import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/styles';
import {
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Grid,
    Divider,
    FormControlLabel,
    Checkbox,
    Typography,
    Button, TextField
} from '@material-ui/core';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import axios from "axios"
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import {getToken} from "../../../../Utils/common";

const useStyles = makeStyles(() => ({
    root: {},
    item: {
        display: 'flex',
        flexDirection: 'column'
    }
}));

const Notifications = props => {
    const {className, path, history, ...rest} = props;

    const classes = useStyles();
    const [values, setValues] = useState({
        name: '',
        modelID: '',
        username: '',
        password: '',
        modelName: '',
        host: "",
        topic: "",
        ApiKey: ""
    });

    const handleChange = event => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
    };
    const handlesend = () => {

        if (values.modelName == "") {
            seterrorname({error: true, text: " name is a required field"})
        }
        if (values.modelID == "") {
            seterrormodelid({error: true, text: "model id is a required field"})
        }
        if (values.host == "") {
            seterrohost({error: true, text: "host is a required field"})
        }
        if (values.topic == "") {
            seterrortopic({error: true, text: "topic is a required field"})
        }
        if (values.host != "" && values.topic != "" && values.modelID != "" && values.modelName != "") {

            axios.post(path + '/addApi/', values, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + getToken()
                }
            }).then(response => {
                console.log("hi " + response)
                if (response) {
                    console.log(response)
                    if (response.data.message != undefined)
                        setOpen1(true)
                    else setOpen(true)
                }
            }).catch(error => {
                if (error.response.status === 401) console.log(" error ");
            });

        }
    }
    const [open, setOpen] = React.useState(false);
    const [open1, setOpen1] = React.useState(false);
    const handleClick = () => {
        setOpen(true);
    };

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
        setOpen1(false)

    };
    const [topics, setTopics] = useState([])
    const fetchData = async () => {
        try {
            let res = await fetch(`${path}/allTopicConnector`,
                {
                    method: 'GET', mode: 'cors',
                    headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getToken()}
                });
            let myjson = await res.json();
            console.log(myjson)
            await setTopics(myjson);
        } catch (err) {
            alert('fetch data error ' + err)
        }
    }
    const [errorname, seterrorname] = useState({error: false, text: ""})
    const [errormodelid, seterrormodelid] = useState({error: false, text: ""})
    const [errorhost, seterrohost] = useState({error: false, text: ""})
    const [errortopic, seterrortopic] = useState({error: false, text: ""})
    const [errortmodelname, seterrormodelname] = useState({error: false, text: ""})

    useEffect(() => {
        fetchData()
    }, []);
    return (
        <Card
            {...rest}
            className={clsx(classes.root, className)}
        >
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success">
                    info added with success

                </Alert>
            </Snackbar>
            <Snackbar open={open1} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="warning">
                    name is not available

                </Alert>
            </Snackbar>
            <form>
                <CardHeader
                    subheader=""
                    title=" Api information"
                />
                <Divider/>
                <CardContent>
                    <Grid
                        container
                        spacing={6}
                        wrap="wrap"
                    >
                        <Grid
                            className={classes.item}
                            item
                            md={4}
                            sm={6}
                            xs={12}
                        >

                            <TextField
                                fullWidth
                                label="Api Name"
                                margin="dense"
                                name="modelName"
                                required
                                variant="outlined"
                                value={values.modelName}
                                onChange={handleChange}

                                error={errorname.error}
                                helperText={errorname.text}
                            />
                            <TextField
                                fullWidth
                                label="Host"
                                margin="dense"
                                name="host"

                                variant="outlined"
                                value={values.host}
                                onChange={handleChange}
                                error={errorhost.error}
                                helperText={errorhost.text}
                            />
                            <TextField
                                fullWidth
                                label="Username"
                                margin="dense"
                                name="username"
                                required
                                variant="outlined"
                                value={values.username}
                                onChange={handleChange}

                            />
                            <TextField
                                fullWidth
                                label="Api key"
                                margin="dense"
                                name="Apikey"

                                variant="outlined"
                                value={values.Apikey}
                                onChange={handleChange}
                            />

                        </Grid>
                        <Grid
                            className={classes.item}
                            item
                            md={4}
                            sm={6}
                            xs={12}
                        >
                            <FormControl className={classes.formControl}>
                                <InputLabel htmlFor="age-native-simple">Topic </InputLabel>
                                <Select
                                    native
                                    value={values.topic}
                                    onChange={handleChange}
                                    inputProps={{
                                        name: 'topic',
                                        id: 'age-native-simple',
                                    }}

                                    error={errortopic.error}
                                    helperText={errortopic.text}
                                >
                                    <option value="">{""}</option>
                                    {topics.map(topic => (
                                        <option value={topic.name}>{topic.name}</option>
                                    ))}
                                </Select>
                            </FormControl>
                            <TextField
                                fullWidth
                                label="Model ID "
                                margin="dense"
                                name="modelID"
                                required
                                variant="outlined"
                                value={values.modelID}
                                onChange={handleChange}
                                error={errormodelid.error}
                                helperText={errormodelid.text}
                            />


                            <TextField
                                fullWidth
                                label="Password"
                                margin="dense"
                                name="password"
                                type="password"
                                variant="outlined"
                                value={values.password}
                                onChange={handleChange}
                            />


                        </Grid>

                    </Grid>
                </CardContent>
                <Divider/>
                <CardActions>
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={handlesend}
                    >
                        Save
                    </Button>
                </CardActions>
            </form>
        </Card>
    );
};

Notifications.propTypes = {
    className: PropTypes.string
};

export default Notifications;
