import React from 'react';
import Alert from '@material-ui/lab/Alert';

function MessageAlert({ message, severity, onClose }) {

        console.log(message)

  return (
    <>
        <Alert severity={severity} onClose={onClose}>
          {message}
        
        </Alert>
    </>
  );
}

export default MessageAlert;
