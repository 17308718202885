import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/styles';
import {Button, Card, CardActions, CardContent, CardHeader, Divider, Grid, TextField} from '@material-ui/core';

import {SearchInput} from '../../../../Components';
import Snackbar from "@material-ui/core/Snackbar";
import Collapse from "@material-ui/core/Collapse";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import axios from "axios";
import MuiAlert from "@material-ui/lab/Alert";
import {ProductCard} from "./components";
import uuid from 'uuid/v1';
import {getToken} from "../../../../Utils/common";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import FileUpload from "../../../ConnectorDetails/components/Notifications/components/FileUpload";
import InputAdornment from "@material-ui/core/InputAdornment";
import OutlinedInput from "@material-ui/core/OutlinedInput";

const useStyles = makeStyles(theme => ({
    root: {},
    row: {
        height: '42px',
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(1)
    },
    spacer: {
        flexGrow: 1
    },
    importButton: {
        marginRight: theme.spacing(1)
    },
    exportButton: {
        marginRight: theme.spacing(1)
    },
    searchInput: {
        marginRight: theme.spacing(1)
    }
}));

const Connectordetails = props => {
    const {className, history, values, url, setValues, ...rest} = props;


    const classes = useStyles();
    const routeChange = () => {
        let path = `apidetails`;
        history.push(path);
    }
    const handleChange = event => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
    };
    const handleset = event => {
        if (event.target.value == "com.test.MqttSourceConnector")

            setValues({
                ...values,
                ["valueConverter"]: "org.apache.kafka.connect.converters.ByteArrayConverter"


            })

    }
    const handleChangewithfield = event => {

        setValues({
            ...values,
            [event.target.name]: event.target.value
        });


        if (event.target.value == "sink") {
            //values.connectorClass="";

            setExpanded2(false);
            setExpanded(true);
            //  setValues(values);
        } else if (event.target.value == "source") {
            setExpanded(false);
            setExpanded2(true);
            /* values.connectorClass="";
             setValues(values);**/
        } else {
            setExpanded(false);
            setExpanded2(false);

        }
    };

    const [valuesConnect, setValuesConnect] = useState([])
    const [errortype, seterrortype] = useState({
        error: false,
        text: ""

    })
    const [errorclass, seterrorclass] = useState({
        error: false,
        text: ""

    })
    const [errorconnectionUrl, seterrorconnectionUrl] = useState({
        error: false,
        text: ""

    })


    const [errortopic, seterrortopic] = useState({
        error: false,
        text: ""

    })
    const [errorformat, seterrorformat] = useState({
        error: false,
        text: ""

    })
    const [errorserveruri, seterrorserveruri] = useState({
        error: false,
        text: ""

    })
    const [errortopicserver, seterrortopicserver] = useState({
        error: false,
        text: ""

    })
    const handleChangeTopic = (prop) => (event) => {
        setValues({...values, [prop]: event.target.value});
    };
    const fetchDataConfig = async (name) => {
        try {
            let res = await fetch(`${url}/connectorConfig/` + name,
                {
                    method: 'GET', mode: 'cors',
                    headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getToken()}
                });
            let myjson = await res.json();
            console.log("oki")
            if (myjson["connector.class"] == "com.test.HttpSourceConnector") {

            } else if (myjson["connector.class"] == "com.test.MqttSourceConnector") {
            } else if (myjson["connector.class"] == "io.confluent.connect.elasticsearch.ElasticsearchSinkConnector") {
            }

        } catch (err) {
            alert('fetch data error ' + err)
        }
    }
    const fetchDataStatus = async (name) => {
        try {
            let res = await fetch(`${url}/connectorStatus/` + name,
                {
                    method: 'GET', mode: 'cors',
                    headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getToken()}
                });
            let myjson = await res.json();


        } catch (err) {
            alert('fetch data error ' + err)
        }
    }
    const fetchData = async () => {
        try {
            let res = await fetch(`${url}/allConnectors/`,
                {
                    method: 'GET', mode: 'cors',
                    headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getToken()}
                });
            let myjson = await res.json();
            await setValuesConnect(myjson);
            await myjson.map(val => {

                fetchDataStatus(val.name)
                fetchDataConfig(val.name)

            })
        } catch (err) {
            alert('fetch data error ' + err)
        }
    }

    useEffect(() => {
        fetchData()
    }, []);


    const [openDelete, setOpenDelete] = useState(false);
    const handleOpenDelete = (id_model) => {
        setOpenDelete(true);
    }
    const handleCloseDelete = () => {
        setOpenDelete(false);
    }
    const handleDeleteContinue = async (name) => {
        try {
            let res = await fetch(`${url}/deleteConnector/` + name, {
                method: 'GET',
                mode: 'cors',
                headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getToken()}
            })
            let myjson = await res.json();
            await setValuesConnect(myjson);
            handleCloseDelete();
        } catch (error) {
            console.error('Error rr:', error)
        }
    }


    const handleSend = () => {

        let checkname = valuesConnect.filter(val => val.name == values.nameconnect)
        if (values.type == "") {
            seterrortype({error: true, text: "field required"})

        } else if (values.type == "source") {
            if (values.topicsource == "") {
                seterrortopic({error: true, text: "field required"})
            }
            if (values.serveruri == "") {
                seterrorserveruri({error: true, text: "field required"})
            }
            if (values.topicserver == "") {
                seterrortopicserver({error: true, text: "field required"})
            }

            if (values.connectorClass == "io.confluent.connect.elasticsearch.ElasticsearchSinkConnector") {
                seterrorclass({error: true, text: "field required"})
            }

        } else {

            if (values.connectionUrl == "") {
                seterrorconnectionUrl({error: true, text: "field required"})
            }
            if (values.connectorClass != "io.confluent.connect.elasticsearch.ElasticsearchSinkConnector") {
                seterrorclass({error: true, text: "field required"})
            }


        }
        if (values.connectorClass == "") {
            seterrorclass({error: true, text: "field required"})
        }
        if (values.valueConverter == "") {
            seterrorformat({error: true, text: "field required"})
        }

        if (values.nameconnect == "") {
            SetHelperror("field required");
            setError(true)
        }
        if (values.nameconnect != "" && values.type != "" && values.valueConverter != "" && values.connectorClass != "") {

            if (values.type == "source") {
                if (values.topicsource != "" && values.topicserver != "" && values.serveruri != "" && values.connectorClass != "io.confluent.connect.elasticsearch.ElasticsearchSinkConnector") {
                    if (checkname.length == 0) {
                        let data = {
                            name: values.nameconnect,
                            connectorClass: values.connectorClass,
                            tasksMax: "1",
                            type: values.type,
                            valueConverter: values.valueConverter,
                            valueConverterschemasenable: values.valueConverterschemasenable,
                            schemaIgnore: values.schemaIgnore,
                            connectionUrl: values.connectionUrl,
                            typeName: values.typeName,
                            topics: values.name,
                            keyIgnore: values.keyIgnore,
                            serveruri: values.serveruri,
                            topicsource: values.thingId + "/" + values.topicsource,
                            topicserver: values.topicserver,
                            mqttUsername: values.mqttUsername,
                            mqttPassword: values.mqttPassword,
                            sslcert: values.sslcert,
                            sslca: values.sslca,
                            sslkey: values.sslkey


                        }
                        let list = values.listConnectors;
                        list.push(values.nameconnect);
                        setValues({
                            ...values,
                            ["listConnectors"]: list
                        });
                        axios.post(url + '/addConnector', data, {
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + getToken()
                            }
                        }).then(response => {

                            axios.post(url + "/AddThingPermission", {
                                    username: values.mqttUsername,
                                    device: values.thingId,
                                    topic: values.topicsource,
                                    pubsub: 3
                                }, {
                                    headers: {
                                        'Content-Type': 'application/json',
                                        'Authorization': 'Bearer ' + getToken()
                                    }
                                }
                            ).then(response => {
                                setShowAll(true)
                                setShowResults(false)
                                fetchData();

                            }).catch(error => {
                                if (error.response.status === 401) console.log(" error ");
                            })
                            setShowAll(true)
                            setShowResults(false)


                            fetchData()
                        }).catch(error => {


                        });


                    } else {
                        SetHelperror("name exist")
                        setError(true);
                    }

                }
            } else {
                if (values.connectionUrl != "" && values.connectorClass == "io.confluent.connect.elasticsearch.ElasticsearchSinkConnector") {
                    if (checkname.length == 0) {
                        let data = {

                            name: values.nameconnect,
                            connectorClass: values.connectorClass,
                            tasksMax: "1",
                            type: values.type,
                            valueConverter: values.valueConverter,
                            valueConverterschemasenable: values.valueConverterschemasenable,
                            schemaIgnore: values.schemaIgnore,
                            connectionUrl: values.connectionUrl,
                            typeName: values.typeName,
                            topics: values.name,
                            keyIgnore: values.keyIgnore,
                            serveruri: values.serveruri,
                            topicsource: values.topicsource,
                            topicserver: values.topicserver,
                            elasticUsername: values.elasticUsername,
                            elasticPassword: values.elasticPassword

                        }
                        let list = values.listConnectors;

                        list.push(values.nameconnect);
                        setValues({
                            ...values,
                            ["listConnectors"]: list
                        });
                        axios.post(url + '/addConnector', data, {
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + getToken()
                            }
                        }).then(response => {

                            if (response.data.error != undefined) {

                                setOpen1(true);


                            } else {

                                setShowAll(true)
                                setShowResults(false)
                                fetchData()
                            }
                        }).catch(error => {


                        });

                    } else {
                        SetHelperror("name exist")
                        setError(true);
                    }

                }

            }

        }
    }


    const [showResults, setShowResults] = React.useState(false)
    const [showAll, setShowAll] = React.useState(true)

    const onClick = () => {
        setShowAll(false)

        setShowResults(true)


    }


    const [expanded, setExpanded] = React.useState(false);
    const [expanded2, setExpanded2] = React.useState(false);
    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const handleCancel = () => {

        history.push('topic')
    }

    const [open, setOpen] = React.useState(false);
    const [open1, setOpen1] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [helperror, SetHelperror] = React.useState("");
    const handleClick = () => {
        setOpen(true);
    };

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
        setOpen1(false);

    };


    return (

        <div className={classes.root}>
            <Grid container spacing={3}>
                <Grid item xs={3}>

                </Grid>
                <Grid item xs={3}>

                </Grid>
                <Grid item xs={3}>
                </Grid>
                <Grid item xs={3}>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={onClick}
                        style={{float: "right"}}
                    >
                        Add connector
                    </Button>
                </Grid>
                <Grid item xs={12}><Divider></Divider></Grid>
                <Grid item xs={12}>
                    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                        <Alert onClose={handleClose} severity="success">
                            info added with success

                        </Alert>
                    </Snackbar>
                    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                        <Alert onClose={handleClose} severity="warning">
                            name is not available

                        </Alert>
                    </Snackbar></Grid>
            </Grid>

            {

                showResults ?
                    <Grid container spacing={3}>
                        <Grid item xs={6}>

                            <TextField
                                fullWidth
                                label=" Name"
                                margin="dense"
                                error={error}
                                helperText={error ? helperror : ""}
                                name="nameconnect"
                                required
                                id="standard-required"
                                variant="outlined"
                                value={values.nameconnect}
                                onChange={handleChange}

                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl className={classes.formControl} fullWidth>
                                <InputLabel htmlFor="age-native-simple">Type </InputLabel>
                                <Select
                                    native
                                    value={values.type}
                                    onChange={handleChangewithfield}
                                    inputProps={{
                                        name: 'type',
                                        id: 'age-native-simple',
                                    }}

                                    error={errortype.error}
                                    helperText={errortype.text}
                                >
                                    <option aria-label="None" value=""/>
                                    <option value={"sink"}>Sink</option>
                                    <option value={"source"}>Source</option>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <Collapse in={expanded} timeout="auto" unmountOnExit>
                                <CardContent>
                                    <Grid
                                        className={classes.item}
                                        item
                                        md={12}
                                        sm={6}
                                        xs={12}
                                    >

                                        <FormControl className={classes.formControl} fullWidth>
                                            <InputLabel htmlFor="age-native-simple">Value Converter </InputLabel>
                                            <Select
                                                native
                                                value={values.valueConverter}
                                                onChange={handleChange}
                                                inputProps={{
                                                    name: 'valueConverter',
                                                    id: 'age-native-simple',
                                                }}
                                                required
                                                error={errorformat.error}
                                                helperText={errorformat.text}

                                            >
                                                <option aria-label="None" value=""/>
                                                <option value={"org.apache.kafka.connect.json.JsonConverter"}>JSON
                                                </option>
                                            </Select>
                                        </FormControl>

                                    </Grid>
                                    <Grid
                                        className={classes.item}
                                        item
                                        md={12}
                                        sm={6}
                                        xs={12}
                                    >

                                        <FormControl className={classes.formControl} fullWidth>
                                            <InputLabel htmlFor="age-native-simple">Connector class </InputLabel>
                                            <Select
                                                native
                                                value={values.connectorClass}
                                                onChange={handleChange}
                                                inputProps={{
                                                    name: 'connectorClass',
                                                    id: 'age-native-simple',
                                                }}

                                                error={errorclass.error}
                                                helperText={errorclass.text}
                                            >
                                                <option aria-label="None" value=""/>
                                                <option
                                                    value={"io.confluent.connect.elasticsearch.ElasticsearchSinkConnector"}>Elasticsearch
                                                </option>

                                            </Select>
                                        </FormControl>
                                        <TextField
                                            fullWidth
                                            label="Tasks max "
                                            margin="dense"
                                            name="tasks"
                                            required
                                            variant="outlined"
                                            value={values.tasksMax}
                                            onChange={handleChange}
                                        />

                                        <TextField
                                            fullWidth
                                            label="Connection Url"
                                            margin="dense"
                                            name="connectionUrl"
                                            required
                                            variant="outlined"
                                            value={values.connectionUrl}
                                            onChange={handleChange}
                                            error={errorconnectionUrl.error}
                                            helperText={errorconnectionUrl.text}

                                        />
                                        <TextField
                                            fullWidth
                                            label="Username"
                                            margin="dense"
                                            name="elasticUsername"
                                            required
                                            variant="outlined"
                                            value={values.elasticUsername}
                                            onChange={handleChange}


                                        />
                                        <TextField
                                            fullWidth
                                            label="Password"
                                            margin="dense"
                                            name="elasticPassword"
                                            required
                                            variant="outlined"
                                            value={values.elasticPassword}
                                            onChange={handleChange}


                                        />
                                    </Grid>

                                </CardContent>
                            </Collapse>
                        </Grid>
                        <Grid item xs={12}>
                            <Collapse in={expanded2} timeout="auto" unmountOnExit>
                                <CardContent>
                                    <Grid
                                        className={classes.item}
                                        item
                                        md={12}
                                        sm={6}
                                        xs={12}
                                    >
                                        <br/>
                                        <FormControl className={classes.formControl} fullWidth>
                                            <InputLabel htmlFor="age-native-simple">Value Converter </InputLabel>
                                            <Select
                                                native
                                                value={values.valueConverter}
                                                onChange={handleChange}
                                                inputProps={{
                                                    name: 'valueConverter',
                                                    id: 'age-native-simple',
                                                }}

                                                error={errorformat.error}
                                                helperText={errorformat.text}
                                            >
                                                <option aria-label="None" value=""/>
                                                <option
                                                    value={"org.apache.kafka.connect.converters.ByteArrayConverter"}>ByteArray
                                                </option>
                                                <option value={"org.apache.kafka.connect.json.JsonConverter"}>JSON
                                                </option>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid
                                        className={classes.item}
                                        item
                                        md={12}
                                        sm={6}
                                        xs={12}
                                    >

                                        <FormControl className={classes.formControl} fullWidth>
                                            <InputLabel htmlFor="age-native-simple">Connector class </InputLabel>
                                            <Select
                                                native
                                                value={values.connectorClass}
                                                onChange={handleChange}
                                                inputProps={{
                                                    name: 'connectorClass',
                                                    id: 'age-native-simple',
                                                }}
                                                onClick={handleset}
                                                error={errorclass.error}
                                                helperText={errorclass.text}
                                            >
                                                <option aria-label="None" value=""/>
                                                <option value={"com.test.MqttSourceConnector"}>MQTT</option>
                                                <option value={"com.test.HttpSourceConnector"}>HTTP</option>
                                            </Select>
                                        </FormControl>
                                        <TextField
                                            fullWidth
                                            label="Topic server"
                                            margin="dense"
                                            name="topicserver"
                                            required
                                            variant="outlined"
                                            value={values.topicserver}
                                            onChange={handleChange}
                                            error={errortopicserver.error}
                                            helperText={errortopicserver.text}
                                        />
                                        <TextField
                                            fullWidth
                                            label="Server Uri source"
                                            margin="dense"
                                            name="serveruri"
                                            required
                                            variant="outlined"
                                            value={values.serveruri}
                                            onChange={handleChange}
                                            error={errorserveruri.error}
                                            helperText={errorserveruri.text}
                                        />


                                        <TextField
                                            fullWidth
                                            label="Topic Source"
                                            margin="dense"
                                            name="topicsource"
                                            required
                                            variant="outlined"
                                            value={values.topicsource}
                                            onChange={handleChange}


                                        />
                                        <TextField
                                            fullWidth
                                            label="Username"
                                            margin="dense"
                                            name="mqttUsername"
                                            required
                                            variant="outlined"
                                            value={values.mqttUsername}
                                            onChange={handleChange}
                                            disabled

                                        />
                                        <TextField
                                            fullWidth
                                            label="Password"
                                            margin="dense"
                                            name="mqttPassword"
                                            required
                                            variant="outlined"
                                            value={values.mqttPassword}
                                            onChange={handleChange}
                                            disabled
                                        />
                                        <FileUpload url={url} history={history} values={values} setValues={setValues}/>

                                    </Grid>

                                </CardContent>
                            </Collapse>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider/>
                        </Grid>

                        <Grid item xs={12}>

                            <Button
                                color="primary"
                                variant="outlined"
                                onClick={handleSend}
                            >
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                    : null}
            {showAll ? <div className={classes.content}>
                <Grid container spacing={3}>
                    {valuesConnect.map(product => (
                        <Grid
                            item

                            xs={3}
                        >
                            <ProductCard product={product} url={url} setValues={setValuesConnect} history={history}/>
                        </Grid>
                    ))}
                </Grid>
            </div> : null}


        </div>
    );
};

Connectordetails.propTypes = {
    className: PropTypes.string
};

export default Connectordetails;
