import React, {useState, useEffect} from 'react';
import {Switch, Redirect} from 'react-router-dom';

import {RouteWithLayout} from './Components';
import {Main as MainLayout, Minimal as MinimalLayout} from './layouts';
import {getToken, getUser, removeUserSession, getRole} from './Utils/common';

import {
    Plan as Planview,
    Acls as AclsView,
    Swagger as SwaggerView,
    Nodered as NoderedView,
    Grafana as GrafanaView,
    NotFound as NotFoundView,
    ProductList as Product,
    Topics as TopicsView,
    Dashboard as DashboardView,
    Settings as SettingsView,
    ApiList as Api,
    ApiDetails as ApiDetailsView,
    ConnectorDetails as ConnectorDetailsView,
    TopicDetails as TopicDetailsView,
    TopicUpdate as TopicUpdateView,
    ConnectorUpdate as ConnectorUpdateView,
    ApiUpdate as ApiUpdateView,
    Steps as StepsView,
    DbaseManager as DbaseManagerView,
    Schema as SchemaView,
    addSchema as addSchemaView,
    SignIn as SignTnView,
    UserList as UserListView,
    Account as AccountView,
    Flows as FlowsView,
    Library as LibraryView,
    SignUp as SignUpView
} from './views';
import axios from 'axios';

const path = process.env.REACT_APP_BASE_URL; 
let variable = 0;

const Routes = ({history}) => {
    
    useEffect(() => {
        const token = getToken();
        if (!token) return
        let config = {headers: {'Authorization': 'Bearer ' + getToken()}}
        axios.get(path + '/verifyToken', config).then(response => {
        }).catch(error => {
            removeUserSession();
            history.push("/signin")
        });
    }, []);

    const user = getUser();
    const connect = getUser() ? true : false;


    return (
        <Switch>
            {connect ?
                <Redirect exact from="/" to="/steps"/> :
                <Redirect exact from="/" to="/signin"/>
            }

            {connect ?
                <RouteWithLayout
                    component={addSchemaView}
                    exact
                    layout={MainLayout}
                    path="/addschema"
                    url={path}
                    history={history}

                />

                : null}

            <RouteWithLayout component={SignTnView}
                             exact
                             layout={MinimalLayout}
                             path="/signin"
                             url={path}
                             history={history}
            />
            <RouteWithLayout component={SignUpView}
                             exact
                             layout={MinimalLayout}
                             path="/signup"
                             url={path}
                             history={history}
            />
            {
                connect && user.admin ?
                    <RouteWithLayout
                        component={UserListView}
                        exact
                        layout={MainLayout}
                        path="/users"
                        url={path}
                        history={history}
                    />
                    : null
            }
            {connect ?

                <RouteWithLayout
                    component={GrafanaView}
                    exact
                    layout={MainLayout}
                    path="/grafana"
                    url={path}
                    history={history}
                /> : null}
            {connect ?
                <RouteWithLayout
                    component={NoderedView}
                    exact
                    layout={MainLayout}
                    path="/nodered/:id"
                    url={path}
                    history={history}
                />
                : null}
            {connect ?
                <RouteWithLayout
                    component={NoderedView}
                    exact
                    layout={MainLayout}
                    path="/nodered/:id"
                    url={path}
                    history={history}
                />
                : null}
            {connect ?
                <RouteWithLayout
                    component={AclsView}
                    exact
                    layout={MainLayout}
                    path="/things"
                    url={path}
                    history={history}
                />
                : null}


            {connect ?
                <RouteWithLayout
                    component={SwaggerView}
                    exact
                    layout={MainLayout}
                    path="/swagger"
                    url={path}
                    history={history}
                />
                : null}
            {connect ?
                <RouteWithLayout
                    component={DbaseManagerView}
                    exact
                    layout={MainLayout}
                    path="/dbmanager"
                    url={path}
                    history={history}
                />
                : null}
            {connect ?
                <RouteWithLayout
                    component={DashboardView}
                    exact
                    layout={MainLayout}
                    path="/dashboard"
                    url={path}
                    history={history}
                    variable={variable}
                />
                : null}
            {connect ?
                <RouteWithLayout
                    component={StepsView}
                    exact
                    layout={MainLayout}
                    path="/steps"
                    url={path}
                    history={history}
                />
                : null}
            {connect ?
                <RouteWithLayout component={AccountView}
                                 exact
                                 layout={MainLayout}
                                 path="/account"
                                 url={path}
                                 history={history}/>
                : null}
            {connect ?
                <RouteWithLayout component={Planview}
                                 exact
                                 layout={MainLayout}
                                 path="/plan"
                                 url={path}
                                 history={history}/>

                : null}
            {connect ?
                <RouteWithLayout component={SettingsView}
                                 exact
                                 layout={MainLayout}
                                 path="/settings"
                                 url={path}
                                 history={history}/>

                : null}
            {connect ?
                <RouteWithLayout
                    component={TopicsView}
                    exact
                    layout={MainLayout}
                    path="/topic"
                    url={path}
                    history={history}
                />
                : null}
            {connect ?

                <RouteWithLayout
                    component={Api}
                    exact
                    layout={MainLayout}
                    path="/apis"
                    url={path}
                    history={history}
                />
                : null}
            {connect ?
                <RouteWithLayout
                    component={ApiDetailsView}
                    exact
                    layout={MainLayout}
                    path="/apidetails"
                    url={path}
                    history={history}
                />
                : null}
            {connect ?
                <RouteWithLayout
                    component={LibraryView}
                    exact
                    layout={MainLayout}
                    path="/downloads"
                    url={path}
                    history={history}
                />
                : null}
            {connect ?
                <RouteWithLayout
                    component={TopicUpdateView}
                    exact
                    layout={MainLayout}
                    path="/topicupdate"
                    url={path}
                    history={history}
                />
                : null}
            {connect ?
                <RouteWithLayout
                    component={ConnectorUpdateView}
                    exact
                    layout={MainLayout}
                    path="/connectorupdate/:name"
                    url={path}
                    history={history}
                />
                : null}
            {connect ?
                <RouteWithLayout
                    component={FlowsView}
                    exact
                    layout={MainLayout}
                    path="/flows"
                    url={path}
                    history={history}
                />
                : null}
            {connect ?
                <RouteWithLayout
                    component={ApiUpdateView}
                    exact
                    layout={MainLayout}
                    path="/apiupdate/:id"
                    url={path}
                    history={history}
                />
                : null}
            {connect ?

                <RouteWithLayout
                    component={ConnectorDetailsView}
                    exact
                    layout={MainLayout}
                    path="/connectordetails"
                    url={path}
                    history={history}
                />
                : null}
            {connect ?
                <RouteWithLayout
                    component={TopicDetailsView}
                    exact
                    layout={MainLayout}
                    path="/topicdetails"
                    url={path}
                    history={history}
                />
                : null}
            {connect ?
                <RouteWithLayout
                    component={Product}
                    exact
                    layout={MainLayout}
                    path="/connectors"
                    url={path}
                    history={history}
                    variable={variable}
                />
                : null}

            <RouteWithLayout
                component={NotFoundView}
                exact
                layout={MinimalLayout}
                path="/not-found"
                history={history}
            />

            {connect ?
                <RouteWithLayout
                    component={SchemaView}
                    exact
                    layout={MainLayout} 
                    path="/schema"
                    url={path}
                    history={history}
                />
                : null}
            <Redirect to="/not-found"/>
        </Switch>
    );
};

export default Routes;
