import React, { useState, useEffect } from 'react';
import { fade, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import withStyles from "@material-ui/core/styles/withStyles";
import ExpandMore from '@material-ui/icons/ExpandMore';
import CheckIcon from '@material-ui/icons/Check';
import TextField from '@material-ui/core/TextField';
import List from "@material-ui/core/List";
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import ListItem from '@material-ui/core/ListItem';
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Slide from "@material-ui/core/Slide";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import axios from "axios";
import { getToken } from "../../Utils/common";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardHeader from "@material-ui/core/CardHeader";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import { SearchInput } from "../../Components";
import LinearProgress from "@material-ui/core/LinearProgress";
import PropTypes from 'prop-types';
import { green } from '@material-ui/core/colors';
import clsx from 'clsx';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import ScrollBound from "react-scroll-bound";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3)
    },
    content: {
        marginTop: theme.spacing(2)
    },
    pagination: {
        marginTop: theme.spacing(3),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end'
    },
    btn: {
        marginRight: theme.spacing(1),
    },
    media: {
        height: 0,
        paddingTop: '60.25%', // 16:9
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginLeft: 0,

    }, details: {
        display: 'flex',
        flexDirection: 'column',
    },

    cover: {
        width: 151,
    },
    controls: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    playIcon: {
        height: 38,
        width: 38,
    }, appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    }, wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
        alignItems: 'center',
    },
    buttonSuccess: {
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
    fabProgress: {
        color: green[500],
        position: 'absolute',
        top: -6,
        left: -6,
        zIndex: 1,
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },

}));


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})
    ((props) => (
        <Menu
            elevation={0}
            getContentAnchorEl={null}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            {...props}
        />
    ));


const StyledMenuItem = withStyles((theme) => ({
    root: {
        '&:focus': {
            backgroundColor: theme.palette.primary.main,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem);


const Flows = props => { 
    const { history, url } = props;
    const classes = useStyles();
    const [checked, setChecked] = React.useState([0]);
    const [anchorEl2, setAnchorEl2] = React.useState(null);
    const [opendeploy, setOpendeploy] = React.useState(false);
    const [openRestart, setopenRestart] = React.useState(false);
    const open = Boolean(anchorEl2);

    const [flows, setFlows] = React.useState({
        name: "",
        search: ""
    })

    const [flow , setFlow ] = React.useState({
        id : null , 
        port : null 
    })

    const [openNew, setOpenNew] = React.useState(false);
    const [selected, setSelected] = React.useState("");

    const handleClickOpen = () => { setOpenNew(true) };
    const handleCloseNew = () => { setOpenNew(false) };
    const handleClosedeploy = () => {
        setOpendeploy(false);
        setLoading(false)
        setSuccess(false);
    };
    const handleCloseRestart = () => {
        setopenRestart(false);
        setLoading1(false)
        setSuccess1(false);
    };
    const handleClick2 = (event) => {
        setAnchorEl2(event.currentTarget);
    };
    const handleClose2 = () => {
        setAnchorEl2(null);
    };
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorEl1, setAnchorEl1] = React.useState(null);
    const [idflow, setidFlow] = React.useState("");
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleToggle = (value, id) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];
        if (currentIndex === -1) { newChecked.push(value); }
        else { newChecked.splice(currentIndex, 1); }
        setChecked(newChecked);
        setidFlow(value)
        setSelected(id)

    };

    const handleidFlow = (flowId, port) => () => {
        setidFlow(flowId)
        setSelected(port)
    }

    const handleClose = () => { setAnchorEl(null); };
    const handleClose1 = () => { setAnchorEl1(null); };
    const [openLoad, setOpenload] = React.useState(false);
    const handleClickOpenLoad = () => { setOpenload(true); };
    const handleCloseload = () => { setOpenload(false); };
    const [loading, setLoading] = React.useState(false);
    const [loading1, setLoading1] = React.useState(false);
    const [success1, setSuccess1] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const timer = React.useRef();
    const timer1 = React.useRef();

    const buttonClassname = clsx({ [classes.buttonSuccess]: success });

    React.useEffect(() => { return () => { clearTimeout(timer.current) } }, []);
    React.useEffect(() => { return () => { clearTimeout(timer1.current) } }, []);


    const handleButtonClick = () => { handleDeploy() };

    const handleDeploy = () => {

        const headers = { 'Content-Type': 'application/json', 'Node-RED-Deployment-Type': 'flows' };

        axios.get("https://www.thingwings.com/node-red/" + selected + "/flows", { headers: headers })
            .then(response => {
                if (response) {
                    axios.post("https://www.thingwings.com/node-red/" + selected + "/flows", response.data, { headers: headers })
                        .then(res => {
                            if (res) {
                                if (!loading) {
                                    setSuccess(false);
                                    setLoading(true);
                                    timer.current = window.setTimeout(() => {
                                        setSuccess(true);
                                        setLoading(false);
                                    }, 5000);
                                }
                            }
                        }).catch(error => {
                            if (error.res.status === 401) console.log(" error ");
                        });
                }
            }).catch(error => {
                if (error.response.status === 401) console.log(" error ");
            })
    }

    const handleSave = (val) => () => {

        setprogress(true);

        var data = librarys.filter(library => {
            if (library.id == val) return library
        })

        var body = librarys.filter(library => {
            if (library.z == val) return library
        })

        var dt = data[0];
        dt["nodes"] = [];
        dt["configs"] = [];
        var MyDate = new Date();
        var MyDateString;

        MyDate.setDate(MyDate.getDate() + 20);

        MyDateString = ('0' + MyDate.getDate()).slice(-2) + '_'
            + ('0' + (MyDate.getMonth() + 1)).slice(-2) + '_'
            + MyDate.getFullYear() + " " + ('0' + MyDate.getHours()).slice(-2) + ":" + ('0' + MyDate.getMinutes()).slice(-2);

        var d = { "name": dt.label + MyDateString };

        const headers = { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getToken() };

        axios.post(url + "/createContainer", d, { headers: headers })
            .then(response => {
                if (response) {
                    var port = response.data.port;
                    const timer = setTimeout(() => {
                        axios.post("https://www.thingwings.com/node-red/" + port + "/flow", dt, { headers: { 'Content-Type': 'application/json' } }).then(res => {
                            if (res) {

                                for (let i = 0; i < body.length; i++) {
                                    body[i].z = res.data.id
                                }

                                for (let i = 0; i < data.length; i++) {
                                    data[i].id = res.data.id
                                }

                                data = data.concat(body);

                                axios.post("https://www.thingwings.com/node-red/" + port + "/flows",
                                    data, { headers: headers })
                                    .then(result => {
                                        if (result) {
                                            setprogress(false);
                                            history.push("nodered/" + port);
                                        }
                                    })
                            }
                        })
                    }, 5000);
                    return () => clearTimeout(timer);
                }
            });
    }

    const [openDelete, setOpenDelete] = useState(false);

    const handleOpenDelete = () => {
        setOpenDelete(true);
    }

    const handleCloseDelete = () => {
        setOpenDelete(false);
    }

    const handleopnedeploy = () => {
        setOpendeploy(true);
    }

    const handleopenRestart = () => {
        setopenRestart(true);
    }

    const handleDelete = () => {

        if (idflow != "" || idflow != undefined) {
            var d = {
                id: idflow
            }

            axios.post(url + "/deleteFlow", d, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + getToken()
                }
            }).then(response => {
                if (response) {
                    handleCloseDelete();
                    handleClose2();
                    fetchData();
                }
            }).catch(error => {
                if (error) alert("Somthing went wrong , please try again");
            });
        }
    }

    const handleRestart = () => {
        if (idflow != "" || idflow != undefined) {
            var d = {
                id: idflow
            }

            axios.post(url + "/restartFlow", d, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + getToken()
                }
            }).then(response => {
                if (response) {
                    if (!loading1) {
                        setSuccess1(false);
                        setLoading1(true);
                        timer1.current = window.setTimeout(() => {
                            setSuccess1(true);
                            setLoading1(false);
                        }, 5000);

                    }

                    handleCloseDelete();
                    handleClose2();
                    fetchData();
                }
            }).catch(error => {
                if (error.response.status === 401) console.log(" error ");
            });
        }

    }

    const [progress, setprogress] = useState(false)

    const handleEdit = () => () => {
        console.log(idflow,selected);
        history.push(`nodered/${idflow}`)
    }

    const handleAdd = () => {

        var data = { "name": flows.name };
        setprogress(true);
        axios.post(url+"/createContainer",data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getToken()
            }
        }).then(response => {
            if (response) {
                const timer = setTimeout(() => {
                    handleCloseNew()
                    fetchData()
                    setprogress(false)
                    history.push("nodered/"+ response.data.port)
                }, 5000);
                return () => clearTimeout(timer);
            }
        }).catch(error => {
            if (error.response.status === 401) console.log(error.response);
            alert(error.response.message);
        });
    }

    const [values, setValues] = useState([])
    const [librarys, setLibrary] = useState([])
    const [search, setSearch] = useState([])


    const fetchData = async () => {
        try {
            let res = await fetch(url + "/allFLows",
                {
                    method: 'GET', mode: 'cors',
                    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getToken() }
                });
            let myjson = await res.json();
            await setValues(myjson);
            await setSearch(myjson);

            console.log("here")
        } catch (err) {
            alert('fetch data error ' + err)
        }
    }

    const fetchDataLibarary = async () => {
        try {
            let res = await fetch(url + "/library/",
                {
                    method: 'GET', mode: 'cors',
                    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getToken() }
                });
            let myjson = await res.json();
            await setLibrary(myjson);
            console.log("here")
        } catch (err) {
            alert('fetch data error ' + err)
        }
    }

    useEffect(() => { fetchData() }, []);
    useEffect(() => { fetchDataLibarary() }, []);

    const handleChange = event => {
        setFlows({
            ...flows,
            [event.target.name]: event.target.value
        });
    };

    const handlesearch = event => {
        setValues(values.filter(value => {
            if (value.name.includes(event.target.value) == true) return value
        }));
        if ((values.length == 0) || (event.target.value == ""))
            fetchData();
    };

    return (
        <div className={classes.root}>
            <Grid container spacing={3}>

                <Grid item xs={3}>
                    <div className={classes.row}>
                        <SearchInput
                            className={classes.searchInput}
                            placeholder="Search flow "
                            name={"search"}
                            values={flows.search}
                            onChange={handlesearch}
                        />
                    </div>
                </Grid>

                <Grid item xs={3}>
                </Grid>

                <Grid item xs={3}>
                </Grid>

                <Grid item xs={3}>
                    <Button
                        aria-controls="customized-menu"
                        aria-haspopup="true"
                        variant="contained"
                        color="primary"
                        onClick={handleClick}
                        endIcon={<ExpandMore />}
                        style={{ float: "right" }}
                        className={classes.btn}
                    >
                        Add Flow
                    </Button>
                </Grid>

                <Grid item xs={12}>
                    <Divider />
                </Grid>

                <Grid item xs={12}>
                    <Card>
                        <CardHeader
                            title="List of Flows"
                        />
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>

                        <Menu
                            id={`long-menu`}
                            anchorEl={anchorEl2}
                            keepMounted
                            open={open}
                            onClose={handleClose2}
                            PaperProps={{
                                style: {
                                    maxHeight: 48 * 4.5,
                                    width: '20ch',
                                },
                            }}
                        >

                            <MenuItem key={`1`} selected={"1" === 'Pyxis'} onClick={handleEdit(null)}>
                                {"Edit Flow"}
                            </MenuItem>
                            <MenuItem key={`2`} selected={"2" === 'Pyxis'} onClick={handleopnedeploy}>
                                {"Deploy Flow"}
                            </MenuItem>
                            <MenuItem key={`3`} selected={"3" === 'Pyxis'} onClick={handleopenRestart}>
                                {"Restart Flow "}
                            </MenuItem>
                            <MenuItem key={`4`} selected={"4" === 'Pyxis'} onClick={handleOpenDelete}>
                                {"Delete Flow"}
                            </MenuItem>

                        </Menu>

                        <Grid item xs={12}>
                            <ScrollBound className="my-list" style={{
                                maxHeight: "600px",
                                overflowY: "auto"
                            }}>
                                <List className={classes.root}>

                                    {values.map((value) => {
                                        const labelId = `checkbox-list-label-${value.id.toString()}`;

                                        return (
                                            <Grid key={value.id.toString()} container spacing={3}>
                                                <ListItem key={value.id.toString()} role={undefined} dense button
                                                    onClick={handleToggle(value.containerName, value.port)}>

                                                    <Grid item xs={4}>
                                                        <ListItemText id={labelId} primary={<React.Fragment>
                                                            <Typography
                                                                variant='h6'
                                                                component="h3"
                                                            >
                                                                {value.name}
                                                            </Typography>

                                                        </React.Fragment>} />
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <ListItemText id={labelId} primary={value.creationDate} />
                                                        <ListItemSecondaryAction
                                                            onClick={handleidFlow(value.containerName, value.port)}>
                                                            <IconButton edge="end" aria-label="comments"
                                                                onClick={handleClick2}>
                                                                <MoreVertIcon />
                                                            </IconButton>
                                                        </ListItemSecondaryAction>
                                                    </Grid>

                                                </ListItem>
                                            </Grid>

                                        )

                                    })}
                                </List>

                            </ScrollBound>
                        </Grid>
                    </Card></Grid>
            </Grid>


            <Dialog open={openNew} onClose={handleCloseNew} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">New Flow</DialogTitle>
                <Divider></Divider>
                <DialogContent>
                    <DialogContentText>
                        Enter Flow Name :
                        <Box display="flex">
                            {progress ?
                                <CircularProgress color="secondary" /> :
                                null}
                        </Box>
                    </DialogContentText>

                    <TextField
                        autoFocus
                        value={flows.name}
                        margin="dense"
                        id="name"
                        label=" flow name"
                        name={"name"}
                        type="text"
                        fullWidth
                        onChange={handleChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseNew} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleAdd} color="primary">
                        Add
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog fullScreen open={openLoad} onClose={handleCloseload} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleCloseload} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Flow Library
                        </Typography>

                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <Box display="flex" justifyContent="center">
                        {progress ?
                            <CircularProgress justifyContent="center" color="secondary" /> :
                            null
                        }
                    </Box>
                    <Grid container spacing={3}>

                        {librarys.map((value) => {
                            if (value.type === "tab") {
                                return (
                                    <Grid key={value.id} item xs={3}>
                                        <Card className={classes.root}>
                                            <CardActionArea onClick={handleSave(value.id)}>
                                                <CardMedia
                                                    className={classes.media}
                                                    image={`/images/connectors/${value.id}.jpg`}
                                                />
                                                <CardContent>
                                                    <Typography gutterBottom variant="h5" component="h2">
                                                        {value.label}
                                                    </Typography>
                                                    <Typography variant="body2" color="textSecondary" component="p">
                                                        {value.info}
                                                    </Typography>
                                                </CardContent>
                                            </CardActionArea>

                                        </Card>
                                    </Grid>
                                )

                            }

                        })}
                    </Grid>
                </DialogContent>
            </Dialog>

            <Dialog open={openDelete} onClose={handleCloseDelete}>
                <DialogTitle disableTypography={false}>Confirmation</DialogTitle>
                <DialogContent dividers>
                    Are you sure you want to delete this flow ?
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        handleDelete()
                    }} color='primary' size='small'> yes </Button>
                    <Button onClick={handleCloseDelete} color='primary' size='small'> No </Button>
                </DialogActions>

            </Dialog>

            <StyledMenu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <StyledMenuItem>

                    <ListItemText primary="New  Flow" onClick={handleClickOpen} />
                </StyledMenuItem>
                <StyledMenuItem>

                    <ListItemText primary="From Flow Library " onClick={handleClickOpenLoad} />
                </StyledMenuItem>

            </StyledMenu>

            <StyledMenu
                id="customized-menu1"
                anchorEl={anchorEl1}
                keepMounted
                open={Boolean(anchorEl1)}
                onClose={handleClose1}
            >
                <StyledMenuItem>

                    <ListItemText primary="Manage flow" />
                </StyledMenuItem>
                <StyledMenuItem>

                    <ListItemText primary="Delete" />
                </StyledMenuItem>

            </StyledMenu>

            <Dialog
                open={opendeploy}
                onClose={handleClosedeploy}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Click to deploy your flow :"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <div className={classes.wrapper} style={{
                            "display": "flex",
                            "align-items": "center",
                            "padding": "25px",
                            "border": "1px solid #e6e6e6",
                            "margin-bottom": "25px",
                            "min-height": "50px",
                        }}>
                            <Button
                                variant="contained"
                                color="primary"
                                style={{
                                    "align-items": "center",
                                    "padding": "30px"
                                }}
                                className={buttonClassname}
                                disabled={success}
                                onClick={handleButtonClick}
                            >
                                {
                                    loading ?
                                        <HourglassEmptyIcon fontSize="large"></HourglassEmptyIcon> :
                                        <PlayArrowIcon fontSize="large"> </PlayArrowIcon>


                                }
                                {"Deploy"}</Button>
                            {loading && <CircularProgress size={30} className={classes.buttonProgress} />}

                        </div>
                        {success ?

                            <div style={{ color: 'green' }}>{" Deploying with success  "} <CheckIcon /></div>

                            : null

                        }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClosedeploy} color="primary">
                        Cancel
                    </Button>

                </DialogActions>
            </Dialog>

            <Dialog
                open={openRestart}
                onClose={handleCloseRestart}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title1">{"Click to restart your flow :"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description1">
                        {success1 ?

                            <div style={{ color: 'green' }}>{" Flow is running  "} <CheckIcon /></div>

                            : null

                        }

                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseRestart} color="primary">
                        Cancel
                    </Button>
                    <div className={classes.wrapper}>
                        <Button
                            variant="contained"
                            color="primary"
                            className={buttonClassname}
                            disabled={success1}
                            onClick={handleRestart}
                        >
                            Restart
                        </Button>
                        {loading1 && <CircularProgress size={30} className={classes.buttonProgress} />}
                    </div>

                </DialogActions>
            </Dialog>

        </div>
    );


};

export default Flows;




