import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import {IconButton, Grid, Typography} from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import CustomScroll from 'react-custom-scroll';

import {ProductsToolbar, ProductCard} from './components';
import mockData from './data';
import uuid from 'uuid/v1';
import {getToken} from "../../Utils/common";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3)
    },
    content: {
        marginTop: theme.spacing(2)
    },
    pagination: {
        marginTop: theme.spacing(3),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end'
    }
}));

const ProductList = props => {
    const {history, url, variable} = props;
    const classes = useStyles();

    const [values, setValues] = useState([])
    const fetchData = async () => {
        try {
            let res = await fetch(`${url}/allConnectors/`,
                {
                    method: 'GET', mode: 'cors',
                    headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getToken()}
                });
            let myjson = await res.json();
            await setValues(myjson);
        } catch (err) {
            alert('fetch data error ' + err)
        }
    }

    useEffect(() => {
        fetchData()
    }, []);
    const [openDelete, setOpenDelete] = useState(false);
    const handleOpenDelete = (id_model) => {
        setOpenDelete(true);
    }
    const handleCloseDelete = () => {
        setOpenDelete(false);
    }
    const handleDeleteContinue = async (name) => {
        try {
            let res = await fetch(`${url}/deleteConnector/` + name, {
                method: 'GET',
                mode: 'cors',
                headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getToken()}
            })

            let myjson = await res.json();
            await setValues(myjson);

            console.log(values)
            handleCloseDelete();


        } catch (error) {
            console.error('Error rr:', error)
        }
    }


    return (
        <div className={classes.root}>
            <ProductsToolbar history={history} setValues={setValues} url={url} values={values}/>
            <div className={classes.content} style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-around',
                overflow: 'hidden'
            }}>
                <Grid
                    container
                    spacing={3}
                >
                    <Grid item xs={12}><Divider/></Grid>

                    {values.map(product => (
                        <Grid
                            item
                            key={uuid()}

                            xs={3}
                        >
                            <ProductCard product={product} url={url} history={history} setValues={setValues}
                                         variable={variable}/>
                        </Grid>
                    ))}
                </Grid>
            </div>
            <div className={classes.pagination}>

                <IconButton>
                    <ChevronLeftIcon/>
                </IconButton>

                <Typography variant="caption">{values.length}</Typography>
                <IconButton>
                    <ChevronRightIcon/>
                </IconButton>
            </div>
        </div>
    );
};

export default ProductList;
