import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Avatar,
  CardActionArea,
  CircularProgress
} from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import MoneyIcon from '@material-ui/icons/Money';
import SettingsInputHdmiIcon from '@material-ui/icons/SettingsInputHdmi';
import Badge from '@material-ui/core/Badge';

import { getToken } from '../../../../Utils/common';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700
  },
  avatar: {
    backgroundColor: theme.palette.success.main,
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  },
  difference: {
    marginTop: theme.spacing(0.5),
    display: 'flex',
    alignItems: 'center'
  },
  differenceIcon: {
    color: theme.palette.success.dark
  },
  icondown: {
    color: theme.palette.warning.dark,
    marginRight: theme.spacing(0.5)
  },
  icondowntype: {
    color: theme.palette.warning.dark
  },
  caption: {
    marginRight: theme.spacing(0.5)
  },
  differenceValue: {
    color: theme.palette.success.dark,
    marginRight: theme.spacing(0.5)
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  }
}));

const TotalConnectors = props => {
  const { className, url, history, ...rest } = props;

  const classes = useStyles();
  const [values, setValues] = useState([]);
  const [run, setRun] = useState(0);
  const [failed, setFailed] = useState(0);
  const [mqtt, setMqtt] = useState(0);
  const [http, setHttp] = useState(0);
  const [elastic, setElastic] = useState(0);
  const [loading, setLoading] = useState(true);

  const fetchDataConfig = async name => {
    try {
      const res = await fetch(`${url}/connectorConfig/` + name, {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + getToken()
        }
      });
      const myjson = await res.json();
      console.log('oki');
      if (myjson['connector.class'] === 'com.test.HttpSourceConnector') {
        setHttp(prevHttp => prevHttp + 1);
      } else if (myjson['connector.class'] === 'com.test.MqttSourceConnector') {
        setMqtt(prevMqtt => prevMqtt + 1);
      } else if (
        myjson['connector.class'] ===
        'io.confluent.connect.elasticsearch.ElasticsearchSinkConnector'
      ) {
        setElastic(prevElastic => prevElastic + 1);
      }
    } catch (err) {
      console.log('fetch data error ' + err);
    }
  };

  const fetchDataStatus = async name => {
    try {
      const res = await fetch(`${url}/connectorStatus/` + name, {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + getToken()
        }
      });
      const myjson = await res.json();
      console.log('oki');
      if (myjson['tasks'][0].state === 'RUNNING') {
        setRun(prevRun => prevRun + 1);
      } else if (myjson['tasks'][0].state === 'FAILED') {
        setFailed(prevFailed => prevFailed + 1);
      }
    } catch (err) {
      console.log('fetch data error ' + err);
    }
  };

  const fetchData = async () => {
    try {
      const res = await fetch(`${url}/allConnectors/`, {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + getToken()
        }
      });
      const myjson = await res.json();
      setValues(myjson);
      await Promise.all(
        myjson.map(async val => {
          await fetchDataStatus(val.name);
          await fetchDataConfig(val.name);
        })
      );
      setLoading(false);
    } catch (err) {
      console.log('fetch data error ' + err);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const goto = () => {
    history.push('connectors');
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)} onClick={goto}>
      {loading ? (
        <div className={classes.loadingContainer}>
          <CircularProgress />
        </div>
      ) : (
        <CardActionArea>
          <CardContent>
            <Grid container justify="space-between">
              <Grid item>
                <Typography
                  className={classes.title}
                  color="textSecondary"
                  gutterBottom
                  variant="body2"
                >
                  CONNECTIONS
                </Typography>
                <Typography variant="h3">{values.length}</Typography>
              </Grid>
              <Grid item>
                <Avatar className={classes.avatar}>
                  <SettingsInputHdmiIcon className={classes.icon} />
                </Avatar>
              </Grid>
            </Grid>
            <Grid container>
              <Grid xs={12}>
                <div className={classes.difference}>
                  <ArrowUpwardIcon className={classes.differenceIcon} />
                  <Typography className={classes.differenceValue} variant="body2">
                    {run}
                  </Typography>
                  <Typography className={classes.caption} variant="caption">
                    Running
                  </Typography>
                  <ArrowDownwardIcon className={classes.icondowntype} />
                  <Typography className={classes.icondown} variant="body2">
                    {failed}
                  </Typography>
                  <Typography className={classes.caption} variant="caption">
                    Failed
                  </Typography>
                </div>
                <div className={classes.difference}>
                  <Typography className={classes.caption} variant="caption">
                    MQTT
                  </Typography>
                  <Typography className={classes.differenceValue} variant="body2">
                    {mqtt}
                  </Typography>
                  <Typography className={classes.caption} variant="caption">
                    HTTP
                  </Typography>
                  <Typography className={classes.differenceValue} variant="body2">
                    {http}
                  </Typography>
                  <Typography className={classes.caption} variant="caption">
                    ELASTIC
                  </Typography>
                  <Typography className={classes.differenceValue} variant="body2">
                    {elastic}
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </CardContent>
        </CardActionArea>
      )}
    </Card>
  );
};

TotalConnectors.propTypes = {
  className: PropTypes.string
};

export default TotalConnectors;
