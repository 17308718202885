import React, {useState, useEffect} from 'react';
import {fade, makeStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import withStyles from "@material-ui/core/styles/withStyles";
import ExpandMore from '@material-ui/icons/ExpandMore';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'

import CheckIcon from '@material-ui/icons/Check';
import SaveIcon from '@material-ui/icons/Save';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import List from "@material-ui/core/List";
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import Checkbox from '@material-ui/core/Checkbox';
import CommentIcon from '@material-ui/icons/Comment';
import ListItem from '@material-ui/core/ListItem';
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Slide from "@material-ui/core/Slide";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import axios from "axios";
import {getToken, getUser} from "../../../../Utils/common";
import uuid from 'uuid/v1';
import {array} from "prop-types";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import Fab from '@material-ui/core/Fab';

import CardHeader from "@material-ui/core/CardHeader";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import LinearProgress from "@material-ui/core/LinearProgress";
import PropTypes from 'prop-types';
import {green} from '@material-ui/core/colors';
import clsx from 'clsx';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import Iframe from "react-iframe";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3)
    }, myClassname: {
        border: "0"


    },
    content: {
        marginTop: theme.spacing(2)
    },
    pagination: {
        marginTop: theme.spacing(3),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end'
    },
    btn: {
        marginRight: theme.spacing(1),
    },
    media: {
        height: 0,
        paddingTop: '60.25%', // 16:9
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginLeft: 0,

    }, details: {
        display: 'flex',
        flexDirection: 'column',
    },

    cover: {
        width: 151,
    },
    controls: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    playIcon: {
        height: 38,
        width: 38,
    }, appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    }, wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
        alignItems: 'center',
    },
    buttonSuccess: {
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
    fabProgress: {
        color: green[500],
        position: 'absolute',
        top: -6,
        left: -6,
        zIndex: 1,
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },

}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const options2 = [
    'Edit Flow',
    'Deploy',
    'Restart',
    'Delete',

];
const StyledMenuItem = withStyles((theme) => ({
    root: {
        '&:focus': {
            backgroundColor: theme.palette.primary.main,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem);
const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})
((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));


const Flowdetails = props => {
    const {history, url} = props;
    const classes = useStyles();
    const [checked, setChecked] = React.useState([0]);
    const [anchorEl2, setAnchorEl2] = React.useState(null);

    const [flows, setFlows] = React.useState({
        name: "",
        search: ""
    })


    const [openNew, setOpenNew] = React.useState(false);
    const [selected, setSelected] = React.useState("");
    const [selectLibrary, setSelectedLibrary] = React.useState("");

    function timeout(ms) { //pass a time in milliseconds to this function
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    const handleClickOpen = () => {
        setOpenNew(true);
    };

    const handleCloseNew = () => {
        setOpenNew(false);
    };


    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };


    const handleClose = () => {
        setAnchorEl(null);
    };

    const [openLoad, setOpenload] = React.useState(false);

    const handleClickOpenLoad = () => {
        setOpenload(true);
    };

    const handleCloseload = () => {
        setOpenload(false);
    };

    function array_move(arr, old_index, new_index) {
        if (new_index >= arr.length) {
            var k = new_index - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        return arr; // for testing
    };

    const timer = React.useRef();
    const timer1 = React.useRef();


    React.useEffect(() => {
        return () => {
            clearTimeout(timer.current);
        };
    }, []);
    React.useEffect(() => {
        return () => {
            clearTimeout(timer1.current);
        };
    }, []);

    const [port, setport] = React.useState("6000");
    const [display, setdisplay] = useState("none")


    const handleSave = (val) => () => {

        setprogress(true)
        var data = librarys.filter(library => {
            if (library.id == val)
                return library
        })

        var body = librarys.filter(library => {
            if (library.z == val)
                return library
        })
        var dt = data[0];
        dt["nodes"] = [];
        dt["configs"] = [];
        var dat = new Date();
        var updated = dat.getFullYear() + "_" + (parseInt(dat.getMonth()) + 1).toString() + "_" + dat.getUTCDate() + "-" + dat.getHours() + "-" + dat.getMinutes();

        var d = {
            "name": dt.label + updated,
        };
        axios.post(url + "/createContainer", d, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getToken()
            }
        }).then(response => {


            if (response) {
                var port = response.data.port;
                const timer = setTimeout(() => {
                    axios.post("https://www.thingwings.com:" + port + "/flow", dt, {headers: {'Content-Type': 'application/json'}}).then(res => {

                        if (res) {

                            for (let i = 0; i < body.length; i++) {
                                body[i].z = res.data.id
                            }
                            for (let i = 0; i < data.length; i++) {
                                data[i].id = res.data.id
                            }
                            data = data.concat(body);
                            axios.post("https://www.thingwings.com:" + port + "/flows",
                                data, {
                                    headers: {
                                        'Content-Type': 'application/json',
                                        'Authorization': 'Bearer ' + getToken()
                                    }
                                }).then(result => {
                                if (result) {

                                    setprogress(false);
                                    setport(port);
                                    setdisplay("initial")
                                    handleCloseload()
                                    handleClose()
                                }
                            })
                        }
                    })
                }, 5000);
                return () => clearTimeout(timer);


            }


        });
    }
    const [progress, setprogress] = useState(false)


    const handleAdd = () => {

        var data = {
            "name": flows.name,
        };
        setprogress(true)
        axios.post(url + "/createContainer", data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getToken()
            }
        }).then(response => {

            if (response) {

                var port = response.data.port;
                const timer = setTimeout(() => {
                        axios.get("https://www.thingwings.com:" + port + "/flows", {headers: {'Content-Type': 'application/json'}}).then(res => {

                            if (res) {

                                setprogress(false);
                                setport(port);
                                setdisplay("initial")
                                handleCloseNew()
                                handleClose()
                            }
                        })

                    }
                    , 5000);
                return () => clearTimeout(timer);


            }
        }).catch(error => {
            if (error.response.status === 401) console.log(" error ");
        });

    }


    const [values, setValues] = useState([])
    const [librarys, setLibrary] = useState([])
    const [search, setSearch] = useState([])


    const fetchData = async () => {
        try {
            let res = await fetch(url + "/allFLows",
                {
                    method: 'GET', mode: 'cors',
                    headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getToken()}
                });
            let myjson = await res.json();
            await setValues(myjson);
            await setSearch(myjson)
            console.log("here")
        } catch (err) {
            alert('fetch data error ' + err)
        }
    }
    const fetchDataLibarary = async () => {
        try {
            let res = await fetch(url + "/library/",
                {
                    method: 'GET', mode: 'cors',
                    headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getToken()}
                });
            let myjson = await res.json();
            await setLibrary(myjson);
            console.log("here")
        } catch (err) {
            alert('fetch data error ' + err)
        }
    }
    useEffect(() => {
        fetchData()
    }, []);
    useEffect(() => {
        fetchDataLibarary()
    }, []);

    const handleChange = event => {
        setFlows({
            ...flows,
            [event.target.name]: event.target.value
        });
    };
    console.log(search)


    return (
        <div className={classes.root}>


            <Grid container spacing={3}>
                <Grid item xs={3}>


                </Grid>
                <Grid item xs={3}>

                </Grid>
                <Grid item xs={3}>
                </Grid>
                <Grid item xs={3}>
                    <Button
                        aria-controls="customized-menu"
                        aria-haspopup="true"
                        variant="contained"
                        color="primary"
                        onClick={handleClick}
                        endIcon={<ExpandMore/>}
                        style={{float: "right"}}
                        className={classes.btn}
                    >
                        Add Flow
                    </Button>


                </Grid>
                <Grid item xs={12}>
                    <Divider/>
                </Grid>


                <Grid item xs={12}>
                    <Iframe url={"https://www.thingwings.com:" + port}
                            width="100%"
                            height="800px"
                            className={classes.myClassname}
                            key={port}
                            display={display}
                            position="relative"
                            styles={"border:0"}
                            name={"frame1"}


                    />
                </Grid>

            </Grid>
            <Dialog open={openNew} onClose={handleCloseNew} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">New Flow</DialogTitle>
                <Divider></Divider>
                <DialogContent>
                    <DialogContentText>
                        Enter Flow Name :
                        <Box display="flex" style={{"align": "center"}}>
                            {progress ?
                                <CircularProgress color="secondary"/> :
                                null}
                        </Box>
                    </DialogContentText>

                    <TextField
                        autoFocus
                        value={flows.name}
                        margin="dense"
                        id="name"
                        label=" flow name"
                        name={"name"}
                        type="text"
                        fullWidth
                        onChange={handleChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseNew} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleAdd} color="primary">
                        Add
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog fullScreen open={openLoad} onClose={handleCloseload} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleCloseload} aria-label="close">
                            <CloseIcon/>
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Flow Library
                        </Typography>

                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <Box display="flex" justifyContent="center">
                        {progress ?
                            <CircularProgress justifyContent="center" color="secondary"/> :
                            null
                        }
                    </Box>
                    <Grid container spacing={3}>

                        {librarys.map((value) => {
                            if (value.type === "tab") {
                                const labelId = `checkbox-list-label1-${value.id}`;

                                return (
                                    <Grid item xs={3}>
                                        <Card className={classes.root}>
                                            <CardActionArea onClick={handleSave(value.id)}>
                                                <CardMedia
                                                    className={classes.media}
                                                    image={`/images/connectors/${value.id}.jpg`}
                                                />
                                                <CardContent>
                                                    <Typography gutterBottom variant="h5" component="h2">
                                                        {value.label}
                                                    </Typography>
                                                    <Typography variant="body2" color="textSecondary" component="p">
                                                        {value.info}
                                                    </Typography>
                                                </CardContent>
                                            </CardActionArea>

                                        </Card>
                                    </Grid>
                                )

                            }

                        })}
                    </Grid>
                </DialogContent>
            </Dialog>

            <StyledMenu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <StyledMenuItem>

                    <ListItemText primary="New  Flow" onClick={handleClickOpen}/>
                </StyledMenuItem>
                <StyledMenuItem>

                    <ListItemText primary="From Flow Library " onClick={handleClickOpenLoad}/>
                </StyledMenuItem>

            </StyledMenu>


        </div>
    );


};

export default Flowdetails;
