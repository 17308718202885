import uuid from 'uuid/v1';

export default [
    {
        id: uuid(),
        title: 'Mqtt ',
        description:
            'Dropbox is a file hosting service that offers cloud storage, file synchronization, a personal cloud.',
        imageUrl: '/images/products/product_1.png',
        totalDownloads: '594',
        updatedAt: '27/03/2019',
        port: "localhost:2000"
    },
    {
        id: uuid(),
        title: 'Elasticsearch ',
        description:
            'Medium is an online publishing platform developed by Evan Williams, and launched .',
        imageUrl: '/images/products/product_2.png',
        totalDownloads: '625',
        createdAt: '31/03/2019',
        port: "localhost:6000"
    }
];
