import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';

import {
    TotalTopic,
    TotalConnectors,
    TotalTemplates,
    TotalApis,
    LatestSales,
    UsersByDevice,
    LatestMessages,
    LatestOrders,
    ChartView
} from './components';
import { getUser } from "../../Utils/common";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4)
    }
}));

const Dashboard = props => {
    const { history, url } = props;
    const classes = useStyles();
    const usr = getUser();

    return (
        <div className={classes.root}>
            <Grid container spacing={1}>

                <Grid item xs={3}>
                    <TotalTopic url={url} history={history} />
                </Grid>

                <Grid item xs={3}>
                    <TotalConnectors url={url} history={history} />
                </Grid>

                <Grid item xs={3}>
                    <TotalApis url={url} history={history} />
                </Grid>

                <Grid item xs={3}>
                    <TotalTemplates url={url} history={history} />
                </Grid>

                <Grid item xs={6}>
                    {(usr != null && usr.admin) ?
                        <LatestOrders url={url} history={history} /> : null
                    }
                </Grid>

                <Grid item xs={6}>
                    {(usr != null && usr.admin) ?
                        <LatestSales url={url} history={history} />
                        : null}
                </Grid>

                <Grid item xs={6}>
                    <LatestMessages url={url} history={history} />
                </Grid>

                <Grid item xs={6} md={6} sm={6} >
                    <ChartView url={url} history={history} />
                </Grid>
            </Grid>
        </div>
    );
};

export default Dashboard;
