import React, {useEffect, useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import {Card, CardContent, Grid, Typography, Avatar} from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import MoneyIcon from '@material-ui/icons/Money';
import CardActionArea from "@material-ui/core/CardActionArea";
import {getToken} from "../../../../Utils/common";
import FolderIcon from '@material-ui/icons/Folder';
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%'
    },
    content: {
        alignItems: 'center',
        display: 'flex'
    },
    title: {
        fontWeight: 700
    },
    avatar: {
        backgroundColor: theme.palette.error.main,
        height: 56,
        width: 56
    },
    icon: {
        height: 32,
        width: 32
    },
    difference: {
        marginTop: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        height: "64px"

    },
    differenceIcon: {
        color: theme.palette.error.dark
    },
    differenceValue: {
        color: theme.palette.error.dark,
        marginRight: theme.spacing(1)
    }
}));

const TotalTopic = props => {
    const {className, url, history, ...rest} = props;

    const classes = useStyles();
    const [values, setValues] = useState([])
    const [dataselect, setDataselect] = useState([])
    const fetchData = async () => {
        try {
            let res = await fetch(`${url}/allTopic/`,
                {
                    method: 'GET', mode: 'cors',
                    headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getToken()}
                });
            let myjson = await res.json();
            await setValues(myjson);
        } catch (err) {
            console.log('fetch data error ' + err)
        }
    }

    const goto = () => {
        history.push('topic');

    }
    useEffect(() => {
        fetchData()
    }, []);
    return (
        <Card
            {...rest}
            className={clsx(classes.root, className)}
        >
            <CardActionArea onClick={goto} style={{maxHeight: "280px"}}>
                <CardContent>
                    <Grid
                        container
                        justify="space-between"
                    >
                        <Grid item>
                            <Typography
                                className={classes.title}
                                color="textSecondary"
                                gutterBottom
                                variant="body2"
                            >
                                TOPICS
                            </Typography>
                            <Typography variant="h3">{values.length}</Typography>
                        </Grid>
                        <Grid item>
                            <Avatar className={classes.avatar}>
                                <FolderIcon className={classes.icon}/>
                            </Avatar>
                        </Grid>
                        <Grid xs={12}>
                            <div className={classes.difference}></div>
                        </Grid>

                    </Grid>


                </CardContent>
            </CardActionArea>
        </Card>
    );
};

TotalTopic.propTypes = {
    className: PropTypes.string
};

export default TotalTopic;
