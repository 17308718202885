import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/styles';
import {IconButton, Grid, Typography} from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import axios from "axios"
import {ProductsToolbar, ProductCard} from './components';
import uuid from 'uuid/v1';
import mockData from './data';
import {getToken} from "../../Utils/common";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3)
    },
    content: {
        marginTop: theme.spacing(2)
    },
    pagination: {
        marginTop: theme.spacing(3),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end'
    }
}));


const Sourcelist = props => {
    const {history, url} = props;
    const classes = useStyles();

    const [values, setValues] = useState([])
    const fetchData = async () => {
        try {
            let res = await fetch(`${url}/allSource/`,
                {
                    method: 'GET', mode: 'cors',
                    headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getToken()}
                });
            let myjson = await res.json();
            await setValues(myjson);
        } catch (err) {
            alert('fetch data error ' + err)
        }
    }

    useEffect(() => {
        fetchData()
    }, []);


    return (
        <div className={classes.root}>
            <ProductsToolbar history={history}/>
            <div className={classes.content}>
                <Grid
                    container
                    spacing={3}
                >
                    {values.map(product => (
                        <Grid
                            item
                            key={uuid()}
                            lg={4}
                            md={6}
                            xs={12}
                        >
                            <ProductCard product={product} url={url} history={history} setValues={setValues}/>
                        </Grid>
                    ))}
                </Grid>
            </div>
            <div className={classes.pagination}>
                <Typography variant="caption">1-6 of {values.length}</Typography>
                <IconButton>
                    <ChevronLeftIcon/>
                </IconButton>
                <IconButton>
                    <ChevronRightIcon/>
                </IconButton>
            </div>
        </div>
    );
};

export default Sourcelist;
