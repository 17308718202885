import {HotKeys} from "react-hotkeys";
import React, {Component} from 'react';
import {render} from 'react-dom';
import Flows from "../flows";

class Grafana extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isEditing: true
        }

        this.keyMap = {
            TEST: "t"
        };

        this.handlers = {
            TEST: (e) => {
                this.setEditing();
            }
        };
    }

    setEditing = () => {
        this.setState({
            isEditing: !this.state.isEditing
        })
    }


    render() {
        return (
            <HotKeys keyMap={this.keyMap} handlers={this.handlers}>
                <span>My HotKeys are effective here</span><br/>
                <b>isEditing: {this.state.isEditing.toString()}</b><br/>

                {this.props.children}
            </HotKeys>
        );
    }
}

export default Grafana;

