import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/styles';
import {
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Grid,
    Divider,
    FormControlLabel,
    Checkbox,
    Typography,
    Button, TextField
} from '@material-ui/core';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import axios from "axios"
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import {getToken} from "../../../../Utils/common";

const useStyles = makeStyles(() => ({
    root: {},
    item: {
        display: 'flex',
        flexDirection: 'column'
    }
}));

const Notifications = props => {
    const {className, path, history, ...rest} = props;

    const classes = useStyles();
    const [values, setValues] = useState({
        name: '',
        modelID: '',
        username: '',
        password: '',
        modelName: '',
        host: "",
        topic: "",
        Apikey: ""
    });

    const handleChange = event => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
    };
    const handlesend = () => {

        history.push("/apis")

    }
    const [open, setOpen] = React.useState(false);
    const handleClick = () => {
        setOpen(true);
    };

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);

    };
    //const [value, values] = useState([])
    const fetchData = async () => {
        try {
            let res = await fetch(`${path}/api/` + history.location.state.detail,
                {
                    method: 'GET', mode: 'cors',
                    headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getToken()}
                });
            let myjson = await res.json();
            console.log(myjson)
            await setValues(myjson[0]);


        } catch (err) {
            alert('fetch data error ' + err)
        }
    }

    useEffect(() => {
        fetchData()
    }, []);
    return (
        <Card
            {...rest}
            className={clsx(classes.root, className)}
        >
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success">
                    info added with success

                </Alert>
            </Snackbar>

            <form>
                <CardHeader
                    subheader=""
                    title=" Api information"
                />
                <Divider/>
                <CardContent>
                    <Grid
                        container
                        spacing={6}
                        wrap="wrap"
                    >
                        <Grid
                            className={classes.item}
                            item
                            md={4}
                            sm={6}
                            xs={12}
                        >

                            <TextField
                                fullWidth
                                label="Api Name"
                                margin="dense"
                                name="modelName"
                                required
                                variant="outlined"
                                value={values.modelName}
                                onChange={handleChange}
                            />
                            <TextField
                                fullWidth
                                label="Host"
                                margin="dense"
                                name="host"

                                variant="outlined"
                                value={values.host}
                                onChange={handleChange}
                            />
                            <TextField
                                fullWidth
                                label="Username"
                                margin="dense"
                                name="username"
                                required
                                variant="outlined"
                                value={values.username}
                                onChange={handleChange}
                            />


                        </Grid>
                        <Grid
                            className={classes.item}
                            item
                            md={4}
                            sm={6}
                            xs={12}
                        >

                            <TextField
                                fullWidth
                                label="Model ID "
                                margin="dense"
                                name="modelID"
                                required
                                variant="outlined"
                                value={values.modelID}
                                onChange={handleChange}
                            />
                            <TextField
                                fullWidth
                                label="Topic "
                                margin="dense"
                                name="topic"
                                required
                                variant="outlined"
                                value={values.topic}
                                onChange={handleChange}
                            />


                            <TextField
                                fullWidth
                                label="Password"
                                margin="dense"
                                name="password"
                                type="password"
                                variant="outlined"
                                value={values.password}
                                onChange={handleChange}
                            />


                        </Grid>

                    </Grid><Grid
                    className={classes.item}
                    item
                    md={4}
                    sm={12}
                    xs={12}
                >
                    <TextField
                        fullWidth
                        multiline
                        label="Api key"
                        margin="dense"
                        name="Apikey"
                        variant="outlined"
                        value={values.Apikey}
                        onChange={handleChange}
                    /></Grid>
                </CardContent>
                <Divider/>
                <CardActions>
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={handlesend}
                    >
                        Back
                    </Button>
                </CardActions>
            </form>
        </Card>
    );
};

Notifications.propTypes = {
    className: PropTypes.string
};

export default Notifications;
