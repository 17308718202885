import React, {useEffect, useState} from 'react';
import {fade, makeStyles, useTheme} from '@material-ui/core/styles';
import SearchBar from 'material-ui-search-bar' ;
import {Grid, IconButton} from "@material-ui/core";
import {RouteWithLayout, SearchInput} from "../../Components";
import Divider from "@material-ui/core/Divider";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Popper from '@material-ui/core/Popper';
import SettingsIcon from '@material-ui/icons/Settings';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ButtonBase from '@material-ui/core/ButtonBase';
import InputBase from '@material-ui/core/InputBase';
import axios from "axios";
import PlaylistPlayIcon from '@material-ui/icons/PlaylistPlay';
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import CardHeader from "@material-ui/core/CardHeader";
import Avatar from "@material-ui/core/Avatar";
import DeleteIcon from "@material-ui/icons/Delete";
import CardActionArea from "@material-ui/core/CardActionArea";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ReactJson from 'react-json-view'
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import PropTypes from 'prop-types';

import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import clsx from "clsx";
import uuid from 'uuid/v1';
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import ListSubheader from "@material-ui/core/ListSubheader";
import ScrollBound from "react-scroll-bound";
import {getToken} from "../../Utils/common";
import Box from "@material-ui/core/Box";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Schema from "../Schema";

const options = [
    'Delete ALL'

];

const ITEM_HEIGHT = 48;
const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        maxWidth: 600,
        backgroundColor: theme.palette.background.paper,
        position: 'relative',
        overflow: 'auto',
        maxHeight: 800,
    }, search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    button: {
        fontSize: 13,
        width: '100%',
        textAlign: 'left',
        paddingBottom: 8,
        color: '#586069',
        fontWeight: 600,
        '&:hover,&:focus': {
            color: '#0366d6',
        },
        '& span': {
            width: '100%',
        },
        '& svg': {
            width: 16,
            height: 16,
        },
    },
    tag: {
        marginTop: 3,
        height: 40,
        padding: '.15em 4px',
        fontWeight: "20%",
        lineHeight: '15px',
        borderRadius: 2,
        cursor: "pointer",
    },
    popper: {
        border: '1px solid rgba(27,31,35,.15)',
        boxShadow: '0 3px 12px rgba(27,31,35,.15)',
        borderRadius: 3,
        width: 300,
        zIndex: 1,
        fontSize: 13,
        color: '#586069',
        backgroundColor: '#f6f8fa',
    },
    header: {
        borderBottom: '1px solid #e1e4e8',
        padding: '8px 10px',
        fontWeight: 600,
    },
    inputBase: {
        padding: 10,
        width: '100%',
        borderBottom: '1px solid #dfe2e5',
        '& input': {
            borderRadius: 4,
            backgroundColor: theme.palette.common.white,
            padding: 8,
            transition: theme.transitions.create(['border-color', 'box-shadow']),
            border: '1px solid #ced4da',
            fontSize: 14,
            '&:focus': {
                boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
                borderColor: theme.palette.primary.main,
            },
        },
    },
    paper: {
        boxShadow: 'none',
        margin: 0,
        color: '#586069',
        fontSize: 13,
    }, pagination: {
        marginTop: theme.spacing(3),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end'
    },
    option: {
        minHeight: 'auto',
        alignItems: 'flex-start',
        padding: 8,
        '&[aria-selected="true"]': {
            backgroundColor: 'transparent',
        },
        '&[data-focus="true"]': {
            backgroundColor: theme.palette.action.hover,
        },
    },
    popperDisablePortal: {
        position: 'relative',
    },
    iconSelected: {
        width: 17,
        height: 17,
        marginRight: 5,
        marginLeft: -2,
    },
    color: {
        width: 14,
        height: 14,
        flexShrink: 0,
        borderRadius: 3,
        marginRight: 8,
        marginTop: 2,
    },
    text: {
        flexGrow: 1,
    },
    close: {
        opacity: 0.6,
        width: 18,
        height: 18,
    },
    demo: {
        backgroundColor: theme.palette.background.paper,
    },
}));

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const DbaseManager = props => {
    const {history, url} = props;
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const [labels, setlabels] = React.useState([])

    const fetchData = async () => {
        try {
            let res = await fetch(`${url}/allindex`,
                {
                    method: 'GET', mode: 'cors',
                    headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getToken()}
                });
            let myjson = await res.json();
            await setlabels(myjson);

        } catch (err) {
            alert('fetch data error ' + err)
        }
    }
    const fetchDataIndex = async (param) => {
        try {
            setdeleteid({
                name: param, id: "",
                type: ""
            });
            let res = await fetch(`${url}/getIndex/` + param,
                {
                    method: 'GET', mode: 'cors',
                    headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getToken()}
                });
            let myjson = await res.json();

            await setData(myjson);

        } catch (err) {
            alert('fetch data error ' + err)
        }
    }

    useEffect(() => {
        fetchData()
    }, []);

    const [value, setValue] = React.useState([]);
    const [deleteid, setdeleteid] = React.useState({
        name: "",
        id: "",
        type: ""

    });
    const [data, setData] = React.useState([]);
    const [pendingValue, setPendingValue] = React.useState([]);
    const theme = useTheme();

    const handleClick = (event) => {
        setPendingValue(value);
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (event, reason) => {
        if (reason === 'toggleInput') {
            return;
        }
        setValue(pendingValue);
        if (anchorEl) {
            anchorEl.focus();
        }
        setAnchorEl(null);
    };

    const [openDelete, setOpenDelete] = useState(false);
    const [openok, setOpen] = useState(false);
    const [openDelete1, setOpenDelete1] = useState(false);
    const [openDelete2, setOpenDelete2] = useState(false);

    const handleOpenDelete = (id, name, type) => {
        setdeleteid({
            name: name, id: id,
            type: type
        });
        setOpenDelete(true);
    }
    const handleCloseDelete = () => {
        setOpenDelete(false);
        setOpenDelete1(false);
        setOpenDelete2(false)
    }
    const handleDeleteContinue = async () => {
        try {
            let res = await fetch(`${url}/delete/` + deleteid.name + "/" + deleteid.type + "/" + deleteid.id, {
                method: 'GET',
                mode: 'cors',
                headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getToken()}
            })
            let myjson = await res.json();
            myjson = myjson.filter(js => js._id != deleteid.id)
            await setData(myjson);
            handleCloseDelete()

        } catch (error) {
            console.error('Error error:', error)
        }
    }
    const handleDelete = async () => {
        try {
            let res = await fetch(`${url}/deleteIndex/` + deleteid.name, {
                method: 'GET',
                mode: 'cors',
                headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getToken()}
            })


            fetchData()
            handleCloseDelete()
            handleClose1()

        } catch (error) {
            console.error('Error error:', error)
        }
    }

    const handleDeleteALL = async () => {
        try {
            let res = await fetch(`${url}/deleteALLIndex/` + deleteid.name, {
                method: 'GET',
                mode: 'cors',
                headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getToken()}
            })


            fetchData()
            handleCloseDelete()
            handleClose1()

        } catch (error) {
            console.error('Error error:', error)
        }
    }


    const [checked, setChecked] = React.useState(false);
    const [obj, setObj] = React.useState({});

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'red' : undefined;
    const handleSend = (name) => {
        fetchDataIndex(name)
        setdeleteid({
            name: name, id: "",
            type: ""
        });

    };
    const [anchorEl1, setAnchorEl1] = React.useState(null);
    const open1 = Boolean(anchorEl1);

    const handleClick1 = (event) => {
        setAnchorEl1(event.currentTarget);
    };

    const handleClose1 = () => {
        setAnchorEl1(null);
    };
    const handleOpen = (name) => {
        setdeleteid({
            name: name, id: "",
            type: ""
        });

        setOpenDelete1(true);
    }

    const handleOpen1 = () => {

        setOpenDelete2(true);

    }


    const handleClickOpen = (variable) => {


        setOpen(true);
        setObj(variable)
    };
    const handleClosedialog = () => {
        setOpen(false);
    };
    const [value5, setValue5] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue5(newValue);
    };
    return (
        <Box sx={{width: '100%'}}>
            <Tabs
                value={value5}
                onChange={handleChange}
                textColor="secondary"
                indicatorColor="secondary"
                aria-label="secondary tabs example"
            >
                <Tab label="Indexes Manager" {...a11yProps(0)} />
                <Tab label="Template Manager" {...a11yProps(1)} />
            </Tabs>
            <TabPanel value={value5} index={0}>
                <div>

                    <Grid
                        container
                        spacing={6}
                        wrap="wrap"
                    >

                        <Grid
                            className={classes.item}
                            item
                            md={4}
                            sm={4}
                            xs={12}
                        >

                            <Card className={classes.demo}>
                                <CardHeader
                                    title="Existing Indexes"

                                />
                                <Divider></Divider>
                                <List className={classes.root}>
                                    {labels.map((val) => {
                                        const labelId = `${val.name}`;

                                        return (
                                            <ListItem key={val.name} role={undefined} dense button onClick={() => {
                                                handleSend(val.name)
                                            }}>
                                                <ListItemIcon>
                                                </ListItemIcon>
                                                <ListItemText id={labelId} primary={`${val.name}`}/>
                                                <ListItemSecondaryAction>
                                                    <IconButton edge="end" aria-label="comments">
                                                        <PlaylistPlayIcon onClick={() => {
                                                            handleSend(val.name)
                                                        }}/>
                                                    </IconButton>
                                                    <IconButton edge="end" aria-label="comments">
                                                        <DeleteIcon onClick={() => {
                                                            handleOpen(val.name)
                                                        }}/>
                                                    </IconButton>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        );
                                    })}
                                </List>
                                <Grid>
                                    {value.map((label) => (
                                        <div
                                            onClick={
                                                () => handleSend(label.name)
                                            }

                                            key={label.name}
                                            className={classes.tag}
                                            style={{
                                                backgroundColor: label.color,
                                                color: theme.palette.getContrastText(label.color),
                                            }}
                                        >
                                            {label.name}
                                        </div>
                                    ))}
                                </Grid>
                            </Card>

                        </Grid>
                        <Grid
                            className={classes.item}
                            item
                            md={8}
                            sm={6}
                            xs={6}


                        >
                            <Card variant="outlined">
                                <CardHeader
                                    title={"Last Messages"}
                                    action={
                                        <IconButton aria-label="settings" onClick={handleClick1}>
                                            <MoreVertIcon/>
                                        </IconButton>
                                    }

                                />

                                <Menu
                                    id="long-menu"
                                    anchorEl={anchorEl1}
                                    keepMounted
                                    open={open1}

                                    onClose={handleClose1}
                                    PaperProps={{
                                        style: {
                                            maxHeight: ITEM_HEIGHT * 4.5,
                                            width: '20ch',
                                        },
                                    }}
                                >
                                    {options.map((option) => (
                                        <MenuItem key={option} selected={option === 'Pyxis'} onClick={handleOpen1}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </Menu>

                                <Grid
                                    container
                                    spacing={4}
                                    justify="center"
                                >
                                    {(data.length == 0) ?
                                        <img

                                            alt="no data"
                                            className={classes.image}
                                            src="/images/no_data.png"

                                        />
                                        :
                                        data.map(dt => (
                                            <Grid
                                                item
                                                lg={3}
                                                sm={6}
                                                xl={3}
                                                xs={12}
                                                key={uuid()}
                                            >

                                                <Card

                                                >
                                                    <CardActionArea>
                                                        <CardHeader

                                                            action={
                                                                <IconButton aria-label="settings" onClick={() => {
                                                                    handleOpenDelete(dt._id, dt._index, dt._type)
                                                                }}>
                                                                    <DeleteIcon/>
                                                                </IconButton>
                                                            }

                                                        />

                                                        <CardContent onClick={() => {
                                                            handleClickOpen(dt._source)
                                                        }}>
                                                            <Grid
                                                                container
                                                                justify="space-between"
                                                            >
                                                                <Grid item>
                                                                    <Typography
                                                                        className={classes.title}
                                                                        color="inherit"
                                                                        gutterBottom
                                                                        variant="body2"
                                                                    >

                                                                    </Typography>
                                                                    {dt._id}
                                                                </Grid>
                                                                <Grid item>
                                                                    <Avatar className={classes.avatar}>
                                                                        <AccountTreeIcon className={classes.icon}/>
                                                                    </Avatar>
                                                                </Grid>
                                                            </Grid>
                                                        </CardContent>
                                                    </CardActionArea>
                                                </Card>
                                            </Grid>

                                        ))}


                                </Grid>

                                <div className={classes.pagination}>

                                    <IconButton>
                                        <ChevronLeftIcon/>
                                    </IconButton>
                                    <Typography variant="caption">   {data.length}</Typography>
                                    <IconButton>
                                        <ChevronRightIcon/>
                                    </IconButton>
                                </div>
                            </Card>

                        </Grid>
                    </Grid>
                    <Dialog open={openDelete} onClose={handleCloseDelete}>
                        <DialogTitle disableTypography={false}>Confirmation</DialogTitle>
                        <DialogContent dividers>
                            Are you sure you want to delete this data ?
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleDeleteContinue} color='primary' size='small'> yes </Button>
                            <Button onClick={handleCloseDelete} color='primary' size='small'> No </Button>
                        </DialogActions>

                    </Dialog>
                    <Dialog open={openDelete1} onClose={handleCloseDelete}>
                        <DialogTitle disableTypography={false}>Confirmation</DialogTitle>
                        <DialogContent dividers>
                            Are you sure you want to delete all data ?
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleDelete} color='primary' size='small'> yes </Button>
                            <Button onClick={handleCloseDelete} color='primary' size='small'> No </Button>
                        </DialogActions>

                    </Dialog>
                    <Dialog open={openDelete2} onClose={handleCloseDelete}>
                        <DialogTitle disableTypography={false}>Confirmation</DialogTitle>
                        <DialogContent dividers>
                            Are you sure you want to delete the index definitely ?
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleDeleteALL} color='primary' size='small'> yes </Button>
                            <Button onClick={handleCloseDelete} color='primary' size='small'> No </Button>
                        </DialogActions>

                    </Dialog>
                    <Dialog onClose={handleClosedialog} aria-labelledby="customized-dialog-title" open={openok}>
                        <DialogTitle id="customized-dialog-title" onClose={handleClosedialog}>
                            Description
                        </DialogTitle>
                        <DialogContent dividers>
                            <ReactJson src={obj}/>
                        </DialogContent>

                    </Dialog>
                </div>
            </TabPanel>
            <TabPanel value={value5} index={1}>
                <Schema history={history} url={url}
                />
            </TabPanel>


        </Box>
    );
};

export default DbaseManager;
