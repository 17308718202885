import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/styles';
import {Button, Card, CardActions, CardContent, CardHeader, Divider, Grid, TextField} from '@material-ui/core';

import {SearchInput} from '../../../../Components';
import Snackbar from "@material-ui/core/Snackbar";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import axios from "axios";
import MuiAlert from "@material-ui/lab/Alert";

const useStyles = makeStyles(theme => ({
    root: {},
    row: {
        height: '42px',
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(1)
    },
    spacer: {
        flexGrow: 1
    },
    importButton: {
        marginRight: theme.spacing(1)
    },
    exportButton: {
        marginRight: theme.spacing(1)
    },
    searchInput: {
        marginRight: theme.spacing(1)
    }
}));

const Apidetails = props => {
    const {className, history, setValues, url, values, ...rest} = props;

    const classes = useStyles();
    const routeChange = () => {
        let path = `apidetails`;
        history.push(path);
    }
    const handleChange = event => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
    };

    const [open, setOpen] = React.useState(false);
    const handleClick = () => {
        setOpen(true);
    };

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);

    };


    return (
        <Card
            {...rest}
            className={clsx(classes.root, className)}
        >
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success">
                    info added with success

                </Alert>
            </Snackbar>

            <form>
                <CardHeader
                    subheader=""
                    title=" Api information"
                />
                <Divider/>
                <CardContent>
                    <Grid
                        container
                        spacing={6}
                        wrap="wrap"
                    >
                        <Grid
                            className={classes.item}
                            item
                            md={4}
                            sm={6}
                            xs={12}
                        >

                            <TextField
                                fullWidth
                                label="Api Name"
                                margin="dense"
                                name="nameapi"
                                required
                                variant="outlined"
                                value={values.nameapi}
                                onChange={handleChange}
                            />
                            <TextField
                                fullWidth
                                label="Host"
                                margin="dense"
                                name="host"

                                variant="outlined"
                                value={values.host}
                                onChange={handleChange}
                            />
                            <TextField
                                fullWidth
                                label="Username"
                                margin="dense"
                                name="username"
                                required
                                variant="outlined"
                                value={values.username}
                                onChange={handleChange}
                            />


                        </Grid>
                        <Grid
                            className={classes.item}
                            item
                            md={4}
                            sm={6}
                            xs={12}
                        >

                            <TextField
                                fullWidth
                                label="Model ID "
                                margin="dense"
                                name="modelID"
                                required
                                variant="outlined"
                                value={values.modelID}
                                onChange={handleChange}
                            />


                            <TextField
                                fullWidth
                                label="Password"
                                margin="dense"
                                name="password"
                                type="password"
                                variant="outlined"
                                value={values.password}
                                onChange={handleChange}
                            />
                            <TextField
                                fullWidth
                                label="Api key"
                                margin="dense"
                                name="Apikey"
                                variant="outlined"
                                value={values.Apikey}
                                onChange={handleChange}
                            />

                        </Grid>

                    </Grid>
                </CardContent>
                <Divider/>

            </form>
        </Card>
    );
};

Apidetails.propTypes = {
    className: PropTypes.string
};

export default Apidetails;
