import React, { useEffect, useState } from "react";
import clsx from "clsx";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import SaveIcon from "@material-ui/icons/Save";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardActions,
  CardHeader,
  CardContent,
  Button,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  TableSortLabel,
  Grid,
  Typography,
  Avatar,
} from "@material-ui/core";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";

import mockData from "./data";
import { StatusBullet } from "../../../../Components";
import CardActionArea from "@material-ui/core/CardActionArea";
import SettingsInputHdmiIcon from "@material-ui/icons/SettingsInputHdmi";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import { getToken } from "../../../../Utils/common";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
  content: {
    alignItems: "center",
    display: "flex",
  },
  title: {
    fontWeight: 700,
  },
  avatar: {
    backgroundColor: theme.palette.success.main,
    height: 56,
    width: 56,
  },
  icon: {
    height: 32,
    width: 32,
  },
  difference: {
    marginTop: theme.spacing(0.5),
    display: "flex",
    alignItems: "center",
  },
  differenceIcon: {
    color: theme.palette.success.dark,
  },
  icondown: {
    color: theme.palette.warning.dark,
    marginRight: theme.spacing(0.5),
  },
  icondowntype: {
    color: theme.palette.warning.dark,
  },
  caption: {
    marginRight: theme.spacing(0.5),
  },
  differenceValue: {
    color: theme.palette.success.dark,
    marginRight: theme.spacing(0.5),
  },
}));

const statusColors = {
  delivered: "success",
  pending: "info",
  refunded: "danger",
};

const LatestOrders = (props) => {
  const { className, url, history, ...rest } = props;

  const classes = useStyles();
  const [values, setValues] = useState("");
  const [run, setRun] = useState(0);
  const [cpu, setcpu] = useState(0);

  const [inbyte, setinbyte] = useState(0);
  const [outbyte, setoutbyte] = useState(0);
  const [inmessage, setinmessage] = useState(0);
  var l = 0,
    m = 0,
    k = 0;
  const fetchDatabytepersecout = async () => {
    try {
      var d = new Date();
      var n = d.toISOString();
      let res = await fetch(`${url}/getMetrics/` + "out", {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getToken(),
        },
      });
      let myjson = await res.json();
      var vl = myjson.data.result;
      var variable = 0;
      for (let i = 0; i < vl.length; i++) {
        variable = parseInt(vl[i].value[1]) + variable;
        console.log(vl[i]);
      }

      var val = variable / 2000;
      setoutbyte(val.toString());
    } catch (err) {
      console.log("fetch data error " + err);
    }
  };
  const fetchDatamessage = async () => {
    try {
      var d = new Date();
      var n = d.toISOString();
      let res = await fetch(`${url}/getMetrics/` + "messages", {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getToken(),
        },
      });
      let myjson = await res.json();
      var vl = myjson.data.result;
      var variable = 0;
      for (let i = 0; i < vl.length; i++) {
        variable = parseInt(vl[i].value[1]) + variable;
        console.log(vl[i]);
      }

      var val = variable;
      setinmessage(val.toString());
    } catch (err) {
      console.log("fetch data error " + err);
    }
  };

  const fetchDataCpu = async () => {
    try {
      var d = new Date();
      var n = d.toISOString();
      let res = await fetch(`${url}/getMetrics/` + "cpu", {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getToken(),
        },
      });
      let myjson = await res.json();
      var vl = myjson.data.result;
      var variable = 0;

      var val = Number.parseFloat(vl[0].value[1] * 100).toFixed(2);
      setcpu(val.toString());
    } catch (err) {
      console.log("fetch data error " + err);
    }
  };

  var i = 0,
    j = 0;
  const fetchDatabytepersec = async () => {
    try {
      var d = new Date();
      var n = d.toISOString();
      let res = await fetch(`${url}/getMetrics/` + "in", {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getToken(),
        },
      });
      let myjson = await res.json();
      var vl = myjson.data.result;
      var variable = 0;
      for (let i = 0; i < vl.length; i++) {
        variable = parseInt(vl[i].value[1]) + variable;
        console.log(vl[i]);
      }

      var val = variable / 2000;
      setinbyte(val.toString());
    } catch (err) {
      console.log("fetch data error " + err);
    }
  };
  const fetchData = async () => {
    try {
      var d = new Date();
      var n = d.toISOString();
      let res = await fetch(`${url}/getMetrics/` + "ram", {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getToken(),
        },
      });
      let myjson = await res.json();

      var vl = myjson.data.result;
      var variable = 0;
      for (let i = 0; i < vl.length; i++) {
        variable = parseInt(vl[i].value[1]) + variable;
        console.log(vl[i]);
      }

      var val = Number.parseFloat(variable / 1000000000).toFixed(2);
      setValues(val.toString() + " G");
    } catch (err) {
      console.log("fetch data error " + err);
    }
  };
  const goto = () => {
    window.open(
      "https://www.thingwings.com/grafana/d/GCd4-WYVz/kafka-cluster-global-healthcheck?orgId=1&refresh=5s",
      "_blank"
    );
  };
  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    fetchDatabytepersec();
  }, []);
  useEffect(() => {
    fetchDatabytepersecout();
  }, []);
  useEffect(() => {
    fetchDatamessage();
  }, []);
  useEffect(() => {
    fetchDataCpu();
  }, []);

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardActionArea
        onClick={goto}
        target="_blank"
        href={
          "https://www.thingwings.com/grafana/d/GCd4-WYVz/kafka-cluster-global-healthcheck?orgId=1&refresh=5s"
        }
      >
        <CardContent>
          <Grid container justify="space-between">
            <Grid item>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
                variant="body2"
              >
                Disk (Global topic size) / CPU
              </Typography>
              <Typography variant="h3">
                {values} | {cpu + "%"}
              </Typography>
            </Grid>
            <Grid item>
              <Avatar className={classes.avatar}>
                <SaveIcon className={classes.icon} />
              </Avatar>
            </Grid>
          </Grid>
          <Grid container justify="space-between">
            <Grid xs={12}>
              <div className={classes.difference}>
                <Typography className={classes.caption} variant="caption">
                  Bytes in per sec
                </Typography>
                <Typography className={classes.differenceValue} variant="body2">
                  {inbyte} |
                </Typography>
                <Typography className={classes.caption} variant="caption">
                  Bytes out per sec
                </Typography>
                <Typography className={classes.differenceValue} variant="body2">
                  {outbyte} |
                </Typography>
                <Typography className={classes.caption} variant="caption">
                  Message in per sec
                </Typography>
                <Typography className={classes.differenceValue} variant="body2">
                  {inmessage}
                </Typography>
                <Typography
                  className={classes.caption}
                  variant="caption"
                ></Typography>
              </div>
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

LatestOrders.propTypes = {
  className: PropTypes.string,
};

export default LatestOrders;
