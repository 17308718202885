import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/styles';
import {
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Grid,
    Divider,
    FormControlLabel,
    Checkbox,
    Typography,
    Button, TextField
} from '@material-ui/core';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import axios from "axios";
import Collapse from "@material-ui/core/Collapse";

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import {getToken} from "../../../../Utils/common";

const useStyles = makeStyles(() => ({
    root: {},
    item: {
        display: 'flex',
        flexDirection: 'column'
    }
}));

const Notifications = ({className, url, history, product, match, ...rest}) => {


    const classes = useStyles();

    const [topics, setTopics] = useState([])
    const [values, setValues] = useState({
        name: "",
        connectorClass: "",
        tasksMax: "",
        type: "",
        valueConverter: "",
        valueConverterschemasenable: "",
        schemaIgnore: "",

        connectionUrl: "",

        typeName: "",

        topics: "",

        keyIgnore: "",
        serveruri: "",
        topicsource: "",
        topicserver: "",
        username: "",
        password: ""
    })
    const fetchData = async () => {
        console.log(history);
        try {
            var name = ""
            if (product == undefined)
                name = history.location.state.detail
            else
                name = product.name
            let res = await fetch(`${url}/connector/` + name,
                {
                    method: 'GET', mode: 'cors',
                    headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getToken()}
                });
            let myjson = await res.json();
            await setValues(myjson);

        } catch (err) {
            alert('fetch data error ' + err)
        }
    }

    useEffect(() => {
        fetchData()
    }, []);

    const handleChange = event => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
    };

    const handleChangewithfield = event => {


        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
        if (event.target.value == "sink") {


            setExpanded2(false);
            setExpanded(true);
        } else if (event.target.value == "source") {
            setExpanded(false);
            setExpanded2(true);

        } else {
            setExpanded(false);
            setExpanded2(false);

        }
    };

    const handlesend = async () => {
        try {
            const res = await fetch(url + "/addConnector", {
                method: 'POST', mode: 'cors',
                body: JSON.stringify(values),
                headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getToken()},
            })

        } catch (error) {
            if (error) console.log(error);
        }
    }


    const [expanded, setExpanded] = React.useState(false);
    const [expanded2, setExpanded2] = React.useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };
    const handleExpandClick2 = () => {
        setExpanded2(!expanded2);
    };

    const handleCancel = () => {

        history.push('/connectors')
    }

    const [open, setOpen] = React.useState(false);
    const handleClick = () => {
        setOpen(true);
    };
    console.log(values)

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);

    };
    return (
        <Card
            {...rest}
            className={clsx(classes.root, className)}
        >
            <form>
                <CardHeader
                    subheader=""
                    title=" Connector Information"
                />
                <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="success">
                        info added with success

                    </Alert>
                </Snackbar>
                <Divider/>
                <CardContent>
                    <Grid
                        container
                        spacing={6}
                        wrap="wrap"
                    >
                        <Grid
                            className={classes.item}
                            item
                            md={6}
                            sm={6}
                            xs={12}
                        >

                            <TextField
                                fullWidth
                                label=" Name"
                                margin="dense"
                                name="name"
                                required
                                variant="outlined"
                                value={values.name}
                                onChange={handleChange}
                            />
                            {values.type == "sink" ?
                                <TextField
                                    fullWidth
                                    label=" Topics"
                                    margin="dense"
                                    name="topics"
                                    required
                                    variant="outlined"
                                    value={values.topics}
                                    onChange={handleChange}
                                />
                                : null
                            } <TextField
                            fullWidth
                            label="Tasks Max "
                            margin="dense"
                            name="tasks"
                            required
                            variant="outlined"
                            value={values.tasksMax}
                            onChange={handleChange}
                        />
                            <TextField
                                fullWidth
                                label=" Value Converter"
                                margin="dense"
                                name="value Converter"
                                required
                                variant="outlined"
                                value={values.valueConverter}
                                onChange={handleChange}
                            />
                            <TextField
                                fullWidth
                                label=" Connector Class"
                                margin="dense"
                                name="connector Class"
                                required
                                variant="outlined"
                                value={values.connectorClass}
                                onChange={handleChange}
                            />
                            <TextField
                                fullWidth
                                label=" Username"
                                margin="dense"
                                name="username"
                                required
                                variant="outlined"
                                value={values.username}
                                onChange={handleChange}
                            />

                        </Grid>
                        <Grid
                            className={classes.item}
                            item
                            md={6}
                            sm={6}
                            xs={12}
                        >

                            <TextField
                                fullWidth
                                label="Type "
                                margin="dense"
                                name="type"
                                required
                                variant="outlined"
                                value={values.type}
                                onChange={handleChange}
                            />

                            {(values.type == "sink") ?
                                <div>
                                    <TextField
                                        fullWidth
                                        label="Connection  Url "
                                        margin="dense"
                                        name="connectionUrl"
                                        required
                                        variant="outlined"
                                        value={values.connectionUrl}
                                        onChange={handleChange}
                                    />
                                    <TextField
                                        fullWidth
                                        label=" Password"
                                        margin="dense"
                                        name="password"
                                        required
                                        variant="outlined"
                                        value={values.elasticPassword}
                                        onChange={handleChange}
                                    />

                                </div>
                                :

                                <div><TextField
                                    fullWidth
                                    label="Server Uri "
                                    margin="dense"
                                    name="serveruri"
                                    required
                                    variant="outlined"
                                    value={values.serveruri}
                                    onChange={handleChange}
                                /> <TextField
                                    fullWidth
                                    label=" Topic Mqtt  "
                                    margin="dense"
                                    name="topicsource"
                                    required
                                    variant="outlined"
                                    value={values.topicsource}
                                    onChange={handleChange}
                                />


                                    <TextField
                                        fullWidth
                                        label="Password "
                                        margin="dense"
                                        name="password"
                                        required
                                        variant="outlined"
                                        value={values.password}
                                        onChange={handleChange}
                                    />


                                </div>
                            }


                        </Grid>

                    </Grid>
                </CardContent>
                <Divider/>

            </form>
        </Card>
    );
};

Notifications.propTypes = {
    className: PropTypes.string,
    name: PropTypes.string
};

export default Notifications;
