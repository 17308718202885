import React, {Fragment, useState} from 'react';
import PropTypes from 'prop-types';
import {Line} from "react-chartjs-2";
import "chartjs-plugin-streaming";
import {Doughnut} from 'react-chartjs-2';
import {setValue} from "@o2xp/react-datatable/src/components/DatatableCore/InputTypes/PickersFunction";

/**const options= {
    title: {
        display: true,
            text: 'Line chart (hotizontal scroll) sample'
    },
    scales: {
        xAxes: [{
            type: 'realtime',
            realtime: {
                duration: 20000,
                refresh: 1000,
                delay: 2000,
                onRefresh: onRefresh
            }
        }],
            yAxes: [{
            scaleLabel: {
                display: true,
                labelString: 'value'
            }
        }]
    },
    tooltips: {
        mode: 'nearest',
            intersect: false
    },
    hover: {
        mode: 'nearest',
            intersect: false
    }

};

 var chartColors = {
    red: 'rgb(255, 99, 132)',
    orange: 'rgb(255, 159, 64)',
    yellow: 'rgb(255, 205, 86)',
    green: 'rgb(75, 192, 192)',
    blue: 'rgb(54, 162, 235)',
    purple: 'rgb(153, 102, 255)',
    grey: 'rgb(201, 203, 207)'
};

 function randomScalingFactor() {
    return (Math.random() > 0.5 ? 1.0 : -1.0) * Math.round(Math.random() * 100);
}


 function webSocketInvoke(setValues) {

}
 function onRefresh(chart) {


        // query your data source and get the array of {x: timestamp, y: value} objects
   /** ws.onmessage = function (evt) {
        var received_msg = evt.data;

 let i=0;
        let values=[]
        var  dataString=received_msg.replace("�","").split("{");
            //console.log(dataString)
        let datafilter =dataString[1].split("}");
        let data = datafilter[0].split(",");
       // console.log(data)
        for (let j = 0; j< data.length; j++) {

            var keys = data[j].split(":");
            //var key=keys[0].split("\"").join("") ;
console.log(keys[1])
            values.push( i);
        }



console.log(values)

      //  console.log(values)
        chart.config.data.datasets.forEach(function(dataset) {
            console.log(dataset);
            console.log(i);
            i=i+1;
            dataset.data.push({x:Date.now(),y:randomScalingFactor()});
        });
    };

        // append the new data array to the existing chart data


}
 if ("WebSocket" in window) {
    console.log("WebSocket is supported by your Browser!");
    var ws = new WebSocket("ws://localhost:8080/","echo-protocol");

    ws.onopen = function() {
        console.log("Connection created");
    };
    ws.onclose = function() {
        console.log("Connection closed");
    };
} else {
    alert("WebSocket NOT supported by your Browser!");
}
 function  getLatestData(){

var datat ={}








}**/
function MessageDetails(props) {
    const {classes} = props;


    /**  const [data, setData] =useState( {
        datasets: [

            {
                label: 'Dataset 1 (linear interpolation)',
                backgroundColor: 'rgb(153, 102, 255)',
                borderColor: chartColors.red,
                fill: false,
                lineTension: 0,
                borderDash: [8, 4],
                data: []
            },
            {
                label: 'Dataset 2 (cubic interpolation)',
                backgroundColor: 'rgb(153, 102, 255)',
                borderColor: chartColors.blue,
                fill: false,
                cubicInterpolationMode: 'monotone',
                data: []
            }

        ]
    })
     <Line data={data} options={options}  />
     **/


    return <React.Fragment>

        <div>


        </div>
    </React.Fragment>;
}

MessageDetails.propTypes = {
    classes: PropTypes.object.isRequired
};

export default MessageDetails;