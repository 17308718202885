import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import Iframe from "react-iframe";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(1),
        height: "75vh"
    },
    myClassname: {
        border: "0"
    }
}));


const Nodered = props => {

    const classes = useStyles();
    const port = props.match.params.id;
    const [isLoaded, setIsLoaded] = useState(false);

    function handleIframeLoad() { setIsLoaded(true); }

    return (
        <div className={classes.root} id="root">

            <Box display="flex" justifyContent="center">
                {isLoaded ? <></> : <CircularProgress justifyContent="center" color="secondary" />}
            </Box>

            <Iframe url={`${process.env.REACT_APP_NODERED_HOST}/node-red/${port}/`}
                width="100%"
                height="100%"
                className={classes.myClassname}
                position="relative"
                onLoad={handleIframeLoad}
            />
        </div>
    );
};

export default Nodered;
