import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Card, CardContent, CardHeader, Grid } from '@material-ui/core';
import { emphasize, withStyles } from '@material-ui/core/styles';
import { AccountProfile, AccountDetails } from './components';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from "@material-ui/core/Box";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import PersonIcon from '@material-ui/icons/Person';
import DescriptionIcon from '@material-ui/icons/Description';
import AccountBilling from "./components/AccountBilling";
import Divider from "@material-ui/core/Divider";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItem from "@material-ui/core/ListItem";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import AccountBilling1 from "./components/AccountBilling1/AccountBilling1";
import AccountBilling2 from "./components/AccountBilling2/AccountBilling2";
import { getToken } from "../../Utils/common";
import axios from "axios";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import Cards from "./components/cards";
import Chip from "@material-ui/core/Chip";

import ContactForm from "./components/cards/Forms/ContactForm";


const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4)
    }
}));

const StyledBreadcrumb = withStyles((theme) => ({
    root: {
        backgroundColor: "#189b1c",
        height: theme.spacing(3),
        color: theme.palette.grey[800],
        fontWeight: theme.typography.fontWeightRegular,
        '&:hover, &:focus': {
            backgroundColor: theme.palette.grey[300],
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(theme.palette.grey[300], 0.12),
        },
    },
}))(Chip);

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const Account = ({ history, url }) => {
    const classes = useStyles();
    const [values, setValues] = React.useState([]);
    const [cards, setCards] = React.useState([]);
    const [value, setValue] = React.useState(0);
    const [openNew, setOpenNew] = React.useState(false);

    const handleClickOpen = () => {
        setOpenNew(true);
    };

    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState("");

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleDelete = async (id) => {
        axios.delete(url + "/card/" + id, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getToken()
            }
        }).then(response => {
            if (response) {

                fetchDataCard();

            }
        }).catch(error => {
            if (error.response.status === 401) console.log(" error ");
        });

    }

    const handleEdit = async (card) => {

        var data = {
            name: "redha card    ",
            search: "",
            number: 0,
            ccv: 155,
            date: "11/25",
            default: 0,
            email: ""

        }
        // setCard(data);

        axios.put(url + "/card/" + card.cardId, data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getToken()
            }
        }).then(response => {
            if (response) {

                fetchDataCard();

            }
        }).catch(error => {
            if (error.response.status === 401) console.log(" error ");
        });

    }

    useEffect(() => { fetchData() }, []);

    const fetchData = async () => {
        try {
            let res = await fetch(url + "/bills",
                {
                    method: 'GET', mode: 'cors',
                    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getToken() }
                });
            let myjson = await res.json();
            await setValues(myjson);

            console.log("here")
        } catch (err) {
            alert('fetch data error ' + err)
        }
    }

    useEffect(() => { fetchDataCard() }, []);

    const fetchDataCard = async () => {
        try {
            let res = await fetch(url + "/cards",
                {
                    method: 'GET', mode: 'cors',
                    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getToken() }
                });
            let myjson = await res.json();
            await setCards(myjson);

        } catch (err) {
            alert('fetch data error ' + err)
        }
    }

    function toDate(tme) {
        var unix_timestamp = tme;
        // Create a new JavaScript Date object based on the timestamp
        // multiplied by 1000 so that the argument is in milliseconds, not seconds.
        var date = new Date(unix_timestamp * 1000);
        // Hours part from the timestamp
        var hours = date.getHours();
        // Minutes part from the timestamp
        var minutes = "0" + date.getMinutes();
        // Seconds part from the timestamp
        var seconds = "0" + date.getSeconds();

        // Will display time in 10:30:23 format
        var formattedTime = formatDate(date) + " " + hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
        return formattedTime;
    }

    function formatDate(string) {
        var options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(string).toLocaleDateString([], options);
    }

    return (
        <div className={classes.root}>

            <Box sx={{  bgcolor: 'background.paper' }} >

                <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="success">
                        {message}
                    </Alert>
                </Snackbar>

                <Tabs 
                    style= {{marginRight:20}}
                    orientation="horizantal"
                    variant="scrollable"
                    value={value}
                    onChange={handleChange}
                    aria-label="Vertical tabs example"
                    sx={{ borderRight: 1 }}
                >
                    <Tab icon={<PersonIcon />} label="PROFILE" {...a11yProps(0)} />
                    <Tab icon={<DescriptionIcon />} label="BILLING" {...a11yProps(1)} />

                </Tabs>

                <TabPanel value={value} index={0}>
                    <Grid container spacing={4}>
                        <Grid item xs>
                            <AccountProfile url={url} />
                        </Grid>
                        <Grid item xs>
                            <AccountDetails url={url} />
                        </Grid>
                    </Grid>

                    <Grid container spacing={4}>
                        <Grid item lg={12} md={6} xl={8} xs={4}>
                            <ContactForm path={url} />
                        </Grid>
                    </Grid>
                </TabPanel>

                <TabPanel value={value} index={1}>
                    <Grid container spacing={4}>

                        <Grid item lg={4} md={6} xl={4} xs={12}>
                            <AccountBilling1 url={url} history={history}></AccountBilling1>
                        </Grid>

                        <Grid item xs={4}>
                            <AccountBilling url={url} history={history}></AccountBilling>
                        </Grid>

                        <Grid item xs={4}>
                            <AccountBilling2 url={url} history={history}></AccountBilling2>
                        </Grid>

                        <Grid item xs={12}>
                            <Card sx={{ minWidth: 275 }}>
                                <form autoComplete="off" noValidate>
                                    <CardHeader subheader="" title="Payment Methods"
                                        action={
                                            <Button variant="outlined" edge={"end"} size="small"
                                                onClick={handleClickOpen}>
                                                Add New Method
                                            </Button>
                                        }>
                                    </CardHeader>

                                    <CardContent>
                                        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                            {cards.map((val) => {
                                                return (<ListItem alignItems="flex-start">
                                                    <ListItemAvatar>
                                                        <Avatar sx={{ width: 56, height: 56 }} variant="rounded"
                                                            src={"/static/images/" + val.cardType.toString() + ".png"}>
                                                        </Avatar>
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        primary={val.cardType}
                                                        secondary={
                                                            <React.Fragment>
                                                                {"************" + val.cardLast4}
                                                            </React.Fragment>
                                                        }
                                                    />
                                                    <ListItemText
                                                    />
                                                    {(val.default) ?
                                                        <Button disabled> Default</Button> :
                                                        <Button onClick={() => {
                                                            handleDelete(val.cardId)

                                                        }}>Delete</Button>
                                                    }
                                                    <Button onClick={() => {
                                                        handleEdit(val)

                                                    }}>Edit</Button>
                                                </ListItem>)

                                            })
                                            }

                                        </List>

                                    </CardContent>
                                </form>
                            </Card>
                            <Cards history={history} url={url} openNew={openNew} setOpenNew={setOpenNew}
                                fetchDataCard={fetchDataCard} setCards={setCards} cards={cards}></Cards>

                        </Grid>

                        <Grid item xs={12}>
                            <Card sx={{ minWidth: 275 }}>
                                <form autoComplete="off" noValidate>
                                    <CardHeader subheader="" title="Billing History" />
                                    <CardContent>
                                        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>

                                            <Grid container spacing={1}>
                                                <ListItem alignItems="flex-start">

                                                    <Grid item xs={3}>
                                                        <ListItemText
                                                            primary={
                                                                <React.Fragment>
                                                                    <Button edge="end"
                                                                        href="#text-buttons">Price</Button>
                                                                </React.Fragment>
                                                            }
                                                        />
                                                    </Grid>

                                                    <Grid item xs={3}>
                                                        <ListItemText
                                                            primary={
                                                                <React.Fragment>
                                                                    <Button edge="end"
                                                                        href="#text-buttons">Description</Button>
                                                                </React.Fragment>
                                                            }
                                                        /></Grid>
                                                    <Grid item xs={3}>
                                                        <ListItemText
                                                            primary={
                                                                <React.Fragment>
                                                                    <Button edge="end"
                                                                        href="#text-buttons">Date</Button>
                                                                </React.Fragment>
                                                            }
                                                        />
                                                    </Grid>
                                                    <Grid item xs={3}>

                                                        <ListItemText
                                                            primary={
                                                                <React.Fragment>
                                                                    <Button edge="end"
                                                                        href="#text-buttons">Status</Button>
                                                                </React.Fragment>
                                                            }
                                                        />
                                                    </Grid>
                                                    <Grid item xs={3}>

                                                        <ListItemText
                                                            primary={
                                                                <React.Fragment>
                                                                    <Button edge="end"
                                                                        href="#text-buttons">Invoice</Button>
                                                                </React.Fragment>
                                                            }
                                                        />
                                                    </Grid>
                                                </ListItem>
                                            </Grid>


                                            <Divider variant="inset" component="li" />
                                            <Divider variant="inset" component="li" />


                                            {values.map((val) => {
                                                return (
                                                    <Grid container spacing={1}>
                                                        <ListItem alignItems="flex-start">
                                                            <Grid item xs={3}>

                                                                <ListItemText
                                                                    primary={
                                                                        <React.Fragment>
                                                                            {val.charges.data[0].amount / 100 + "  " + val.currency}
                                                                        </React.Fragment>
                                                                    }
                                                                />
                                                            </Grid>

                                                            <Grid item xs={3}>
                                                                <ListItemText
                                                                    primary={
                                                                        <React.Fragment>
                                                                            {val.description}
                                                                        </React.Fragment>
                                                                    }
                                                                />
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <ListItemText
                                                                    primary={
                                                                        <React.Fragment>
                                                                            {toDate(val.charges.data[0].created)}
                                                                        </React.Fragment>
                                                                    }
                                                                />
                                                            </Grid>
                                                            <Grid item xs={3}>
                                                                <ListItemText
                                                                    primary={
                                                                        <React.Fragment>
                                                                            <ListItemText primary={<React.Fragment>
                                                                                <Typography
                                                                                    variant="h9"
                                                                                    component="h4"
                                                                                >

                                                                                    {
                                                                                        val.status == "succeeded" ?
                                                                                            <StyledBreadcrumb
                                                                                                component="a" href="#"
                                                                                                label={val.status}
                                                                                                style={{ "color": "white" }} />
                                                                                            :
                                                                                            <StyledBreadcrumb
                                                                                                component="a" href="#"
                                                                                                label={val.status}
                                                                                                style={{ "color": "white" }} />
                                                                                    }

                                                                                </Typography>

                                                                            </React.Fragment>} />
                                                                        </React.Fragment>
                                                                    }
                                                                />
                                                            </Grid>
                                                            <Grid item xs={3}>

                                                                <ListItemText
                                                                    primary={
                                                                        <React.Fragment>
                                                                            <Link
                                                                                component="button"
                                                                                variant="body2"
                                                                                onClick={() => {
                                                                                    window.open(val.charges.data[0].receipt_url);
                                                                                }}
                                                                            >
                                                                                Invoice
                                                                            </Link>
                                                                        </React.Fragment>
                                                                    }
                                                                />
                                                            </Grid>
                                                        </ListItem>

                                                    </Grid>

                                                )

                                            })}
                                            <Divider variant="inset" component="li" />


                                        </List>

                                    </CardContent>
                                </form>
                            </Card>

                        </Grid>

                    </Grid>
                  
                </TabPanel>

            </Box>
        </div>

    );
};

export default Account;
