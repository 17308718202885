import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/styles';
import {
    Card,
    CardContent,
    CardActions,
    Typography,
    Grid,
    Divider, TableCell, TextField
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

import MoreVertIcon from '@material-ui/icons/MoreVert';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import GetAppIcon from '@material-ui/icons/GetApp';
import {StatusBullet} from "../../../../Components";
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import CardActionArea from "@material-ui/core/CardActionArea";
import Snackbar from "@material-ui/core/Snackbar";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Collapse from "@material-ui/core/Collapse";
import {createFilterOptions} from "@material-ui/lab";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {getToken} from "../../../../Utils/common";

const filter = createFilterOptions();
const useStyles = makeStyles(theme => ({
    root: {},
    imageContainer: {
        height: 64,
        width: 64,
        margin: '0 auto',
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: '5px',
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    image: {
        width: '100%'
    },
    statsItem: {
        display: 'flex',
        alignItems: 'center'
    },
    statsIcon: {
        color: theme.palette.icon,
        marginRight: theme.spacing(1)
    }
}));


const Topicdetails = props => {
    const {className, url, history, values, setValues, ...rest} = props;

    const classes = useStyles();
    const statusColors = {
        delivered: 'success',
        pending: 'info',
        refunded: 'danger'
    }
    const handleChange = event => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
    };
    const [topics, setTopics] = useState([])
    const fetchData = async () => {
        try {
            let res = await fetch(`${url}/allTopicConnector`,
                {
                    method: 'GET', mode: 'cors',
                    headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getToken()}
                });
            let myjson = await res.json();
            await setTopics(myjson);

        } catch (err) {
            alert('fetch data error ' + err)
        }
    }

    useEffect(() => {
        fetchData()
    }, []);


    return (
        <Card
            {...rest}
            className={clsx(classes.root, className)}
        >
            <form>

                <CardHeader
                    subheader=""
                    title=" Add Topic "
                />
                <Divider/>
                <CardContent>
                    <Grid
                        container
                        spacing={6}
                        wrap="wrap"
                    >
                        <Grid
                            className={classes.item}
                            item
                            md={4}
                            sm={4}
                            xs={12}
                        >

                            <Autocomplete fullWidth aria-required

                                          value={values.name}
                                          onChange={(event, newValue) => {
                                              console.log(newValue)
                                              if (typeof newValue === 'string') {
                                                  console.log(newValue)
                                                  setValues({
                                                      ...values,
                                                      ["name"]: newValue
                                                  });
                                              } else if (newValue && newValue.inputValue) {
                                                  console.log("here ")
                                                  setValues({
                                                      ...values,
                                                      ["name"]: newValue.inputValue
                                                  });
                                              } else {
                                                  console.log("here 2")
                                                  if (newValue != null)
                                                      setValues({
                                                          ...values,
                                                          ["name"]: newValue.name
                                                      });
                                              }
                                          }}
                                          filterOptions={(options, params) => {
                                              const filtered = filter(options, params);

                                              // Suggest the creation of a new value
                                              if (params.inputValue !== '') {
                                                  filtered.push({
                                                      inputValue: params.inputValue,
                                                      name: `Add "${params.inputValue}"`,
                                                  });
                                              }

                                              return filtered;
                                          }}
                                          selectOnFocus
                                          clearOnBlur
                                          handleHomeEndKeys
                                          id="free-solo-with-text-demo"
                                          options={topics}
                                          getOptionLabel={(option) => {
                                              // Value selected with enter, right from the input
                                              if (typeof option === 'string') {
                                                  return option;
                                              }
                                              // Add "xxx" option created dynamically
                                              if (option.inputValue) {
                                                  return option.inputValue;
                                              }
                                              // Regular option
                                              return option.name;
                                          }}
                                          renderOption={(option) => option.name}

                                          freeSolo
                                          renderInput={(params) => (
                                              <TextField {...params} label="topic" variant="outlined"/>
                                          )}
                            />

                        </Grid>
                        <Grid
                            className={classes.item}
                            item
                            md={4}
                            sm={4}
                            xs={12}
                        >
                            <FormControl className={classes.formControl} fullWidth>

                                <TextField
                                    autoFocus
                                    value={values.partitions}
                                    margin="dense"
                                    id="partitions"
                                    label="# of partitions"
                                    name={"partitions"}
                                    type="text"
                                    fullWidth
                                    onChange={handleChange}
                                />
                            </FormControl>


                        </Grid>
                        <Grid
                            className={classes.item}
                            item
                            md={4}
                            sm={4}
                            xs={12}
                        >
                            <FormControl className={classes.formControl} fullWidth>
                                <TextField
                                    autoFocus
                                    value={values.replications}
                                    margin="dense"
                                    id="replications"
                                    label="# of replications"
                                    name={"replications"}
                                    type="text"
                                    fullWidth
                                    onChange={handleChange}
                                />
                            </FormControl>


                        </Grid>
                    </Grid>

                </CardContent>
                <Divider/>

            </form>
        </Card>
    );
};

Topicdetails.propTypes = {
    className: PropTypes.string,
    product: PropTypes.object.isRequired
};

export default Topicdetails;
