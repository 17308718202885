import React from 'react';
import {makeStyles} from '@material-ui/styles';
import {Grid} from '@material-ui/core';

import {Notifications} from './components';
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import HomeIcon from "@material-ui/icons/Home";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4)
    }
}));

const TopicUpdate = props => {
    const {history, url} = props;
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid
                container
                spacing={4}
            >
                <Grid
                    item
                    md={12}
                    xs={12}
                >

                    <Breadcrumbs aria-label="breadcrumb">
                        <Link color="inherit" href="/topic" className={classes.link}>
                            <HomeIcon className={classes.icon}/>
                            Topics
                        </Link>


                    </Breadcrumbs>
                    <Notifications history={history} url={url}/>
                </Grid>

            </Grid>
        </div>
    );
};

export default TopicUpdate;
