import React from 'react';
import PropTypes from 'prop-types';
import {CardContent, Grid, TextField} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";


const DynamicField = ({idx, state, handleCatChange}) => {


    const catId = `variable-${idx}`;
    const ageId = `type-${idx}`;
    return (
        /**
         <div key={`variable-${idx}`}>
         <Grid
         container
         spacing={6}
         wrap="wrap"
         >

         <Grid

         item
         md={4}
         sm={6}
         xs={12}
         >

         <TextField
         fullWidth
         label={`Variable #${idx + 1}`}
         margin="dense"
         required
         variant="outlined"
         name={catId}
         data-idx={idx}
         id={catId}
         className="name"
         value={state[idx].name}
         onChange={handleCatChange}
         />
         </Grid>
         <Grid

         item
         md={4}
         sm={6}
         xs={12}
         >
         <FormControl fullWidth >
         <InputLabel htmlFor="age-native-simple">Type </InputLabel>
         <Select
         native
         value={state[idx].type}
         onChange={handleCatChange}
         data-idx={idx}
         inputProps={{
                        name: {ageId},
                        id: {ageId},

                    }}
         >
         <option value={"keyword"}>{"keyword"}</option>
         <option value={"date"}>{"Date"}</option>
         <option value={"integer"}>{"Integer"}</option>
         <option value={"text"}>{"Text"}</option>

         </Select>
         </FormControl>
         </Grid></Grid>
         </div>
         );**/


        <div key={`cat-${idx}`}>
            <label htmlFor={catId}>{`attribute N° #${idx + 1}`}</label>
            <input
                type="text"
                name={catId}
                data-idx={idx}
                id={catId}
                className="name" style={{
                width: "80%",
                "background-color": "#f6f7ff",
                "color": "black",
                "padding": "14px 20px",
                "margin": "8px 0",
                "border": "1px solid #ccc",
                "border-radius": "4px",

                "cursor": "pointer"
            }}
                value={state[idx].name}
                onChange={handleCatChange}
            />
            <label htmlFor={ageId}>Type of attribute</label>
            <select type="text"
                    name={ageId}
                    data-idx={idx}
                    id={ageId}
                    className="type"
                    style={{
                        width: "80%",
                        "background-color": "#f6f7ff",
                        "color": "black",
                        "padding": "14px 20px",
                        "margin": "8px 0",
                        "border": "1px solid #ccc",
                        "border-radius": "4px",
                        "cursor": "pointer"
                    }}
                    value={state[idx].type}
                    onChange={handleCatChange}>
                <option></option>
                <option value={"keyword"}>{"Keyword"}</option>
                <option value={"date"}>{"Date"}</option>
                <option value={"float"}>{"Float"}</option>
                <option value={"integer"}>{"Integer"}</option>
                <option value={"text"}>{"Text"}</option>

            </select>

        </div>)
};

DynamicField.propTypes = {
    idx: PropTypes.number,
    state: PropTypes.array,
    handleCatChange: PropTypes.func,
};

export default DynamicField;