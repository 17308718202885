import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/styles';
import {
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Grid,
    Divider,
    FormControlLabel,
    Checkbox,
    Typography,
    Button, TextField
} from '@material-ui/core';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import Collapse from "@material-ui/core/Collapse";
import axios from "axios";

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import {getToken} from "../../../../Utils/common";

const useStyles = makeStyles((theme) => ({
    root: {},
    item: {
        display: 'flex',
        flexDirection: 'column'
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    }
}));

const Notifications = props => {
    const {className, history, url, ...rest} = props;

    const classes = useStyles();
    const [values, setValues] = useState([])
    const fetchData = async () => {
        try {
            let res = await fetch(`${url}/allTopic/`,
                {
                    method: 'GET', mode: 'cors',
                    headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getToken()}
                });
            let myjson = await res.json();
            await setValues(myjson);
        } catch (err) {
            alert('fetch data error ' + err)
        }
    }

    useEffect(() => {
        fetchData()
    }, []);


    const handleChange = event => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
    };
    const handlesend = () => {

        new Promise(resolve => {
            axios.post(url + '/addTopic/', values, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + getToken()
                }
            }).then(response => {

                if (response) {
                    setOpen(true)


                }
            }).catch(error => {
                if (error.response.status === 401) console.log(" error ");
            });
        })

    }

    const handleCancel = () => {

        history.push('topic')
    }
    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };
    const [open, setOpen] = React.useState(false);
    const handleClick = () => {
        setOpen(true);
    };

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);

    };

    return (
        <Card
            {...rest}
            className={clsx(classes.root, className)}
        >
            <form>
                <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="success">
                        info added with success

                    </Alert>
                </Snackbar>
                <CardHeader
                    subheader=""
                    title="  Topic Details "
                />
                <Divider/>
                <CardContent>
                    <Grid
                        container
                        spacing={6}
                        wrap="wrap"
                    >
                        <Grid
                            className={classes.item}
                            item
                            md={4}
                            sm={6}
                            xs={12}
                        >

                            <TextField
                                fullWidth
                                label="Name"
                                margin="dense"
                                name="name"
                                required
                                variant="outlined"
                                value={values.name}
                                onChange={handleChange}
                            />

                        </Grid>
                        <Grid
                            className={classes.item}
                            item
                            md={4}
                            sm={6}
                            xs={12}
                        >
                            <FormControl className={classes.formControl}>
                                <InputLabel htmlFor="age-native-simple"># of partitions </InputLabel>
                                <Select
                                    native
                                    value={values.partitions}
                                    onChange={handleChange}
                                    inputProps={{
                                        name: 'partitions',
                                        id: 'age-native-simple',
                                    }}
                                >
                                    <option aria-label="None" value=""/>
                                    <option value={1}>1</option>
                                    <option value={2}>2</option>
                                    <option value={3}>3</option>
                                    <option value={3}>4</option>
                                </Select>
                            </FormControl>


                        </Grid>

                    </Grid>
                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                        <CardContent>
                            <Grid
                                className={classes.item}
                                item
                                md={4}
                                sm={6}
                                xs={12}
                            >

                            </Grid>
                        </CardContent>
                    </Collapse>
                </CardContent>
                <Divider/>
                <CardActions>

                    <Button variant="contained" size="medium" color="primary" className={classes.margin}
                            onClick={handlesend}>
                        Create with defaults
                    </Button>
                    <Button
                        color="primary"
                        variant="outlined" onClick={handleExpandClick}
                    >
                        Customize settings
                    </Button>
                    <Button size="medium" className={classes.margin} onClick={handleCancel}>
                        Cancel
                    </Button>
                </CardActions>
            </form>
        </Card>
    );
};

Notifications.propTypes = {
    className: PropTypes.string
};

export default Notifications;
