import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {useTheme} from '@material-ui/core/styles';
import {
    Apidetails,
    Connectordetails,
    Topicdetails,
    SchemaDetails,
    Preprocessingdetails,
    Flowdetails,
    Thingdetails
} from './components';
import {setValue} from "@o2xp/react-datatable/src/components/DatatableCore/InputTypes/PickersFunction";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import axios from "axios";
import uuid from 'uuid/v1';
import CircularProgress from "@material-ui/core/CircularProgress";
import {Grid} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import {getToken} from "../../Utils/common";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    dt: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
}));

function getSteps() {
    return ['Thing', 'Topic ', 'Preprocessing', 'Template', 'Connectors', 'Flow', 'Model'];
}

function getStepContent(stepIndex, values, setValues, url, history, setState1, state1, state, setState) {
    switch (stepIndex) {
        case 0:
            return <Thingdetails values={values} setValues={setValues} url={url}></Thingdetails>;
        case 1:
            return <Topicdetails values={values} setValues={setValues} url={url}></Topicdetails>;
        case 2:
            return <Preprocessingdetails values={values} setValues={setValues} url={url} state1={state1}
                                         setState1={setState1} state={state} setState={setState}></Preprocessingdetails>
        case 3 :
            return <SchemaDetails values={values} setValues={setValues} url={url}></SchemaDetails>;
        case 4:
            return <Connectordetails values={values} setValues={setValues} url={url}
                                     history={history}></Connectordetails>;
        case 5:
            return <Flowdetails url={url} history={history}></Flowdetails>;
        case 6 :
            return <Apidetails values={values} setValues={setValues} url={url}></Apidetails>
        default:
            return 'All processes have been completed';
    }
}


const Steps = props => {
    const {history, url} = props;
    const classes = useStyles();
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const [stateDialog, setStateDialog] = React.useState(false);
    const [messageError, setMessageError] = React.useState(false);
    const handleGoPlan = () => {
        history.push("plan")
    }
    const steps = getSteps();
    const handleNext = () => {
    };
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        axios.post(url + '/resumeTopic/', [values.name + "_cache"], {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getToken()
            }
        }).then(response => {
            if (response) {
                setOpen(true);
                setActiveStep((prevActiveStep) => prevActiveStep + 1);

            }
        }).catch(error => {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        });

    };
    const handleCancel = () => {

        console.log(values.listConnectors)
        axios.post(url + '/deleteTopic/' + values.name, {}, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getToken()
            }
        }).then(response => {


            axios.get(url + '/deleteSchema/' + values.schema, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + getToken()
                }
            }).then(response => {
                axios.post(url + '/deleteMany/', {listConnectors: values.listConnectors}, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + getToken()
                    }
                }).then(response => {

                    axios.get(url + '/delete/' + "/api/" + values.idApi, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + getToken()
                        }
                    }).then(response => {
                        console.log("hi " + response)
                        if (response) {

                            history.push("/")
                        }
                    }).catch(error => {

                        history.push("/")


                    }).catch(error => {

                        history.push("/")


                    });


                }).catch(error => {

                    history.push("/")


                });

            }).catch(error => {


                axios.post(url + '/deleteMany/', {listConnectors: values.listConnectors}, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + getToken()
                    }
                }).then(response => {

                    axios.get(url + '/delete/' + "/api/" + values.idApi, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + getToken()
                        }
                    }).then(response => {
                        console.log("hi " + response)
                        if (response) {

                            history.push("/")
                        }
                    }).catch(error => {

                        history.push("/")


                    }).catch(error => {

                        history.push("/")


                    });


                }).catch(error => {

                    history.push("/")


                });

            });
            ;
        });


    }

    const [values, setValues] = useState({
        name: '',
        partitions: 1,
        nameconnect: "",
        connectorClass: "",
        tasksMax: "1",
        type: "",

        valueConverter: "org.apache.kafka.connect.json.JsonConverter",

        valueConverterschemasenable: "false",

        schemaIgnore: "true",

        connectionUrl: "https://www.thingwings.com:9201",

        typeName: "_doc",

        topics: "",
        replications: 1,
        Apikey: "",

        keyIgnore: "true",
        serveruri: "tcp://thingwings.com:1883",
        topicsource: "",
        topicserver: "thingwings.com:9092",
        nameapi: '',
        modelID: '',
        username: '',
        password: '',
        modelName: '',
        host: "",
        schema: [],
        listTopics: [],
        variables: [],
        shards: 1,
        add: false,
        idApi: "",
        listConnectors: [],
        valueofjar: "",
        elasticUsername: "",
        elasticPassword: "",
        sslcert: "",
        sslca: "",
        sslkey: "",
        mqttUsername: "",
        mqttPassword: "",
        thingname: "",
        thingId: ""

    });
    const [open, setOpen] = React.useState(false);
    const [open1, setOpen1] = React.useState(false);
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleClickOpen = () => {
        setStateDialog(true);
    };

    const handleClickClose = () => {
        setStateDialog(false);
    };
    const [data, setData] = useState([])
    const [data2, setData2] = useState([])
    const handleClick = () => {
        setOpen(true);
    };

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
        setOpen1(false)

    };
    const [state1, setState1] = React.useState({
        columns: [
            {title: 'Name', field: 'name'},
            {title: 'type', field: 'type'},

        ],
        data: [],
    });
    const [state, setState] = React.useState({
        columns: [
            {title: 'Name', field: 'name'},
            {title: 'type', field: 'type'},

        ],
        data: [],
    });
    const [messageTopic, setMessageTopic] = React.useState("")
    const [progress, setProgress] = React.useState(false);
    const [things, setThings] = useState([])
    const fetchDataThing = async () => {
        try {
            let res = await fetch(`${url}/things`,
                {
                    method: 'GET', mode: 'cors',
                    headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getToken()}
                });
            let myjson = await res.json();
            if (myjson == undefined)
                history.push("signin")

            await setThings(myjson);

        } catch (err) {
            console.log("signin")
        }
    }

    useEffect(() => {
        fetchDataThing()
    }, []);

    const handleSend = (step) => {


        if (step === 0) {
            var data = {
                "name": values.thingname,
            };

            if (values.thingname == "") {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);

            } else {
                var arr = things.filter(ar => ar.name == values.thingname)
                if (arr.length == 0) {
                    axios.post(url + "/addThing", data, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + getToken()
                        }
                    }).then(response => {
                        if (response.data.message == undefined) {
                            // setActiveStep((prevActiveStep) => prevActiveStep + 1);

                            axios.get(url + "/things", {
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Authorization': 'Bearer ' + getToken()
                                }
                            }).then(response => {
                                if (response) {
                                    var myjson = response.data;


                                    var thing = myjson.filter(dt => dt.name == values.thingname)

                                    values.mqttPassword = thing[0].password;
                                    values.mqttUsername = thing[0].username;
                                    values.thingId = thing[0].device
                                    setValues(values);

                                }
                            });
                            setActiveStep((prevActiveStep) => prevActiveStep + 1);


                        } else {
                            setMessageError(response.data.message);
                            handleClickOpen();
                        }

                    }).catch(error => {
                        setActiveStep((prevActiveStep) => prevActiveStep + 1);

                    });
                } else {
                    values.mqttPassword = arr[0].password;
                    values.mqttUsername = arr[0].username;
                    values.thingId = arr[0].device;
                    setValues(values);
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);

                }
            }
        } else if (step === 1) {

            if (values.name != "") {

                let data = {

                    name: values.name,
                    partitions: values.partitions,
                    replications: values.replications
                }

                console.log(data.name)
                setProgress(true)
                axios.post(url + '/addTopic/', data, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + getToken()
                    }
                }).then(response => {


                    if (response.data.message != undefined) {


                        setMessageTopic(response.data.message)
                        setOpen1(true);
                        setProgress(false)

                    } else {


                        setProgress(false)
                        setOpen(true);
                        setActiveStep((prevActiveStep) => prevActiveStep + 1);
                    }
                }).catch(error => {
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                });
            }
        } else if (step == 2) {
            if (values.valueofjar != "") {
                axios.post(`${url}/CreateApi/`, {

                    name: values.valueofjar,
                    columns: state1.data,
                    columnsOut: state.data,
                    topic: values.name

                }, {
                    headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getToken()},

                }).then(res => { // then print response status
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);

                })
            } else
                setActiveStep((prevActiveStep) => prevActiveStep + 1);


        } else if (step == 3) {


            if (values.schema != "") {
                console.log(values.variables)
                axios.post(url + '/addSchema/', {
                    schema: values.schema,
                    topic: values.listTopics,
                    shards: values.shards,
                    variables: values.variables
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + getToken()
                    }
                }).then(response => {

                    if (response) {
                        setOpen(true);
                        setActiveStep((prevActiveStep) => prevActiveStep + 1);
                    }
                })
            } else {

                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
        } else if (step == 4) {

            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } else if (step == 5) {

            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } else if (step == 6) {

            if (values.nameapi == "") {

                handleReset();
                setActiveStep((prevActiveStep) => prevActiveStep + 1);

            } else {
                let data = {

                    name: values.nameapi,
                    modelID: values.modelID,
                    username: values.username,
                    password: values.password,
                    modelName: values.nameapi,
                    host: values.host,
                    topic: values.name,
                    Apikey: values.Apikey


                }

                axios.post(url + '/addApi/', data, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + getToken()
                    }
                }).then(response => {
                    console.log("hi " + response)
                    if (response) {

                        handleReset();

                        setOpen(true);


                        setActiveStep((prevActiveStep) => prevActiveStep + 1);
                    }
                }).catch(error => {
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                });

            }

        }
    }

    return (
        <div className={classes.root}>
            <Dialog
                fullScreen={fullScreen}
                open={stateDialog}
                onClose={handleClickClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    {"Upgrade your plan to create more topics?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>

                        <h2 style={{color: "red"}}>
                            {messageError}
                        </h2>


                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClickClose}>
                        Cancel
                    </Button>
                    <Button onClick={handleGoPlan} autoFocus>
                        Upgrade
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success">
                    info added with success

                </Alert>
            </Snackbar>
            <Snackbar open={open1} autoHideDuration={3000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="warning">
                    {messageTopic}
                </Alert>
            </Snackbar>

            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>

                    </Step>
                ))

                }

            </Stepper>

            <div>
                {activeStep === steps.length ? (
                    <div>
                        <Typography className={classes.instructions}></Typography>
                        {" process created with success "}
                    </div>
                ) : (
                    <div>
                        <Typography
                            className={classes.instructions}>{getStepContent(activeStep, values, setValues, url, history, setState1, state1, state, setState)}</Typography>
                        <div className={classes.dt}>

                            {(activeStep >= 7)
                                ?
                                null

                                :
                                <Button variant="contained" color="primary" onClick={() => handleSend(activeStep)}
                                        style={{float: "right"}} hidden={activeStep === 7}>
                                    {(activeStep === steps.length - 1) ? 'Start streaming' :
                                        'Next'
                                    }
                                </Button>
                            }
                            <Button
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                className={classes.backButton}
                                style={{float: "right"}}
                            >
                                Back
                            </Button>
                            <Button className={classes.backButton} variant="contained" color="primary"
                                    onClick={() => handleCancel(activeStep)} style={{float: "right"}}>
                                Cancel
                            </Button>
                            <Box display="flex" justifyContent="center">
                                {progress ?
                                    <CircularProgress justifyContent="center" color="secondary"/> :
                                    null
                                }
                            </Box>
                        </div>
                    </div>
                )}
            </div>

        </div>
    );
};

export default Steps;
