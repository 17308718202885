import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import MUIDataTable from "mui-datatables";
import CustomToolbar from "./CustomToolbar";
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import axios from "axios";
import { MuiThemeProvider, createMuiTheme, Grid, Card, CardContent, Avatar } from '@material-ui/core';
import CustomToolbarSelect from "./CustomToolbarSelect";
import ReactJson from "react-json-view";
import ScrollBound from "react-scroll-bound";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardHeader from "@material-ui/core/CardHeader";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { getToken } from "../../Utils/common";
import { Line } from "react-chartjs-2";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { emphasize, withStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4)
    }, paper: {
        maxWidth: 800,

        margin: `${theme.spacing(1)}px auto`,
        padding: theme.spacing(1),
    },
}));

const StyledBreadcrumb = withStyles((theme) => ({
    root: {
        backgroundColor: "#189b1c",
        height: theme.spacing(3),
        color: theme.palette.grey[800],
        fontWeight: theme.typography.fontWeightRegular,
        '&:hover, &:focus': {
            backgroundColor: theme.palette.grey[300],
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(theme.palette.grey[300], 0.12),
        },
    },
}))(Chip);

const StyledBreadcrumb1 = withStyles((theme) => ({
    root: {
        backgroundColor: "#9b0004",
        height: theme.spacing(3),
        color: theme.palette.grey[800],
        fontWeight: theme.typography.fontWeightRegular,
        '&:hover, &:focus': {
            backgroundColor: theme.palette.grey[300],
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(theme.palette.grey[300], 0.12),
        },
    },
}))(Chip);

const getMuiTheme = () => createMuiTheme({
    overrides: {
        MUIDataTable: {

            paper: {
                boxShadow: 'none',
                height: "700px"
            },
        }, MUIDataTableBodyCell: {
            paper: {
                boxShadow: 'none',
                height: "700px"
            },
        }

    },
})

const Topics = props => {
    const { history, url } = props;
    const classes = useStyles();
    const columns = [
        {
            label: "Name",
            name: "Name"
        },
        {
            label: "N° Partitions",
            name: "partitions"
        },
        {
            label: "Messages number",
            name: "messages"
        },
        {
            name: "Status",
            options: {
                filter: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div>
                            {(value == "connected") ?
                                <FormControlLabel control={<StyledBreadcrumb component="a" href="#" style={{ "color": "white" }} label={value} />}
                                /> : <FormControlLabel control={<StyledBreadcrumb1 component="a" href="#" style={{ "color": "white" }} label={value} />} />
                            }</div>
                    );
                }
            }
        }
    ];

    let history1 = history
    const [values, setValues] = useState([])
    const [status, setStatus] = useState("not connected")
    const [length, setlength] = useState(6)
    const [open, setOpen] = useState({ open: false, text: "" })
    const [open2, setOpen2] = useState(false)
    const handleClose2 = () => {setOpen2(false)}
    const handleClose = () => {setOpen({open: false,text: ""});}
    const [dataselect, setDataselect] = useState([])
    const [min, setMin] = useState(10)
    const [dt, setdata] = React.useState({});
    const handleClickOpen = (data) => {
        setOpen2(true);
        setdata(data)
    };

    const options = {
        filter: true,
        selectableRows: true,
        filterType: 'dropdown',
        responsive: 'stacked',
        rowsPerPage: 10, onCellClick: (rowData, rowState) => {
            var arr = [];
            arr.push(rowState.rowIndex);


        }, customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {

            console.log(selectedRows)

            var arr = [];

            for (let i = 0; i < selectedRows.data.length; i++) {
                arr.push(selectedRows.data[i].index)

            }

            return (<CustomToolbarSelect selectedRows={selectedRows} displayData={displayData} history={history} setSelectedRows={setSelectedRows} setValues={setValues} values={values} url={url} setDataselect={setDataselect} setlength={setlength} setOpen={setOpen} setOpenDelete={setOpenDelete} openDelete={openDelete} setVal={setVal} arr={arr} val={val} data1={data1} setdata1={setdata1} handleAllData={fetchDataall} disable={disable} />
            )
        },
        customToolbar: () => {
            return (
                <CustomToolbar history={history1} />
            );
        },

    };
    const [openDelete, setOpenDelete] = useState(false);
    const [data1, setdata1] = useState({});
    const [disable, setDisable] = useState(false);
    const [val, setVal] = useState([])
    const options1 = {
        maintainAspectRatio: false,
        scales: {

            yAxes: [{
                scaleLabel: {
                    display: true,
                    labelString: 'Number of Messages',
                },
                ticks: {
                    max: min,
                    min: 0
                }
            }],
            xAxes: [
                {
                    type: "realtime",
                    realtime: {
                        onRefresh: function () {

                            axios.get(`${url}/nbrmessage/`, { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getToken() } }).then(res => {

                                if (res) {
                                    var i = 0;
                                    var z = 0;
                                    data1.datasets.forEach(function (dataset) {
                                        // {if (i==val[j])

                                        var k = 0;/**set k max value for showing **/
                                        console.log("vali" + val)
                                        for (let j = 0; j < res.data[val[i]].value.length; j++) {
                                            z++;
                                            console.log(res.data[val[i]].value[j])

                                            if (k < res.data[val[i]].value[j]) k = res.data[val[i]].value[j] + k
                                        }
                                        dataset.data.push({
                                            x: Date.now(),
                                            y: parseInt(k)
                                        });

                                        // }

                                        i++;
                                        setMin(k + 3)
                                    });

                                }
                            })
                        }

                        ,
                        delay: 1000, duration: 80000, refresh: 10000
                    }
                }
            ]
        }
        , tooltips: {
            mode: 'nearest',
            intersect: false
        },
        hover: {
            mode: 'nearest',
            intersect: false
        },
        legend: {
            position: 'right'
        }
    };

    function getRandomColor() {
        var letters = '0123456789ABCDEF';
        var color = '#';
        for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    const fetchDataall = async () => {
        try {

            setprogress(true)
            let res = await fetch(`${url}/nbrmessage/`,
                {
                    method: 'GET', mode: 'cors',
                    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getToken() }
                });
            let myjson = await res.json();

            var arr = []
            var vals = []
            for (let i = 0; i < myjson.length; i++) {
                try {
                    let res = await fetch(`${url}/statusConnect/` + myjson[i].name + "_cache",{ method: 'GET', mode: 'cors',headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getToken() }});
                    let myjson1 = await res.json();
                    console.log(myjson1)
                    var dk = 0;
                    for (let s = 0; s < myjson[i].value.length; s++)
                        dk = myjson[i].value[s] + dk
                    if (myjson1.source > 0 && myjson1.sink > 0) {
                        vals.push([myjson[i].name, myjson[i].partitions, dk, "connected"])
                        setStatus("connected")
                        setValues(vals)

                    }
                    else {
                        setStatus("not connected")
                        vals.push([myjson[i].name, myjson[i].partitions, dk, "not connected"])
                        setValues(vals)

                    }

                } catch (err) { console.log('fetch data error ' + err) }

                for (let j = 0; j < val.length; j++) {
                    if (val[j] == myjson[i].name)
                        arr.push({
                            label: myjson[i].name,
                            borderColor: getRandomColor(),
                            backgroundColor: `rgba(255, 126, 139, 0.5)`,
                            fill: false,
                            cubicInterpolationMode: 'monotone',
                            data: [{ x: Date.now(), y: 0 }]
                        })
                }
            }

            setprogress(false)
            setdata1({ ...data1, "datasets": arr })

        } catch (err) { alert('fetch data error ' + err) }
    }

    useEffect(() => { fetchDataall() }, []);
    const [progress, setprogress] = useState(true)

    return (
        <div className={classes.root}>
            <Grid container spacing={4} >
                <Grid item md={6} xs={12} >
                    <Snackbar open={open.open} autoHideDuration={6000} onClose={handleClose}>
                        <Alert onClose={handleClose} severity="success">
                            {open.text}
                        </Alert>
                    </Snackbar>

                    <MuiThemeProvider theme={getMuiTheme()} >
                        {progress ?
                            <LinearProgress /> :
                            null}
                        <MUIDataTable title={"Topics list"} data={values} columns={columns} options={options} style={{ height: "600px" }} /></MuiThemeProvider>
                </Grid>
                <Grid item
                    md={6}
                    xs={6}>
                    <div style={{ "font-size": "1.25rem", "font-weight": "500", "line-height": "1.6", "letter-spacing": "0.0075em" }}>&nbsp;&nbsp;&nbsp;&nbsp;  Number of Messages</div>
                    <div style={{ height: "250px" }}>
                        <Line data={data1} options={options1} />
                    </div>

                    <ScrollBound className="my-list" style={{ maxHeight: "300px", overflowY: "auto" }}>
                        <div style={{ "font-size": "1.25rem", "font-weight": "500", "line-height": "1.6", "letter-spacing": "0.0075em" }}>{"Messages"}</div>
                        <Divider />
                        {((dataselect.length === 0) && (length === 6)) ?
                            <img alt="no data" className={classes.image} src="/images/no_data.png" /> :
                            dataselect.map((dat) => (
                                <Card>
                                    <CardActionArea>
                                        <CardHeader title={dat.topic + "_" + dat.id} />
                                        <CardContent onClick={() => { handleClickOpen(dat.message) }}>
                                            <Grid container justify="space-between">
                                                <Grid item>
                                                    <Typography variant="body2" component="p">
                                                        {new Date(parseInt(dat.creationDate)).toString()}
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    <Avatar className={classes.avatar}>
                                                        <AccountTreeIcon className={classes.icon} />
                                                    </Avatar>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            ))}
                    </ScrollBound>
                    <Dialog onClose={handleClose2} aria-labelledby="customized-dialog-title" open={open2}>
                        <DialogTitle id="customized-dialog-title" onClose={handleClose2}>
                            Description
                        </DialogTitle>
                        <DialogContent dividers>
                            <ReactJson src={dt} />
                        </DialogContent>
                    </Dialog>
                </Grid>
            </Grid>
        </div>
    );
};

export default Topics;
