import React, {Fragment, useState} from 'react';
import Message from './Message';
import Progress from './Progress';
import axios from 'axios';
import {Button, CardContent} from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import {makeStyles} from "@material-ui/styles";
import {getToken} from "../../../../../Utils/common";
import AttachFileIcon from '@material-ui/icons/AttachFile';

const useStyles = makeStyles((theme) => ({
    root: {},
    item: {
        display: 'flex',
        flexDirection: 'column'
    }
    , button: {
        margin: theme.spacing(1),
    }
}));
const FileUpload = (props) => {
    const classes = useStyles();
    const {className, url, history, values, setValues, ...rest} = props;

    const [file, setFile] = useState('');
    const [file1, setFile1] = useState('');
    const [file2, setFile2] = useState('');
    const [filename, setFilename] = useState('Choose File');
    const [filename1, setFilename1] = useState('Choose File');
    const [filename2, setFilename2] = useState('Choose File');
    const [uploadedFile, setUploadedFile] = useState({});
    const [uploadedFile1, setUploadedFile1] = useState({});
    const [uploadedFile2, setUploadedFile2] = useState({});
    const [message, setMessage] = useState({
        text: "",
        type: ""
    });
    const [message1, setMessage1] = useState('');
    const [message2, setMessage2] = useState('');

    const [uploadPercentage, setUploadPercentage] = useState(0);
    const [uploadPercentage1, setUploadPercentage1] = useState(0);
    const [uploadPercentage2, setUploadPercentage2] = useState(0);

    const onChange = e => {
        setFile(e.target.files[0]);
        setFilename(e.target.files[0].name);
        setValues({
            ...values,
            ["sslcert"]: e.target.files[0].name,
        });
    };
    const onChange1 = e => {
        setFile1(e.target.files[0]);
        setFilename1(e.target.files[0].name);
        setValues({
            ...values,
            ["sslkey"]: e.target.files[0].name,
        });
    };
    const onChange2 = e => {
        setFile2(e.target.files[0]);
        setFilename2(e.target.files[0].name);
        setValues({
            ...values,
            ["sslca"]: e.target.files[0].name,
        });
    };
    const onSubmit = async e => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('sslcert', file);
        formData.append('sslkey', file1)
        formData.append('sslca', file2)


        if ((file1 == "") || (file == "") || (file2 == ""))
            setMessage({
                text: "files is empty ",
                type: "error"
            });
        else {
            console.log(formData)
            axios.post(url + '/uploadSSL/', formData, {
                headers: {'Content-Type': 'multipart/form-data', 'Authorization': 'Bearer ' + getToken()},
                onUploadProgress: progressEvent => {
                    setUploadPercentage(parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total)));
                    setUploadPercentage1(parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total)));
                    setUploadPercentage2(parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total)));

                    setTimeout(() => setUploadPercentage(0), 10000);
                    setTimeout(() => setUploadPercentage1(0), 10000);
                    setTimeout(() => setUploadPercentage2(0), 10000);

                }
            }).then(response => {
                const {fileName, fileName1, fileName2} = response.data;
                setUploadedFile({fileName});
                setUploadedFile1({fileName1})
                setUploadedFile2({fileName2})
                setMessage({
                    text: "files uploaded ",
                    type: "success"
                });

            }).catch(error => {
                if (error.response.status === 401) console.log(" error ");
            });
        }
    }

    return (
        <Fragment>
            <form onSubmit={onSubmit}>
                <div className="custom-file mb-4">
                    {message ? <Message msg={message}/> : null}

                    <Button
                        variant="contained"
                        component="label"
                        startIcon={<AttachFileIcon/>}
                        className={classes.button}

                    >
                        Certificate
                        <input
                            type="file"
                            className="custom-file-input"
                            id="customFile"
                            onChange={onChange}
                            hidden
                        />
                    </Button>
                    <label className='custom-file-label' htmlFor='customFile'>
                        {filename}
                    </label>
                </div>
                <Progress percentage={uploadPercentage}/>
                {message1 ? <Message msg={message1}/> : null}

                <div className="custom-file mb-4">
                    <Button
                        variant="contained"
                        component="label"
                        startIcon={<AttachFileIcon/>}
                        className={classes.button}

                    >
                        Private key
                        <input
                            type="file"
                            className="custom-file-input2"
                            id="customFile1"
                            onChange={onChange1}
                            hidden
                        />
                    </Button>
                    <label className='custom-file-label' htmlFor='customFile'>
                        {filename1}
                    </label>
                </div>
                <Progress percentage={uploadPercentage1}/>
                {message2 ? <Message msg={message2}/> : null}
                <div className="custom-file mb-4">
                    <Button
                        variant="contained"
                        component="label"
                        startIcon={<AttachFileIcon/>}
                        className={classes.button}

                    >
                        CA Certificate
                        <input
                            type="file"
                            className="custom-file-input2"
                            id="customFile2"
                            onChange={onChange2}
                            hidden
                        /> </Button>
                    <label className='custom-file-label' htmlFor='customFile'>
                        {filename2}
                    </label>

                </div>

                <Progress percentage={uploadPercentage2}/>
                <Button
                    variant="contained"
                    component="label"
                    startIcon={<CloudUploadIcon/>}
                    className={classes.button}

                >
                    Upload
                    <input
                        type="submit"
                        value="Upload"
                        className="btn btn-primary btn-block mt-4"
                        hidden
                    />
                </Button>
            </form>

        </Fragment>
    );
};

export default FileUpload;
