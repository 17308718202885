import React, {useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/styles';
import {
    Card,
    CardContent,
    CardActions,
    Typography,
    Grid,
    Divider, TableCell
} from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import GetAppIcon from '@material-ui/icons/GetApp';
import {StatusBullet} from "../../../../Components";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

import DeleteIcon from '@material-ui/icons/Delete';

import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import IconButton from "@material-ui/core/IconButton";
import CardActionArea from "@material-ui/core/CardActionArea";

const useStyles = makeStyles(theme => ({
    root: {},
    imageContainer: {
        height: 64,
        width: 64,
        margin: '0 auto',
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: '5px',
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    image: {
        width: '100%'
    },
    statsItem: {
        display: 'flex',
        alignItems: 'center'
    },
    statsIcon: {
        color: theme.palette.icon,
        marginRight: theme.spacing(1)
    }
}));

const ProductCard = props => {
    const {className, product, history, url, setValues, ...rest} = props;

    const classes = useStyles();
    const statusColors = {
        delivered: 'success',
        pending: 'info',
        refunded: 'danger'
    }
    const [openDelete, setOpenDelete] = useState(false);
    const handleOpenDelete = (id_model) => {
        setOpenDelete(true);
    }
    const handleCloseDelete = () => {
        setOpenDelete(false);
    }
    const handleDeleteContinue = async (id) => {
        try {
            await fetch(`${url}/delete/sourcedata/doc/` + product._id, {
                method: 'GET',
                mode: 'cors',
                headers: {'Content-Type': 'application/json'}
            })
            handleCloseDelete();
            fetchData()

        } catch (error) {
            console.error('Error rr:', error)
        }
    }
    const handleUpdate = () => {

        // history.push("/sourcesdetails")


    }
    const fetchData = async () => {
        try {
            let res = await fetch(`${url}/allSource/`,
                {
                    method: 'GET', mode: 'cors',
                    headers: {'Content-Type': 'application/json'}
                });
            let myjson = await res.json();
            await setValues(myjson);
        } catch (err) {
            alert('fetch data error ' + err)
        }
    }
    return (
        <Card
            {...rest}
            className={clsx(classes.root, className)}
        >
            <CardActionArea>
                <CardHeader
                    avatar={
                        <Avatar aria-label="recipe" className={classes.avatar}>
                            S
                        </Avatar>
                    }
                    action={
                        <IconButton aria-label="settings" onClick={handleOpenDelete}>
                            <DeleteIcon/>
                        </IconButton>
                    }
                    title={product._source.topic}
                    subheader={product.modelName}
                />
                <CardContent onClick={handleUpdate}>
                    <div className={classes.imageContainer}>
                        <img
                            alt="Product"
                            className={classes.image}
                            src={"/images/products/product_4.png"}
                        />
                    </div>
                    <Typography
                        align="center"
                        gutterBottom
                        variant="h4"
                    >
                        {product._source.name}
                    </Typography>
                    <Typography
                        align="center"
                        variant="body1"
                    >

                    </Typography>
                </CardContent>
            </CardActionArea>
            <Divider/>
            <CardActions>
                <Grid
                    container
                    justify="space-between"
                >
                    <Grid
                        className={classes.statsItem}
                        item
                    >
                        <AccessTimeIcon className={classes.statsIcon}/>
                        <Typography
                            display="inline"
                            variant="body2"
                        >
                            {product._source.host}
                        </Typography>
                    </Grid>
                    <Grid
                        className={classes.statsItem}
                        item
                    >
                        <div className={classes.statusContainer}>
                            <StatusBullet
                                className={classes.status}
                                color={'success'}
                                size="sm"
                            />

                        </div>
                        <Typography
                            display="inline"
                            variant="body2"
                        >

                        </Typography>

                        <Dialog open={openDelete} onClose={handleCloseDelete}>
                            <DialogTitle disableTypography={false}>Confirmation</DialogTitle>
                            <DialogContent dividers>
                                Are you sure you want to delete this model ?
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => {
                                    handleDeleteContinue("1")
                                }} color='primary' size='small'> yes </Button>
                                <Button onClick={handleCloseDelete} color='primary' size='small'> No </Button>
                            </DialogActions>

                        </Dialog>
                    </Grid>
                </Grid>
            </CardActions>
        </Card>
    );
};

ProductCard.propTypes = {
    className: PropTypes.string,
    product: PropTypes.object.isRequired
};

export default ProductCard;
