import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/styles';
import AddIcon from '@material-ui/icons/Add';
import {
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Grid,
    Divider,
    FormControlLabel,
    Checkbox,
    Typography,
    Button, TextField
} from '@material-ui/core';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import axios from "axios"
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import DynamicField from './DynamicField';
import IconButton from "@material-ui/core/IconButton";
import {getToken} from "../../../../Utils/common";

const useStyles = makeStyles(() => ({
    root: {},
    item: {
        display: 'flex',
        flexDirection: 'column'
    }
}));

const Notifications = props => {
    const {className, path, history, ...rest} = props;

    const classes = useStyles();
    const [values, setValues] = useState({});

    const handleChange = event => {
        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
    };
    const handlesend = () => {


        console.log(state)
        if (ownerState.schema == "") {
            seterrortopic({
                error: true,
                text: "the name of the template field must be filled "
            })
        }
        if (ownerState.topic == "") {
            seterrortopic({
                error: true,
                text: "the name of the topic field must be filled "
            })

        }
        if ((ownerState.topic != "") && (ownerState.schema != "")) {
            axios.post(path + '/addSchema/', {
                schema: ownerState.schema[0],
                topic: ownerState.topic,
                variables: state,
                shards: ownerState.shards[0],
                add: ownerState.add,
                elasticUsername: ownerState.elasticUsername[0],
                elasticPassword: ownerState.elasticPassword[0],
                connectionUrl: ownerState.connectionUrl[0]

            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + getToken()
                }
            }).then(response => {
                console.log("hi " + response)
                if (response) {
                    setOpen(true)
                }
            }).catch(error => {
                if (error.response.status === 401) console.log(" error ");
            });
        }


    }


    const fetchData = async () => {
        try {
            let res = await fetch(`${path}/allTopicConnector`,
                {
                    method: 'GET', mode: 'cors',
                    headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getToken()}
                });
            let myjson = await res.json();
            console.log(myjson)
            await setTopics(myjson);


        } catch (err) {
            alert('fetch data error ' + err)
        }
    }

    useEffect(() => {
        fetchData()
    }, []);
    const [open, setOpen] = React.useState(false);
    const handleClick = () => {
        setOpen(true);
    };

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
        seterrortopic({
            error: false,
            text: ""
        })

    };
    const [topics, setTopics] = useState([])

    const [ownerState, setOwnerState] = useState({
        owner: '',
        description: '',
        schema: '',
        topic: "",
        shards: 1,
        add: true,
        elasticUsername: "",
        elasticPassword: "",
        connectionUrl: ""
    });

    const handleOwnerChange = (e) => setOwnerState({
        ...ownerState,
        [e.target.name]: [e.target.value],
    });

    const field = {name: '', type: ''};
    const [state, setState] = useState([
        {...field},
    ]);

    const addField = () => {
        setState([...state, {...field}]);
    };
    const [errortopic, seterrortopic] = useState({
        error: false,
        text: ""

    })
    const handleCatChange = (e) => {
        const updatedCats = [...state];
        updatedCats[e.target.dataset.idx][e.target.className] = e.target.value;
        setState(updatedCats);
        console.log(state)
    };


    return (
        <Card
            {...rest}
            className={clsx(classes.root, className)}
        >


            <Snackbar open={errortopic.error} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error">
                    {errortopic.text}

                </Alert>
            </Snackbar>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success">
                    template added with success

                </Alert>
            </Snackbar>
            <CardHeader
                subheader=""
                title=" Add template"
            />
            <form>

                <Divider/>
                <CardContent>
                    <label htmlFor={"schema"}>{"Template name "}</label>
                    <input
                        type="text"
                        name={"schema"}
                        required
                        style={{
                            width: "100%",
                            "background-color": "#f6f7ff",
                            "color": "black",
                            "padding": "14px 20px",
                            "margin": "8px 0",
                            "border": "1px solid #ccc",
                            "border-radius": "4px",

                            "cursor": "pointer"
                        }}
                        value={ownerState.schema}
                        onChange={handleOwnerChange}
                    />
                    <p></p>
                    <label htmlFor={"topic"}>Topic</label>
                    <select type="number"
                            name={"topic"}

                            style={{
                                width: "100%",
                                "background-color": "#f6f7ff",
                                "color": "black",
                                "padding": "14px 20px",
                                "margin": "8px 0",
                                "border": "1px solid #ccc",
                                "border-radius": "4px",
                                "cursor": "pointer"
                            }}
                            required
                            helperText={errortopic.text}
                            onChange={handleOwnerChange}>
                        <option aria-label="None" value=""/>
                        {topics.map(topic => (
                            <option value={topic.name}>{topic.name}</option>
                        ))

                        }
                    </select>
                    <p></p>
                    <label htmlFor={"schema"}>{"number of shards "}</label>
                    <input
                        type="text"
                        name={"shards"}

                        style={{
                            width: "100%",
                            "background-color": "#f6f7ff",
                            "color": "black",
                            "padding": "14px 20px",
                            "margin": "8px 0",
                            "border": "1px solid #ccc",
                            "border-radius": "4px",

                            "cursor": "pointer"
                        }}
                        value={ownerState.shards}
                        onChange={handleOwnerChange}
                    />
                    <p></p>
                    <label htmlFor={"schema"}>{"Connection "}</label>
                    <input
                        type="text"
                        name={"connectionUrl"}
                        style={{
                            width: "100%",
                            "background-color": "#f6f7ff",
                            "color": "black",
                            "padding": "14px 20px",
                            "margin": "8px 0",
                            "border": "1px solid #ccc",
                            "border-radius": "4px",
                            "cursor": "pointer"
                        }}
                        value={ownerState.connectionUrl}
                        onChange={handleOwnerChange}
                    />
                    <p></p>
                    <label htmlFor={"schema"}>{" username "}</label>
                    <input
                        type="text"
                        name={"elasticUsername"}
                        style={{
                            width: "100%",
                            "background-color": "#f6f7ff",
                            "color": "black",
                            "padding": "14px 20px",
                            "margin": "8px 0",
                            "border": "1px solid #ccc",
                            "cursor": "pointer"
                        }}
                        value={ownerState.elasticUsername}
                        onChange={handleOwnerChange}

                    />
                    <p></p>
                    <label htmlFor={"schema"}>{" password "}</label>
                    <input
                        type="text"
                        name={"elasticPassword"}
                        style={{
                            width: "100%",
                            "background-color": "#f6f7ff",
                            "color": "black",
                            "padding": "14px 20px",
                            "margin": "8px 0",
                            "border": "1px solid #ccc",
                            "cursor": "pointer"
                        }}
                        value={ownerState.elasticPassword}
                        onChange={handleOwnerChange}

                    />
                    <Divider></Divider>

                    {
                        state.map((val, idx) => (
                            <DynamicField
                                key={`variable-${idx}`}
                                idx={idx}
                                state={state}
                                handleCatChange={handleCatChange}
                            />
                        ))
                    }
                    <IconButton aria-label="delete" color="primary">
                        <AddIcon onClick={addField}/>
                    </IconButton>
                </CardContent>

                <CardActions>
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={handlesend}
                    >
                        Save
                    </Button>
                </CardActions>
            </form>

        </Card>
    );
};

Notifications.propTypes = {
    className: PropTypes.string
};

export default Notifications;
