import React, { useEffect, useState } from 'react';
import { makeStyles, withStyles, emphasize } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from "@material-ui/core/Grid";
import { SearchInput, StatusBullet } from "../../Components";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import Divider from "@material-ui/core/Divider";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";


import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import clsx from "clsx";
import axios from "axios";
import { getToken } from "../../Utils/common";
import { TextField } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import Drawer from '@material-ui/core/Drawer';
import Chip from '@material-ui/core/Chip';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";


const AntTabs = withStyles({
    root: {
        borderBottom: '1px solid #e8e8e8',
    },
    indicator: {
        backgroundColor: '#1890ff',
    },
})(Tabs);

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})
    ((props) => (
        <Menu
            elevation={0}
            getContentAnchorEl={null}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            {...props}
        />
    ));

const StyledMenuItem = withStyles((theme) => ({
    root: {
        '&:focus': {
            backgroundColor: theme.palette.primary.main,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem);

const AntTab = withStyles((theme) => ({
    root: {
        textTransform: 'none',
        minWidth: 72,
        fontWeight: theme.typography.fontWeightRegular,
        marginRight: theme.spacing(4),
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:hover': {
            color: '#40a9ff',
            opacity: 1,
        },
        '&$selected': {
            color: '#1890ff',
            fontWeight: theme.typography.fontWeightMedium,
        },
        '&:focus': {
            color: '#40a9ff',
        },
    },
    selected: {},
}))((props) => <Tab disableRipple {...props} />);



const StyledBreadcrumb = withStyles((theme) => ({
    root: {
        backgroundColor: "#189b1c",
        height: theme.spacing(3),
        color: theme.palette.grey[800],
        fontWeight: theme.typography.fontWeightRegular,
        '&:hover, &:focus': {
            backgroundColor: theme.palette.grey[300],
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(theme.palette.grey[300], 0.12),
        },
    },
}))(Chip);


const drawerWidth = 500;

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    padding: {
        padding: theme.spacing(1),
    },
    username: {
        padding: theme.spacing(1),
    },
    demo1: {
        backgroundColor: theme.palette.background.paper,
    },
    demo2: {
        backgroundColor: theme.palette.background.paper,
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: "40%",
        top: "65px"
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },

}));

const Acls = ({ history, url }) => {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const [selectData, setSelectedData] = React.useState(
        {
            "created": "2021-2-15",
            "updated": "2021-2-15"
            , "name": "",
            "status": "",
            "device": "",
            "client": "",
            "username": "",
            "password": ""
        }
    )

    const [valuesAcl, setvaluesAcl] = React.useState({
        name: "",
        pubsub: 3
    });

    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });
    const toggleDrawer = (anchor, open) => () => {
        setState({ ...state, [anchor]: open });
    };

    const list = () => (
        <div
            role="presentation"
        >
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Button aria-label="close" className={classes.closeButton} onClick={toggleDrawer("right", false)}>
                        <CloseIcon />
                    </Button>
                </Grid>
                <Grid item xs={1}>
                </Grid>
                <Grid item xs={10}>
                    <TextField
                        autoFocus
                        value={thingdetail.nameth}
                        margin="dense"
                        label=" Thing Name"
                        name={"nameth"}
                        type="text"
                        fullWidth
                        onChange={handleChangethingupdate}
                    />
                    <Button
                        aria-controls="customized-menu"
                        aria-haspopup="true"
                        variant="contained"
                        color="primary"
                        onClick={handleUpdate}
                        style={{ float: "right" }}
                        className={classes.btn}

                    >
                        Update
                    </Button>

                    {progress ?
                        <CircularProgress color="secondary" /> :
                        null}
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={12}>
                    <Divider></Divider>

                </Grid>
                <Grid item xs={12}>

                    <AntTabs value={value} onChange={handleChange} aria-label="ant example">
                        <AntTab label="Details" />
                        <AntTab label="Credentials" />
                        <AntTab label="Permissions" />
                    </AntTabs>
                    <TabPanel value={value} index={0}>
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    Type
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="body2" color="textSecondary" variant="h6"
                                    component="h4">{"MQTT broker"}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider></Divider>

                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="body2" color="textSecondary" component="p">Created

                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="body2" color="textSecondary" variant="h6"
                                    component="h4">{selectData.created}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider></Divider>

                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="body2" color="textSecondary" component="p">{"Updated"}
                                </Typography>
                            </Grid>

                            <Grid item xs={6}>
                                <Typography variant="body2" color="textSecondary" component="h4">
                                    {selectData.updated}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider></Divider>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="body2" color="textSecondary" component="p">{"Status"}
                                </Typography>
                            </Grid>

                            <Grid item xs={6}>
                                <StyledBreadcrumb component="a" href="#" label={selectData.status}
                                    style={{ "color": "white" }} />

                            </Grid>
                            <Grid item xs={12}>
                                <Divider></Divider>

                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    Device Id
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="body2" color="textSecondary" variant="h6"
                                    component="h4">{selectData.device}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider></Divider>

                            </Grid>

                        </Grid>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <Typography variant="body2" color="textSecondary" component="p">{"Host"}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="body2" color="textSecondary" variant="h6"
                                    component="h4">{"thingwings.com"}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider></Divider>

                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    Username
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="body2" color="textSecondary" variant="h6"
                                    component="h4">{selectData.username}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider></Divider>

                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    Password
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="body2" color="textSecondary" variant="h6"
                                    component="h4">{selectData.password}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider></Divider>

                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    Client Id
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="body2" color="textSecondary" variant="h6"
                                    component="h4">{selectData.client}
                                </Typography>
                            </Grid>

                        </Grid>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>

                                <Button
                                    aria-controls="customized-menu"
                                    aria-haspopup="true"
                                    variant="contained"
                                    color="primary"
                                    onClick={handleOpen}
                                    className={classes.btn}

                                >
                                    Assign permission
                                </Button>
                            </Grid>
                            <Grid container spacing={3}>
                                <Grid item xs={4}> <Typography
                                    variant="h6"
                                    component="h3"
                                >
                                    {"Topic"}
                                </Typography></Grid>
                                <Grid item xs={2}> <Typography
                                    variant="h6"
                                    component="h3"
                                >
                                    {"Apply to"}
                                </Typography></Grid>

                            </Grid>

                            <Grid item xs={12}>
                                <Divider></Divider>
                            </Grid>
                            <Grid item xs={12}>

                                <List className={classes.root}>

                                    {Acls.map((value) => {
                                        const labelId = `checkbox-list-label-${value.aclID.toString()}`;

                                        return (

                                            <Grid container spacing={3}>
                                                <ListItem key={value.aclID.toString()} role={undefined} dense button>

                                                    <Grid item xs={4}>
                                                        <ListItemText id={labelId} primary={<React.Fragment>
                                                            <Typography
                                                                variant="h6"
                                                                component="h3"
                                                            >
                                                                {value.topic}
                                                            </Typography>

                                                        </React.Fragment>} />
                                                    </Grid>
                                                    <Grid item xs={4}>

                                                        {(value.pubsub == 3) ?
                                                            <ListItemText id={labelId}
                                                                primary={"Publishes & Subscriptions"} />
                                                            : null}

                                                        {(value.pubsub == 1) ?
                                                            <ListItemText id={labelId}
                                                                primary={"Subscriptions"} /> : null
                                                        }
                                                        {(value.pubsub == 2) ?
                                                            <ListItemText id={labelId} primary={"Publishes"} />
                                                            : null
                                                        }<ListItemSecondaryAction>

                                                        </ListItemSecondaryAction>
                                                    </Grid>

                                                </ListItem>
                                            </Grid>

                                        )


                                    })}
                                </List>

                            </Grid>
                        </Grid>
                    </TabPanel>
                </Grid>
            </Grid>
        </div>
    );

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`nav-tabpanel-${index}`}
                aria-labelledby={`nav-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box p={3}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    const [checked, setChecked] = React.useState([0]);
    const [anchorEl2, setAnchorEl2] = React.useState(null);
    const [opendeploy, setOpendeploy] = React.useState(false);
    const [openRestart, setopenRestart] = React.useState(false);
    const open = Boolean(anchorEl2);
    const handleChangething = event => {
        setThing({
            ...thing,
            [event.target.name]: event.target.value
        });
    };
    const [thing, setThing] = React.useState({
        thingname: "",
        search: ""
    })
    const handleChangethingupdate = event => {
        setthingdetail({
            ...thingdetail,
            [event.target.name]: event.target.value
        });
    };
    const handleChangeAcl = event => {
        setvaluesAcl({
            ...valuesAcl,
            [event.target.name]: event.target.value
        });
    };

    const [thingdetail, setthingdetail] = React.useState({
        nameth: ""

    })
    const [progress1, setProgress1] = React.useState(10);


    const [selected, setSelected] = React.useState("");


    const [openNew, setOpenNew] = React.useState(false);
    const handleClickOpen = () => {
        setOpenNew(true);
    };

    const handleCloseNew = () => {
        setOpenNew(false);
    };
    const handleClosedeploy = () => {
        setOpendeploy(false);
        setLoading(false)
        setSuccess(false);

    };
    const handleOpen = () => {
        setOpendeploy(true);


    }
    const handleCloseRestart = () => {
        setopenRestart(false);
        setLoading1(false)
        setSuccess1(false);

    };

    const handleClick2 = (event) => {
        setAnchorEl2(event.currentTarget);
    };

    const handleClose2 = () => {
        setAnchorEl2(null);
    };

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorEl1, setAnchorEl1] = React.useState(null);
    const [idthing, setidThing] = React.useState("");
    const [idAcl, setidAcl] = React.useState("");

    const handleToggle = (data) => () => {

        const currentIndex = checked.indexOf(data.idthing);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(data.idthing);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setSelected(data.idthing)
        setChecked(newChecked);
        setidThing(data.idthing)
        setSelectedData(data)
        fetchDataAcl(data.idthing)
        setthingdetail({
            nameth: data.name
        })
    };
    const handleidthing = (id) => () => {

        setidThing(id)
        setSelected(id)
        fetchDataAcl(id)


    }
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleClose1 = () => {
        setAnchorEl1(null);
    };

    const [openLoad, setOpenload] = React.useState(false);

    const handleClickOpenLoad = () => {
        setOpenload(true);
    };

    const [loading, setLoading] = React.useState(false);
    const [loading1, setLoading1] = React.useState(false);
    const [success1, setSuccess1] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const timer = React.useRef();
    const [copied, setCopied] = useState(false);
    const timer1 = React.useRef();

    const buttonClassname = clsx({
        [classes.buttonSuccess]: success,
    });

    React.useEffect(() => {
        return () => {
            clearTimeout(timer.current);
        };
    }, []);
    React.useEffect(() => {
        return () => {
            clearTimeout(timer1.current);
        };
    }, []);


    const handleAcl = () => {
        console.log(valuesAcl.topic + valuesAcl.pubsub)
        axios.post(url + "/AddThingPermission", {
            username: selectData.username,
            device: selectData.device,
            topic: valuesAcl.name,
            pubsub: valuesAcl.pubsub
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getToken()
            }
        }
        ).then(response => {

            if (response) {
                //history.push("nodered")
                handleClosedeploy()
                fetchDataAcl(idthing)


            }
        }).catch(error => {
            if (error.response.status === 401) console.log(" error ");
        })


    }

    const [openDelete, setOpenDelete] = useState(false);
    const [openDelete1, setOpenDelete1] = useState(false);

    const handleOpenDelete = () => {
        setOpenDelete(true);
    }
    const handleCloseDelete = () => {
        setOpenDelete(false);
    }
    const handleCloseDelete1 = () => {
        setOpenDelete1(false)
    }
    const handleDelete = () => {

        if (idthing != "" || idthing != undefined) {

            axios.delete(url + "/thing/" + idthing, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + getToken()
                }
            }).then(response => {
                if (response) {
                    handleCloseDelete();
                    handleClose2();
                    fetchData();
                }
            }).catch(error => {
                if (error) alert("Instance is not running ,you should restart the flow and delete after ");
            });
        }

    }
    const handleDelete1 = () => {


        axios.delete(url + "/acl/" + idAcl, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getToken()
            }
        }).then(response => {
            if (response) {
                handleCloseDelete1();
                fetchDataAcl(idthing);
            }
        }).catch(error => {
            if (error) alert("Instance is not running ,you should restart the flow and delete after ");
        });
    }

    const handleRestart = () => {
        if (idthing != "" || idthing != undefined) {
            var d = {
                id: idthing
            }

            axios.post(url + "/restartFlow", d, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + getToken()
                }
            }).then(response => {
                if (response) {
                    if (!loading1) {
                        setSuccess1(false);
                        setLoading1(true);
                        timer1.current = window.setTimeout(() => {
                            setSuccess1(true);
                            setLoading1(false);
                        }, 5000);

                    }

                    handleCloseDelete();
                    handleClose2();
                    fetchData();
                }
            }).catch(error => {
                if (error.response.status === 401) console.log(" error ");
            });
        }

    }
    const [progress, setprogress] = useState(false);
    const [messageError, SetMessageError] = useState("");

    const handleAdd = () => {

        var data = {
            "name": thing.thingname,
        };
        setprogress(true)
        axios.post(url + "/addThing", data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getToken()
            }
        }).then(response => {
            if (response.data.message != undefined) {
                SetMessageError(response.data.message);
                setprogress(false);
            } else {
                handleCloseNew();
                fetchData();
                setprogress(false);

            }
        }).catch(error => {
            if (error.response.status === 401) console.log(" error ");
        });

    }
    const handleUpdate = () => {

        var data = {
            "name": thingdetail.nameth,
        };
        setprogress(true)
        axios.post(url + "/updateThing/" + idthing, data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getToken()
            }
        }).then(response => {
            if (response) {

                handleCloseNew()
                fetchData()
                setprogress(false)


            }
        }).catch(error => {
            if (error.response.status === 401) console.log(" error ");
        });

    }

    const [values, setValues] = useState([])
    const [Acls, setAcls] = useState([])
    const [search, setSearch] = useState([])


    const fetchData = async () => {
        try {
            let res = await fetch(url + "/things",
                {
                    method: 'GET', mode: 'cors',
                    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getToken() }
                });
            let myjson = await res.json();
            await setValues(myjson);
            await setSearch(myjson)


            console.log("here")
        } catch (err) {
            alert('fetch data error ' + err)
        }
    }
    const fetchDataAcl = async (idthing) => {
        try {
            let res = await fetch(url + "/acl/" + idthing,
                {
                    method: 'GET', mode: 'cors',
                    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getToken() }
                });
            let myjson = await res.json();
            await setAcls(myjson);


            console.log("here")
        } catch (err) {
            alert('fetch data error ' + err)
        }
    }

    useEffect(() => {
        fetchData()
    }, []);

    const handlesearch = event => {

        console.log(event.target.value)
        setValues(values.filter(value => {


            if (value.name.includes(event.target.value) == true) return value

        }));
        if ((values.length == 0) || (event.target.value == ""))
            fetchData();


    };

    return (
        <div>
            <Drawer anchor={"right"} open={state["right"]} onClose={toggleDrawer("right", false)} classes={{
                paper: classes.drawerPaper,
            }}

            >
                {list("right")}
            </Drawer>
            <Grid container spacing={3}>
                <Grid item xs={3}>
                    <div className={classes.row}>

                        <SearchInput
                            className={classes.searchInput}
                            placeholder="Search thing "
                            name={"search"}
                            values={thing.search}
                            onChange={handlesearch}
                        />
                    </div>
                </Grid>

                <Grid item xs={3}>
                </Grid>

                <Grid item xs={3}>
                </Grid>

                <Grid item xs={3}>
                    <Button
                        aria-controls="customized-menu"
                        aria-haspopup="true"
                        variant="contained"
                        color="primary"
                        onClick={handleClickOpen}
                        style={{ float: "right" }}
                        className={classes.btn}

                    >
                        Add thing
                    </Button>
                </Grid>

                <Grid item xs={12}>
                    <Card>

                        <Grid item xs={12}>
                            <Typography gutterBottom variant="h6" component="h4" className={classes.username}>
                                Things
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Divider />
                        </Grid>

                        <Menu
                            id={`long-menu`}
                            anchorEl={anchorEl2}
                            keepMounted
                            open={open}
                            onClose={handleClose2}
                            PaperProps={{
                                style: {
                                    maxHeight: 48 * 4.5,
                                    width: '20ch'
                                },
                            }}
                        >
                            <MenuItem key={`1`} selected={"1" === 'Pyxis'} onClick={toggleDrawer("right", true)}>
                                {"Details"}
                            </MenuItem>

                            <MenuItem key={`4`} selected={"4" === 'Pyxis'} onClick={handleOpenDelete}>
                                {"Delete "}
                            </MenuItem>

                        </Menu>

                        <Grid style={{ padding: 10 }} item xs={12}>

                            <Grid container spacing={3}>
                                <Grid item xs={4}> <Typography
                                    variant="h6"
                                    component="h3"
                                >
                                    {"Name"}
                                </Typography></Grid>
                                <Grid item xs={2}> <Typography
                                    variant="h6"
                                    component="h3"
                                >
                                    {"Status"}
                                </Typography></Grid>
                                <Grid item xs={4}> <Typography
                                    variant="h6"
                                    component="h3"
                                >
                                    {"Date"}
                                </Typography></Grid>
                            </Grid>

                            <List className={classes.root}>

                                {values.map((value) => {
                                    const labelId = `checkbox-list-label-${value.idthing.toString()}`;

                                    return (
                                        <Grid container spacing={3}>
                                            <ListItem key={value.idthing.toString()} role={undefined} dense button
                                                onClick={handleToggle(value)}>

                                                <Grid item xs={4}>
                                                    <ListItemText id={labelId} primary={<>
                                                        <Typography
                                                            variant="h9"
                                                            component="h4"
                                                        >
                                                            <StatusBullet
                                                                style={{marginRight : 10}}
                                                                color={"success"}
                                                                size="sm"
                                                            />
                                                            {value.name}
                                                        </Typography>

                                                    </>} />
                                                </Grid>

                                                <Grid item xs={2}>
                                                    <StyledBreadcrumb component="a" href="#" label={selectData.status}
                                                        style={{ "color": "white" }} />
                                                </Grid>

                                                <Grid item xs={4} style={{ "height": "50px" }}>
                                                    <ListItemText id={labelId} primary={value.created} />

                                                    <ListItemSecondaryAction
                                                        onClick={handleidthing(value.idthing, value.idthing)}>
                                                        <IconButton edge="end" aria-label="comments"
                                                            onClick={handleClick2}>
                                                            <MoreVertIcon />
                                                        </IconButton>
                                                    </ListItemSecondaryAction>
                                                </Grid>

                                            </ListItem>
                                        </Grid>
                                    )
                                })}
                            </List>

                        </Grid>
                    </Card></Grid>

                <Dialog open={openNew} onClose={handleCloseNew} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title"> New Thing</DialogTitle>

                    <Divider></Divider>
                    <DialogContent>
                        <DialogContentText>

                            Things are used to allow you to connect a pure MQTT client to thingwings
                            <Box display="flex">
                                {progress ?
                                    <CircularProgress color="secondary" /> :
                                    <h2 style={{ color: "red" }}>
                                        {messageError}
                                    </h2>}
                            </Box>
                        </DialogContentText>

                        <TextField
                            autoFocus
                            value={thing.thingname}
                            margin="dense"
                            id="thingname"
                            label="Thing Name"
                            name={"thingname"}
                            type="text"
                            fullWidth
                            onChange={handleChangething}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseNew} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleAdd} color="primary">
                            Add
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={openDelete} onClose={handleCloseDelete}>
                    <DialogTitle disableTypography={false}>Confirmation</DialogTitle>
                    <DialogContent dividers>
                        Are you sure you want to delete this thing ?
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => {
                            handleDelete()
                        }} color='primary' size='small'> yes </Button>
                        <Button onClick={handleCloseDelete} color='primary' size='small'> No </Button>
                    </DialogActions>

                </Dialog>

                <Dialog open={openDelete1} onClose={handleCloseDelete}>
                    <DialogTitle disableTypography={false}>Confirmation</DialogTitle>
                    <DialogContent dividers>
                        Are you sure you want to delete this permission ?
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => {
                            handleDelete1()
                        }} color='primary' size='small'> yes </Button>
                        <Button onClick={handleCloseDelete1} color='primary' size='small'> No </Button>
                    </DialogActions>

                </Dialog>

                <StyledMenu
                    id="customized-menu1"
                    anchorEl={anchorEl1}
                    keepMounted
                    open={Boolean(anchorEl1)}
                    onClose={handleClose1}
                >
                    <StyledMenuItem>

                        <ListItemText primary="Manage flow" />
                    </StyledMenuItem>
                    <StyledMenuItem>

                        <ListItemText primary="Delete" />
                    </StyledMenuItem>

                </StyledMenu>

                <Dialog
                    open={opendeploy}
                    onClose={handleClosedeploy}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"\n" +
                        "Thingwings access permissions are used to authorise your Thing to subscribe or publish to MQTT topics:"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <Grid container spacing={3}>

                                <Grid
                                    item

                                    xs={12}>
                                    <TextField
                                        fullWidth
                                        label="Topic path"
                                        margin="dense"
                                        name="name"
                                        variant="outlined"
                                        value={valuesAcl.name}
                                        onChange={handleChangeAcl}
                                    />


                                </Grid>
                                <Grid
                                    item

                                    xs={12}
                                >
                                    <FormControl className={classes.formControl} fullWidth={"100%"}>
                                        <InputLabel htmlFor="age-native-simple">Apply to </InputLabel>
                                        <Select
                                            native
                                            value={valuesAcl.pubsub}
                                            onChange={handleChangeAcl}
                                            inputProps={{
                                                name: 'pubsub',
                                                id: 'age-native-simple',
                                            }}

                                        >

                                            <option value={3}>Publishes & Subscriptions</option>
                                            <option value={2}>Publishes</option>
                                            <option value={1}>Subscriptions</option>
                                        </Select>
                                    </FormControl>


                                </Grid>
                            </Grid>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClosedeploy} color="primary">
                            Cancel
                        </Button>

                        <Button onClick={handleAcl} color="primary" autoFocus>
                            Add
                        </Button>

                    </DialogActions>
                </Dialog>

                <Dialog
                    open={openRestart}
                    onClose={handleCloseRestart}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title1">{"Click to restart your flow :"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description1">
                            {success1 ?
                                <div style={{ color: 'green' }}>{" Flow is running  "} <CheckIcon /></div>
                                : null

                            }

                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseRestart} color="primary">
                            Cancel
                        </Button>
                        <div className={classes.wrapper}>
                            <Button
                                variant="contained"
                                color="primary"
                                className={buttonClassname}
                                disabled={success1}
                                onClick={handleRestart}
                            >
                                Restart
                            </Button>
                            {loading1 && <CircularProgress size={30} className={classes.buttonProgress} />}
                        </div>

                    </DialogActions>
                </Dialog>

                <StyledMenu
                    id="customized-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <StyledMenuItem>

                        <ListItemText primary="New  Flow" onClick={handleClickOpen} />
                    </StyledMenuItem>
                    <StyledMenuItem>

                        <ListItemText primary="From Flow Library " onClick={handleClickOpenLoad} />
                    </StyledMenuItem>

                </StyledMenu>

            </Grid>
        </div>
    );
}


export default Acls;
