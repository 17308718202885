import React, { useEffect, useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  IconButton,
} from "@material-ui/core";
import ScrollBound from "react-scroll-bound";
import MailIcon from "@material-ui/icons/Mail";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Badge from "@material-ui/core/Badge";
import mockData from "./data";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import axios from "axios";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import RefreshIcon from "@material-ui/icons/Refresh";

import uuid from "uuid/v1";
import { getToken } from "../../../../Utils/common";

const useStyles = makeStyles(() => ({
  root: {
    height: "100%",
  },
  content: {
    padding: 0,
  },
  image: {
    height: 48,
    width: 48,
  },
  actions: {
    justifyContent: "flex-end",
  },
}));
const defaultProps = {
  color: "secondary",
  children: <MailIcon />,
};
const options = ["Resume Topic"];
const ITEM_HEIGHT = 48;

const LatestMessages = (props) => {
  const { className, url, history, ...rest } = props;

  const classes = useStyles();

  const [products] = useState(mockData);

  const [progress, setprogress] = useState(true);
  const [values, setValues] = useState([]);
  const [dataselect, setDataselect] = useState([]);
  const fetchData = async () => {
    try {
      let res = await fetch(`${url}/nbrmessage/`, {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getToken(),
        },
      });
      let myjson = await res.json();

      setprogress(false);
      await setValues(myjson);
    } catch (err) {
      console.log("fetch data error " + err);
    }
  };
  const goto = () => {
    history.push("topic");
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const [open, setOpen] = React.useState(false);
  const [messageT, setMessageT] = React.useState("");
  const [name, setName] = React.useState("");
  const handleClickResume = () => {
    var data = [];
    data.push(name);
    data.push(name + "_cache");
    console.log(name);
    axios
      .post(url + "/resumeTopic/", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getToken(),
        },
      })
      .then((response) => {
        if (response.data.message != undefined) {
          setMessageT(response.data.message);
          setOpen(true);
        }
        fetchData();
      })
      .catch((error) => {});
  };
  useEffect(() => {
    fetchData();
  }, []);
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const open1 = Boolean(anchorEl1);

  const handleClick1 = (event, name) => {
    setName(name);
    setAnchorEl1(event.currentTarget);
  };

  const handleClose1 = () => {
    setAnchorEl1(null);
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader
        subtitle={`${products.length} in total`}
        title="Latest Messages"
        action={
          <IconButton size="small">
            <RefreshIcon onClick={fetchData} />
          </IconButton>
        }
      />
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="warning">
          {messageT}
        </Alert>
      </Snackbar>
      <Divider />
      <CardContent className={classes.content}>
        <ScrollBound
          className="my-list"
          style={{
            maxHeight: "400px",
            overflowY: "auto",
          }}
        >
          <List>
            <Box display="flex" justifyContent="center">
              {progress ? (
                <CircularProgress justifyContent="center" color="secondary" />
              ) : null}
            </Box>
            {values.map((product, i) => (
              <ListItem divider={i < products.length - 1} key={i}>
                <ListItemText
                  primary={product.name}
                  key={uuid()}
                  secondary={`# of partitions : ${product.partitions}`}
                />
                {product.value.map((val, j) => (
                  <span>
                    <ListItemText
                      key={uuid()}
                      primary={` partition${j}   | `}
                    />
                    <Badge badgeContent={val} {...defaultProps} />
                  </span>
                ))}

                <IconButton edge="end" size="small">
                  <MoreVertIcon
                    onClick={(event) => {
                      setName(product.name);
                      setAnchorEl1(event.currentTarget);
                    }}
                  />
                </IconButton>
              </ListItem>
            ))}
          </List>{" "}
          <Menu
            id="long-menu"
            anchorEl={anchorEl1}
            keepMounted
            open={open1}
            key={uuid()}
            onClose={handleClose1}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: "20ch",
              },
            }}
          >
            <MenuItem key={uuid()} onClick={handleClickResume}>
              {"Resume Topic"}
            </MenuItem>
          </Menu>
        </ScrollBound>
      </CardContent>
      <Divider />
      <CardActions className={classes.actions}>
        <Button color="primary" size="small" variant="text" onClick={goto}>
          View all <ArrowRightIcon />
        </Button>
      </CardActions>
    </Card>
  );
};

LatestMessages.propTypes = {
  className: PropTypes.string,
};

export default LatestMessages;
