import React, {useEffect, useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import {Card, CardContent, Grid, Typography, Avatar} from '@material-ui/core';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import CardActionArea from "@material-ui/core/CardActionArea";
import {getToken} from "../../../../Utils/common";

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',

    },
    content: {
        alignItems: 'center',
        display: 'flex'
    },
    title: {
        fontWeight: 700
    },
    avatar: {
        backgroundColor: theme.palette.white,
        color: theme.palette.primary.main,
        height: 56,
        width: 56
    },
    icon: {
        height: 32,
        width: 32
    }, difference: {
        marginTop: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        height: "64px"

    }
}));

const TotalApis = props => {
    const {className, url, history, ...rest} = props;

    const classes = useStyles();
    const [values, setValues] = useState([])

    const fetchData = async () => {
        try {
            let res = await fetch(`${url}/AllApi/`,
                {
                    method: 'GET', mode: 'cors',
                    headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getToken()}
                });
            let myjson = await res.json();
            await setValues(myjson);
        } catch (err) {
            console.log('fetch data error ' + err)
        }
    }

    useEffect(() => {
        fetchData()
    }, []);
    const goto = () => {
        history.push('apis');

    }
    return (
        <Card
            {...rest}
            className={clsx(classes.root, className)}
        > <CardActionArea onClick={goto}>
            <CardContent>
                <Grid
                    container
                    justify="space-between"
                >
                    <Grid item>
                        <Typography
                            className={classes.title}
                            color="textSecondary"
                            gutterBottom
                            variant="body2"
                        >
                            APIs
                        </Typography>
                        <Typography
                            color="inherit"
                            variant="h3"
                        >
                            {values.length}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Avatar className={classes.avatar}>
                            <SwapHorizIcon className={classes.icon}/>
                        </Avatar>
                    </Grid>
                    <Grid xs={12}>
                        <div className={classes.difference}></div>
                    </Grid>
                </Grid>
            </CardContent>
        </CardActionArea>
        </Card>
    );
};

TotalApis.propTypes = {
    className: PropTypes.string
};

export default TotalApis;
