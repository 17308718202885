import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/styles';
import {
    Card,
    CardContent,
    CardActions,
    Typography,
    Grid,
    Divider
} from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import GetAppIcon from '@material-ui/icons/GetApp';
import {StatusBullet} from "../../../../../../Components";
import CardHeader from "@material-ui/core/CardHeader";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import ButtonBase from "@material-ui/core/ButtonBase";
import CardActionArea from "@material-ui/core/CardActionArea";
import {parse} from "graphql";
import {getToken} from "../../../../../../Utils/common";
import Notifications from "../../../../../ConnectorUpdate/components/Notifications";

const useStyles = makeStyles(theme => ({
    root: {},
    imageContainer: {
        height: 64,
        width: 64,
        margin: '0 auto',
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: '5px',
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    cardAction: {
        display: 'block',
        textAlign: 'initial'
    }
    ,
    image: {
        width: '100%'
    },
    statsItem: {
        display: 'flex',
        alignItems: 'center'
    },
    statsIcon: {
        color: theme.palette.icon,
        marginRight: theme.spacing(1)
    }
}));

const ProductCard = props => {
    const {className, product, url, history, setValues, ...rest} = props;

    const classes = useStyles();
    const [openDelete, setOpenDelete] = useState(false);
    const handleOpenDelete = (id_model) => {
        setOpenDelete(true);
    }
    const handleCloseDelete = () => {
        setOpenDelete(false);
    }
    const handleDeleteContinue = async (id) => {
        try {
            let res = await fetch(`${url}/deleteConnector/` + name, {
                method: 'GET',
                mode: 'cors',
                headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getToken()}
            })
            if (res) {

                fetchDatanew()

                handleCloseDelete();


            }
        } catch (error) {
            console.error('Error rr:', error)
        }
    }
    const fetchDatanew = async () => {
        try {
            let res = await fetch(`${url}/allConnectors/`,
                {
                    method: 'GET', mode: 'cors',
                    headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getToken()}
                });
            let myjson = await res.json();
            await setValues(myjson);
        } catch (err) {
            alert('fetch data error ' + err)
        }
    }

    const handleUpdate = () => {
        /***his({
      pathname: '/connectorupdate/'+name,

      state: { detail: name }
    })**/


    }
    const [name, setName] = useState(product.name);
    const [status, setStatus] = useState({
        connector: {
            state: "RUNNING",
            worker_id: "127.0.1.1:8083"
        }, tasks: []
    })
    const fetchData = async () => {
        try {
            let res = await fetch(`${url}/connectorStatus/` + product.name,
                {
                    method: 'GET', mode: 'cors',
                    headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getToken()}
                });
            let myjson = await res.json();
            if (myjson.error_code != 404)
                setStatus(myjson);

        } catch (err) {
            alert('fetch data error ' + err)
        }
    }
    const [config, setConfig] = useState({})
    const fetchDataConfig = async () => {
        try {
            let res = await fetch(`${url}/connectorConfig/` + product.name,
                {
                    method: 'GET', mode: 'cors',
                    headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getToken()}
                });
            let myjson = await res.json();
            await setConfig(myjson);

        } catch (err) {
            alert('fetch data error ' + err)
        }
    }

    useEffect(() => {
        fetchDataConfig()
    }, []);
    useEffect(() => {
        fetchData()
    }, []);
    const [openconnect, setOpenConnect] = React.useState(false);

    const handleClickOpen = () => {
        setOpenConnect(true);
    };

    const handleCloseConnect = () => {
        setOpenConnect(false);
    };
    return (
        <Card
            {...rest}
            className={clsx(classes.root, className)}
        >
            <CardActionArea>
                <CardHeader

                    title={product.topic}
                    subheader={product.modelName}
                />

                <CardContent onClick={handleClickOpen}>
                    <div className={classes.imageContainer}>
                        <img
                            alt="Product"
                            className={classes.image}
                            src={
                                (status.type == "sink") ?
                                    "/images/products/product_3.jpg" :
                                    (config["connector.class"] == "com.test.HttpSourceConnector") ?
                                        "/images/products/product_7.png" :
                                        "/images/products/product_1.png"}
                        />
                    </div>
                    <Typography
                        align="center"
                        gutterBottom
                        variant="h4"
                    >
                        {product.name}
                    </Typography>
                    <Typography
                        align="center"
                        variant="body1"
                    >
                        {status.type}
                    </Typography>
                </CardContent>
            </CardActionArea>
            <Divider/>
            <CardActions>
                <Grid
                    container
                    justify="space-between"
                >
                    <Grid
                        className={classes.statsItem}
                        item
                    >

                        <Typography
                            display="inline"
                            variant="body2"
                        >
                            {status.connector.worker_id}
                        </Typography>
                    </Grid>
                    <Grid
                        className={classes.statsItem}
                        item
                    >
                        <div className={classes.statusContainer}>
                            {
                                (status.type == "sink") ?
                                    (status.tasks.length > 0) ?

                                        (status.tasks[0].state == "FAILED") ?
                                            <StatusBullet
                                                className={classes.status}
                                                color={"warning"}
                                                size="sm"
                                            /> :
                                            <StatusBullet
                                                className={classes.status}
                                                color={"success"}
                                                size="sm"
                                            /> :
                                        <StatusBullet
                                            className={classes.status}
                                            color={"success"}
                                            size="sm"
                                        /> :
                                    <StatusBullet
                                        className={classes.status}
                                        color={"success"}
                                        size="sm"
                                    />

                            }
                        </div>
                        <Typography
                            display="inline"
                            variant="body2"
                        >
                            {
                                (status.type == "sink") ?
                                    (status.tasks.length > 0) ?

                                        status.tasks[0].state :
                                        status.connector.state :
                                    status.connector.state


                            }
                        </Typography>
                    </Grid>
                </Grid>
                <Dialog open={openDelete} onClose={handleCloseDelete}>
                    <DialogTitle disableTypography={false}>Confirmation</DialogTitle>
                    <DialogContent dividers>
                        Are you sure you want to delete this model ?
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => {
                            handleDeleteContinue("1")
                        }} color='primary' size='small'> yes </Button>
                        <Button onClick={handleCloseDelete} color='primary' size='small'> No </Button>
                    </DialogActions>

                </Dialog>
                <Dialog
                    open={openconnect}
                    onClose={handleCloseConnect}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >

                    <DialogContent>

                        <Notifications url={url} history={history} product={product}/>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseConnect} color="primary">
                            Back
                        </Button>

                    </DialogActions>
                </Dialog>


            </CardActions>


        </Card>
    );
};

ProductCard.propTypes = {
    className: PropTypes.string,
    product: PropTypes.object.isRequired
};

export default ProductCard;
