import React, {forwardRef} from 'react';
import {NavLink as RouterLink} from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import {List, ListItem, Button, colors} from '@material-ui/core';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Link from "@material-ui/core/Link";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Icon from "@material-ui/core/Icon";
import ListItemText from "@material-ui/core/ListItemText";

const useStyles = makeStyles(theme => ({
    root: {},
    item: {
        display: 'flex',
        paddingTop: 0,
        paddingBottom: 0,
        textDecoration: 'none',
    },
    button: {

        padding: '10px 8px',
        justifyContent: 'flex-start',
        textTransform: 'none',
        letterSpacing: 0,
        width: '100%',
        fontWeight: theme.typography.fontWeightMedium
    },
    icon: {
        color: theme.palette.icon,
        width: 24,
        height: 24,
        display: 'flex',
        alignItems: 'center',
        marginRight: theme.spacing(1)
    },
    active: {
        color: theme.palette.primary.main,
        fontWeight: theme.typography.fontWeightMedium,
        '& $icon': {
            color: theme.palette.primary.main
        }
    }
}));

const CustomRouterLink = forwardRef((props, ref) => (
    <div
        ref={ref}
        style={{flexGrow: 1}}
    >
        <RouterLink {...props} />
    </div>
));

const SidebarNav = props => {
    const {pages, className, history, stay, ...rest} = props;
    const classes = useStyles();
    const [open, setOpen] = React.useState(false)
    const [openstep, SetopenStep] = React.useState("false")
    const [link, setLink] = React.useState("")
    const handleClick = (href) => {

        console.log("here" + href)
        if (history.location.pathname == "/steps") {
            SetopenStep("/steps")
            setLink(href)
            setOpen(true)

            return "/steps"
        } else {
            SetopenStep("")
            history.push(href)

        }

    }
    const handleClose = () => {
        setOpen(false);
    }
    const handleGo = () => {

        history.push(link)
        setOpen(false)

    }


    return (
        <List
            {...rest}
            className={clsx(classes.root, className)}
        >
            {pages.map(page => (
                <ListItem
                    className={classes.item}
                    disableGutters
                    key={page.title}

                    component={Link} href={`#`}
                    color="inherit"
                    style={{
                        "text-decoration": "none",
                        "color": "#4d4dff",
                        "&:focus, &:hover, &:visited, &:link, &:active": {
                            "text-decoration": "none",

                        }
                    }}
                >
                    <Button
                        activeClassName={classes.active}
                        className={classes.button}
                        onClick={() => handleClick(page.href)}
                    >
                        <div className={classes.icon}>{page.icon}</div>
                        {page.title}
                    </Button>
                </ListItem>

            ))}

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle disableTypography={false}>Confirmation</DialogTitle>
                <DialogContent dividers>
                    Are you sure you want to exit this process ?
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        handleGo()
                    }} color='primary' size='small'> yes </Button>
                    <Button onClick={handleClose} color='primary' size='small'> No </Button>
                </DialogActions>

            </Dialog>
        </List>
    );
};

SidebarNav.propTypes = {
    className: PropTypes.string,
    pages: PropTypes.array.isRequired,
    history: PropTypes.any
};

export default SidebarNav;
