import React, { useState, useEffect } from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import DeleteIcon from '@material-ui/icons/Delete';
import SlideshowIcon from '@material-ui/icons/Slideshow';
import PausePresentationIcon from '@material-ui/icons/PausePresentation';
import EmailIcon from '@material-ui/icons/Email';
import axios from "axios";
import { getToken } from "../../Utils/common";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import ShowChartIcon from '@material-ui/icons/ShowChart';

const defaultToolbarSelectStyles = {
    iconButton: {},
    iconContainer: {
        marginRight: "24px",
    },
    inverseIcon: {
        transform: "rotate(90deg)",
    },
};

function CustomToolbarSelect(props) {
    
    const [open, setOpen] = useState(false);

    const handleClickInverseSelection = () => {
        let vals = props.selectedRows.data.map(row => row.dataIndex);
        console.log(vals);
        let data = vals.map(index => props.values[index][0] + "_cache");

        new Promise(resolve => {
            axios.post(props.url + '/resumeTopic/', data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + getToken()
                }
            }).then(response => {
                props.setOpen({
                    open: true,
                    text: "topic resumed with success"
                });
            }).catch(error => {
                console.error(error);
            });
        });
    };

    const handleClickDeselectAll = () => {
        let vals = props.selectedRows.data.map(row => row.dataIndex);
        let data = vals.map(index => props.values[index][0] + "_cache");

        new Promise(resolve => {
            axios.post(props.url + '/pauseTopic/', data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + getToken()
                }
            }).then(response => {
                props.setOpen({
                    open: true,
                    text: "topic paused with success"
                });
            }).catch(error => {
                console.error(error);
            });
        });
    };

    const getRandomColor = () => {
        var letters = '0123456789ABCDEF';
        var color = '#';
        for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    };

    const handleClickBlockSelected = () => {
        setOpen(true);
    };

    const handledisplaydata = () => {
        axios.get(`${props.url}/getTopicKafka/` + props.values[props.selectedRows.data[0].dataIndex][0], {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getToken()
            }
        }).then(res => {
            props.setlength(6);
            props.setDataselect(res.data);
        });
    };

    const handleDelete = () => {
        let vals = props.selectedRows.data.map(row => row.dataIndex);
        let data = vals.map(index => props.values[index][0]);
        data.map(dt => {
            axios.post(props.url + '/deleteTopic/' + dt, {}, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + getToken()
                }
            }).then(response => {
                props.handleAllData();
                setOpen(false);
            }).catch(error => {
                console.error(error);
            });
        });
    };

    const handleShow = () => {
        var arr = [];
        var da = props.arr;
        console.log(da);
        for (let i = 0; i < da.length; i++) {
            var color = getRandomColor();
            arr.push({
                label: props.displayData[da[i]].data[0],
                borderColor: color,
                backgroundColor: color,
                fill: false,
                cubicInterpolationMode: 'monotone',
                data: [{
                    x: Date.now(),
                    y: 0
                }]
            });
        }
        console.log(arr);
        props.setdata1({
            datasets: arr
        });
        props.setVal(props.arr);
    };

    const handleCloseDelete = () => {
        setOpen(false);
    };

    const { classes } = props;

    return (
        <div className={classes.iconContainer}>
            <Tooltip title={"Pause Topic(s)"}>
                <IconButton className={classes.iconButton} onClick={handleClickDeselectAll}>
                    <PausePresentationIcon className={classes.icon} />
                </IconButton>
            </Tooltip>
            <Tooltip title={"Resume Topic(s)"}>
                <IconButton className={classes.iconButton} onClick={handleClickInverseSelection}>
                    <SlideshowIcon className={[classes.icon, classes.inverseIcon].join(" ")} />
                </IconButton>
            </Tooltip>
            <Tooltip title={"Delete Topic(s)"}>
                <IconButton className={classes.iconButton} onClick={handleClickBlockSelected}>
                    <DeleteIcon className={classes.icon} />
                </IconButton>
            </Tooltip>
            <Tooltip title={"See Messages"}>
                {props.selectedRows.data.length > 1 ?
                    <IconButton className={classes.iconButton} disabled onClick={handledisplaydata}>
                        <EmailIcon className={classes.icon} />
                    </IconButton> :
                    <IconButton className={classes.iconButton} disabled={props.disable} onClick={handledisplaydata}>
                        <EmailIcon className={classes.icon} />
                    </IconButton>
                }
            </Tooltip>
            <Tooltip title={"Add to Chart"}>
                <IconButton className={classes.iconButton} onClick={handleShow}>
                    <ShowChartIcon className={classes.icon} />
                </IconButton>
            </Tooltip>
            <Dialog open={open} onClose={handleCloseDelete}>
                <DialogTitle disableTypography={false}>Confirmation</DialogTitle>
                <DialogContent dividers>
                    Are you sure you want to delete this template?
                </DialogContent>
                <DialogActions>
                    <Button color='primary' onClick={handleDelete} size='small'>yes</Button>
                    <Button onClick={handleCloseDelete} color='primary' size='small'>No</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default withStyles(defaultToolbarSelectStyles, {name: "CustomToolbarSelect"})(CustomToolbarSelect);

