import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/styles';
import {
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Grid,
    Divider,
    FormControlLabel,
    Checkbox,
    Typography,
    Button, TextField
} from '@material-ui/core';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import axios from "axios";
import Collapse from "@material-ui/core/Collapse";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import {getToken} from "../../../../Utils/common";
import createSpacing from "@material-ui/core/styles/createSpacing";
import FileUpload from "./components/FileUpload";

const useStyles = makeStyles((theme) => ({
    root: {},
    item: {
        display: 'flex',
        flexDirection: 'column'
    }
    , button: {
        margin: theme.spacing(1),
    }
}));


const Notifications = props => {
    const {className, url, history, ...rest} = props;

    const classes = useStyles();

    const [topics, setTopics] = useState([])

    const [values, setValues] = useState({
        name: "",
        connectorClass: "",
        tasksMax: "1",
        type: "",
        valueConverter: "org.apache.kafka.connect.json.JsonConverter",
        valueConverterschemasenable: "false",
        schemaIgnore: "true",
        connectionUrl: "https://www.thingwings.com:9201",
        typeName: "kafka-connect",
        topics: "",
        keyIgnore: "true",
        serveruri: "tcp://thingwings.com:1883",
        topicsource: "",
        topicserver: "thingwings.com:9092",
        elasticUsername: "",
        elasticPassword: "",
        sslcert: "",
        sslca: "",
        sslkey: "",
        mqttUsername: "",
        mqttPassword: ""

    });

    const handleChange = event => {
        console.log(event.target.value)
        if (event.target.value == "com.test.HttpSourceConnector") {
            setValues({
                ...values,
                [event.target.name]: event.target.value,
            });
            values["topicserver"] = "thingwings.com:8082";
        } else {
            setValues({
                ...values,
                [event.target.name]: event.target.value
            });
            values["topicserver"] = "https://www.thingwings.com:9201";
        }
        ;
    };

    const handleChangewithfield = event => {

        setValues({
            ...values,
            [event.target.name]: event.target.value
        });
        if (event.target.value == "sink") {
            setExpanded2(false);
            setExpanded(true);
        } else if (event.target.value == "source") {
            setExpanded(false);
            setExpanded2(true);

        } else {
            setExpanded(false);
            setExpanded2(false);

        }
    };

    const fetchData = async () => {
        try {
            let res = await fetch(`${url}/allTopicConnector`,
                {
                    method: 'GET', mode: 'cors',
                    headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getToken()}
                });
            let myjson = await res.json();
            console.log(myjson)
            await setTopics(myjson);


        } catch (err) {
            alert('fetch data error ' + err)
        }
    }

    const [error, setError] = React.useState(false);
    const [helperror, SetHelperror] = React.useState("");
    const [errortype, seterrortype] = useState({
        error: false,
        text: ""

    })
    const [errorclass, seterrorclass] = useState({
        error: false,
        text: ""

    })
    const [errorconnectionUrl, seterrorconnectionUrl] = useState({
        error: false,
        text: ""

    })

    const [errortopic, seterrortopic] = useState({
        error: false,
        text: ""

    })
    const [errorformat, seterrorformat] = useState({
        error: false,
        text: ""

    })
    const [errorserveruri, seterrorserveruri] = useState({
        error: false,
        text: ""

    })
    const [errortopicserver, seterrortopicserver] = useState({
        error: false,
        text: ""

    })
    const [errortopics, seterrortopics] = useState({
        error: false,
        text: ""

    })
    useEffect(() => {
        fetchData()
    }, []);

    const handlesend = () => {


        let checkname = topics.filter(val => val.name == values.name)


        if (values.type == "") seterrortype({error: true, text: "field required"})
        if (values.topics == "") seterrortopics({error: true, text: "field required"})


        if (values.type == "source") {

            if (values.topicsource == "") {
                seterrortopic({error: true, text: "field required"})
            }
            if (values.serveruri == "") {
                seterrorserveruri({error: true, text: "field required"})
            }
            if (values.topicserver == "") {
                seterrortopicserver({error: true, text: "field required"})
            }

            if (values.connectorClass == "io.confluent.connect.elasticsearch.ElasticsearchSinkConnector") {
                seterrorclass({error: true, text: "field required"})
            }

        } else {

            if (values.connectionUrl == "") {
                seterrorconnectionUrl({error: true, text: "field required"})
            }
            if (values.connectorClass != "io.confluent.connect.elasticsearch.ElasticsearchSinkConnector") {
                seterrorclass({error: true, text: "field required"})
            }


        }
        if (values.connectorClass == "") {
            seterrorclass({error: true, text: "field required"})
        }
        if (values.valueConverter == "") {
            seterrorformat({error: true, text: "field required"})
        }

        if (values.name == "") {
            SetHelperror("field required");
            setError(true)
        }
        if (values.name != "" && values.type != "" && values.valueConverter != "" && values.connectorClass != "" && values.topics != "") {

            if (values.type == "source") {
                if (values.topicsource != "" && values.topicserver != "" && values.serveruri != "" && values.connectorClass != "io.confluent.connect.elasticsearch.ElasticsearchSinkConnector") {
                    if (checkname.length == 0) {
                        let data = {

                            name: values.name,
                            connectorClass: values.connectorClass,
                            tasksMax: "1",
                            type: values.type,

                            valueConverter: values.valueConverter,

                            valueConverterschemasenable: values.valueConverterschemasenable,

                            schemaIgnore: values.schemaIgnore,

                            connectionUrl: values.connectionUrl,
                            typeName: values.typeName,
                            topics: values.topics,
                            keyIgnore: values.keyIgnore,
                            serveruri: values.serveruri,
                            topicsource: values.topicsource,
                            topicserver: values.topicserver,
                            mqttUsername: values.mqttUsername,
                            mqttPassword: values.mqttPassword,
                            sslcert: values.sslcert,
                            sslca: values.sslca,
                            sslkey: values.sslkey


                        }

                        axios.post(url + '/addConnector', data, {
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + getToken()
                            }
                        }).then(response => {

                            if (response.data.error)
                                setOpen1(true)

                            else
                                setOpen(true)


                            fetchData()
                        }).catch(error => {


                        });

                    } else {
                        SetHelperror("name exist")
                        setError(true);
                    }


                }
            } else {
                if (values.connectionUrl != "" && values.connectorClass == "io.confluent.connect.elasticsearch.ElasticsearchSinkConnector") {
                    if (checkname.length == 0) {
                        let data = {

                            name: values.name,
                            connectorClass: values.connectorClass,
                            tasksMax: "1",
                            type: values.type,

                            valueConverter: values.valueConverter,

                            valueConverterschemasenable: values.valueConverterschemasenable,

                            schemaIgnore: values.schemaIgnore,

                            connectionUrl: values.connectionUrl,
                            typeName: values.typeName,
                            topics: values.topics,
                            keyIgnore: values.keyIgnore,
                            serveruri: values.serveruri,
                            topicsource: values.topicsource,
                            topicserver: values.topicserver,
                            elasticUsername: values.elasticUsername,
                            elasticPassword: values.elasticPassword

                        }

                        axios.post(url + '/addConnector', data, {
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + getToken()
                            }
                        }).then(response => {

                            if (response.data.error)
                                setOpen1(true)

                            else
                                setOpen(true)

                            fetchData()
                        }).catch(error => {


                        });
                    } else {
                        SetHelperror("name exist")
                        setError(true);
                    }

                }

            }

        }
    }


    const [expanded, setExpanded] = React.useState(false);
    const [expanded2, setExpanded2] = React.useState(false);
    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const handleCancel = () => {

        history.push('topic')
    }

    const [open, setOpen] = React.useState(false);
    const [open1, setOpen1] = React.useState(false);
    const handleClick = () => {
        setOpen(true);
    };

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
        setOpen1(false)

    };
    const handleset = event => {
        if (event.target.value == "com.test.MqttSourceConnector")

            setValues({
                ...values,
                ["valueConverter"]: "org.apache.kafka.connect.converters.ByteArrayConverter"


            })

    }
    return (
        <Card
            {...rest}
            className={clsx(classes.root, className)}
        >
            <form>
                <CardHeader
                    subheader=""
                    title=" Connector information"
                />
                <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="success">
                        info added with success

                    </Alert>
                </Snackbar>
                <Snackbar open={open1} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="warning">
                        name is not available

                    </Alert>
                </Snackbar>
                <Divider/>
                <CardContent>


                    <div style={{gridColumnEnd: 'span 6'}}>
                        <TextField
                            fullWidth
                            label=" Name"
                            margin="dense"
                            name="name"
                            required
                            variant="outlined"
                            value={values.name}
                            onChange={handleChange}
                            error={error}
                            helperText={helperror}

                        />
                    </div>
                    <div style={{gridColumnEnd: 'span 6'}}>
                        <FormControl className={classes.formControl} fullWidth>
                            <InputLabel htmlFor="age-native-simple">Type </InputLabel>
                            <Select
                                native
                                value={values.type}
                                onChange={handleChangewithfield}
                                inputProps={{
                                    name: 'type',
                                    id: 'age-native-simple',
                                }}
                                error={errortype.error}
                                helperText={errortype.text}
                            >
                                <option aria-label="None" value=""/>
                                <option value={"sink"}>Sink</option>
                                <option value={"source"}>Source</option>
                            </Select>
                        </FormControl>

                    </div>

                    <Collapse in={expanded2} timeout="auto" unmountOnExit>
                        <CardContent>
                            <FormControl className={classes.formControl} fullWidth>
                                <InputLabel htmlFor="age-native-simple">Topic </InputLabel>
                                <Select
                                    native
                                    value={values.topics}
                                    onChange={handleChange}
                                    inputProps={{
                                        name: 'topics',
                                        id: 'age-native-simple',
                                    }}
                                    error={errortopics.error}
                                    helperText={errortopics.text}
                                >
                                    <option aria-label="None" value=""/>
                                    {topics.map(topic => (
                                        <option value={topic.name}>{topic.name}</option>
                                    ))}
                                </Select>

                            </FormControl>


                            <FormControl className={classes.formControl} fullWidth>
                                <InputLabel htmlFor="age-native-simple">Value Converter </InputLabel>
                                <Select
                                    native
                                    value={values.valueConverter}
                                    onChange={handleChange}
                                    inputProps={{
                                        name: 'valueConverter',
                                        id: 'age-native-simple',
                                    }}
                                    error={errorformat.error}
                                    helperText={errorformat.text}
                                >
                                    <option aria-label="None" value=""/>
                                    <option value={"org.apache.kafka.connect.converters.ByteArrayConverter"}>ByteArray
                                    </option>
                                    <option value={"org.apache.kafka.connect.json.JsonConverter"}>JSON</option>


                                </Select>
                            </FormControl>


                            <FormControl className={classes.formControl} fullWidth>
                                <InputLabel htmlFor="age-native-simple">Connector Class </InputLabel>
                                <Select
                                    native
                                    value={values.connectorClass}
                                    onChange={handleChange}
                                    inputProps={{
                                        name: 'connectorClass',
                                        id: 'age-native-simple',
                                    }}
                                    error={errorclass.error}
                                    helperText={errorclass.text}
                                    onClick={handleset}
                                >
                                    <option aria-label="None" value=""/>
                                    <option value={"com.test.MqttSourceConnector"}>MQTT</option>
                                    <option value={"com.test.HttpSourceConnector"}>HTTP</option>
                                </Select>
                            </FormControl>


                            <TextField
                                fullWidth
                                label="Server  "
                                margin="dense"
                                name="topicserver"
                                required
                                variant="outlined"
                                value={values.topicserver}
                                onChange={handleChange}

                                error={errortopicserver.error}
                                helperText={errortopicserver.text}
                            />
                            <TextField
                                fullWidth
                                label="Server Uri Source"
                                margin="dense"
                                name="serveruri"
                                required
                                variant="outlined"
                                value={values.serveruri}
                                onChange={handleChange}
                                error={errorserveruri.error}
                                helperText={errorserveruri.text}
                            />

                            <TextField
                                fullWidth
                                label="Topic Source"
                                margin="dense"
                                name="topicsource"
                                required
                                variant="outlined"
                                value={values.topicsource}
                                onChange={handleChange}
                                error={errortopic.error}
                                helperText={errortopic.text}
                            />
                            <TextField
                                fullWidth
                                label="Username"
                                margin="dense"
                                name="mqttUsername"
                                required
                                variant="outlined"
                                value={values.mqttUsername}
                                onChange={handleChange}


                            />
                            <TextField
                                fullWidth
                                label="Password"
                                margin="dense"
                                name="mqttPassword"
                                required
                                variant="outlined"
                                value={values.mqttPassword}
                                onChange={handleChange}
                            />

                            <FileUpload url={url} history={history} values={values} setValues={setValues}/>
                        </CardContent>
                    </Collapse>


                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                        <CardContent>

                            <FormControl className={classes.formControl} fullWidth>
                                <InputLabel htmlFor="age-native-simple">Topic </InputLabel>
                                <Select
                                    native
                                    value={values.topics}
                                    onChange={handleChange}
                                    inputProps={{
                                        name: 'topics',
                                        id: 'age-native-simple',
                                    }}
                                    error={errortopics.error}
                                    helperText={errortopics.text}
                                >
                                    <option aria-label="None" value=""/>
                                    {topics.map(topic => (
                                        <option value={topic.name}>{topic.name}</option>
                                    ))}
                                </Select>
                            </FormControl>

                            <FormControl className={classes.formControl} fullWidth>
                                <InputLabel htmlFor="age-native-simple">Value Converter </InputLabel>
                                <Select
                                    native
                                    value={values.valueConverter}
                                    onChange={handleChange}
                                    inputProps={{
                                        name: 'valueConverter',
                                        id: 'age-native-simple',
                                    }}
                                    error={errorformat.error}
                                    helperText={errorformat.text}
                                >
                                    <option aria-label="None" value=""/>
                                    <option value={"org.apache.kafka.connect.json.JsonConverter"}>JSON</option>
                                </Select>
                            </FormControl>


                            <FormControl className={classes.formControl} fullWidth>
                                <InputLabel htmlFor="age-native-simple">Connector Class </InputLabel>
                                <Select
                                    native
                                    value={values.connectorClass}
                                    onChange={handleChange}
                                    inputProps={{
                                        name: 'connectorClass',
                                        id: 'age-native-simple',
                                    }}
                                    error={errorclass.error}
                                    helperText={errorclass.text}
                                >
                                    <option aria-label="None" value=""/>
                                    <option
                                        value={"io.confluent.connect.elasticsearch.ElasticsearchSinkConnector"}>Elasticsearch
                                    </option>

                                </Select>
                            </FormControl>
                            <div style={{gridColumnEnd: 'span 4'}}>

                                <TextField
                                    fullWidth
                                    label="Tasks Max "
                                    margin="dense"
                                    name="tasks"
                                    required
                                    variant="outlined"
                                    value={values.tasksMax}
                                    onChange={handleChange}
                                />
                            </div>
                            <div style={{gridColumnEnd: 'span 4'}}>
                                <TextField
                                    fullWidth
                                    label="Connection Url"
                                    margin="dense"
                                    name="connectionUrl"
                                    required
                                    variant="outlined"
                                    value={values.connectionUrl}
                                    onChange={handleChange}
                                    error={errorconnectionUrl.error}
                                    helperText={errorconnectionUrl.text}
                                />
                            </div>
                            <TextField
                                fullWidth
                                label="Username"
                                margin="dense"
                                name="elasticUsername"
                                required
                                variant="outlined"
                                value={values.elasticUsername}
                                onChange={handleChange}


                            />
                            <TextField
                                fullWidth
                                label="Password"
                                margin="dense"
                                name="elasticPassword"
                                required
                                variant="outlined"
                                value={values.elasticPassword}
                                onChange={handleChange}
                            />


                        </CardContent>
                    </Collapse>


                </CardContent>
                <Divider/>
                <CardActions>
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={handlesend}
                    >
                        Save
                    </Button>
                </CardActions>
            </form>
        </Card>
    );
};

Notifications.propTypes = {
    className: PropTypes.string
};

export default Notifications;
