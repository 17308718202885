//import {BrowserRouter as Router, Route, Switch, NavLink, Redirect} from 'react-router-dom';
import React, {Component} from 'react';
import {Router} from 'react-router-dom';
import {createBrowserHistory} from 'history';
import Routes from './Routes';
import {ThemeProvider} from '@material-ui/styles';

//import React, {useState , useEffect , Suspense, lazy } from 'react';
import {MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';


//const client = new ApolloClient( {uri : path + '/graphql'});
const browserHistory = createBrowserHistory();
const theme = createMuiTheme()
export default class App extends Component {
    render() {
        return (
            <MuiThemeProvider theme={theme}>
                <Router history={browserHistory}>
                    <Routes history={browserHistory}/>
                </Router>
            </MuiThemeProvider>
        );
    }
}
