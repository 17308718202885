import React, { useState } from "react";
import axios from "axios";
import {
  getUser,
  setNotifications,
  setUserSession,
  setSetting,
} from "../../Utils/common";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Toolbar from "@material-ui/core/Toolbar";
import CardMedia from "@material-ui/core/CardMedia";
import { Link as RouterLink } from "react-router-dom";
import { Alert } from "@material-ui/lab";
const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const SignIn = (props) => {
  const { history, url } = props;
  const [loading, setLoading] = useState(false);

  const username = useFormInput("");
  const password = useFormInput("");
  const [error, setError] = useState(null);
  const classes = useStyles();

  if (getUser() != null) {
    history.push("/");
  }
  // handle button click of login form
  const handleLogin = () => {
    setError(null);
    setLoading(true);
    axios
      .post(url + "/auth/login", {
        identifier : username.value,
        password: password.value,
      })
      .then((response) => {
        setUserSession(response.data.token, response.data.user, {});
        setNotifications({});
        if (getUser().admin) window.location.reload();
        else window.location.reload();
      })
      .catch((error) => {
        setLoading(false);
        setError("username or password is incorrect  ... .");
      });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") handleLogin();
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />

      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          <img
            src={"signin.png"}
            alt={"BeeMetrix"}
            width={"250px"}
            height={"100px"}
          />{" "}
        </Typography>

        <div className={classes.form}>
          <div>
            <TextField
              type="text"
              {...username}
              autoComplete="username"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username/Email"
              name="username"
              onKeyDown={handleKeyDown}
              autoFocus
            />
          </div>

          <div>
            <TextField
              type="password"
              {...password}
              autoComplete="new-password"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              onKeyDown={handleKeyDown}
              id="password"
              label="Password"
              name="password"
              autoFocus
            />
          </div>

          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
          <Link component={RouterLink} to="/signup" variant="h6">
            Sign up
          </Link>

          {error && (
            <Alert
              severity="error"
              onClose={() => {
                setError(null);
              }}
            >
              Username or Password incorrect
            </Alert>
          )}

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            value={loading ? "Loading..." : "Login"}
            onClick={handleLogin}
            disabled={loading}
          >
            Sign In
          </Button>
        </div>
      </div>
    </Container>
  );
};

const useFormInput = (initialValue) => {
  const [value, setValue] = useState(initialValue);
  const handleChange = (e) => setValue(e.target.value);
  return { value, onChange: handleChange };
};

export default SignIn;
