import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import withStyles from "@material-ui/core/styles/withStyles";
import StarIcon from '@material-ui/icons/StarBorder';
import uuid from 'uuid/v1';
import SwipeableViews from 'react-swipeable-views';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import List from "@material-ui/core/List";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import ListItem from '@material-ui/core/ListItem';
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Slide from "@material-ui/core/Slide";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import axios from "axios";
import { getToken, getUser } from "../../Utils/common";
import CardActions from "@material-ui/core/CardActions";

import CardHeader from "@material-ui/core/CardHeader";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import { SearchInput, StatusBullet } from "../../Components";
import LinearProgress from "@material-ui/core/LinearProgress";
import PropTypes from 'prop-types';
import { green } from '@material-ui/core/colors';
import clsx from 'clsx';
import Container from "@material-ui/core/Container";
import { Settings } from "@material-ui/icons";
import useTheme from "@material-ui/core/styles/useTheme";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import Cards from "../Account/components/cards";
import CardActionArea from "@material-ui/core/CardActionArea";
import Avatar from "@material-ui/core/Avatar";
import DeleteIcon from "@material-ui/icons/Delete";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3)
    },
    content: {
        marginTop: theme.spacing(2)
    },
    pagination: {
        marginTop: theme.spacing(3),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end'
    },
    btn: {
        marginRight: theme.spacing(1),
    },
    media: {
        height: 0,
        paddingTop: '60.25%', // 16:9
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },

    cover: {
        width: 151,
    },
    controls: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    playIcon: {
        height: 38,
        width: 38,
    }, appBar: {
        position: 'relative',
        background: '#2E3B55',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    }, wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
        alignItems: 'center',
    },
    buttonSuccess: {
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
    fabProgress: {
        color: green[500],
        position: 'absolute',
        top: -6,
        left: -6,
        zIndex: 1,
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },

}));


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const Plan = props => {
    const { className, url, history, ...rest } = props;
    const classes = useStyles();
    const [checked, setChecked] = React.useState([0]);
    const [anchorEl2, setAnchorEl2] = React.useState(null);
    const [opendeploy, setOpendeploy] = React.useState(false);
    const open = Boolean(anchorEl2);
    const theme = useTheme();
    const [personName, setPersonName] = React.useState([]);


    const [value, setValue] = React.useState(0);

    const handleChangeEvent = (event, newValue) => {
        setValue(newValue);
    };
    const handleChangeIndex = (index) => {
        setValue(index);
    };

    const [progress1, setProgress1] = React.useState(10);

    const [openNew, setOpenNew] = React.useState(false);
    const [selected, setSelected] = React.useState("");
    const [selectLibrary, setSelectedLibrary] = React.useState("");

    const [openNot, setOpenNot] = React.useState(false);


    const handleCloseNot = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenNot(false);
    };

    const handleCloseNew = () => {
        setOpenNew(false);
    };


    const handleClick2 = (event) => {

        setAnchorEl2(event.currentTarget);

    };

    const handleClose2 = () => {
        setAnchorEl2(null);
    };

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorEl1, setAnchorEl1] = React.useState(null);
    const [idplan, setidPlan] = React.useState({
        id: "",
        idSub: ""
    });

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClick1 = (event) => {
        setAnchorEl1(event.currentTarget);
    };
    const handleToggle = (id, idsub) => () => {
        setidPlan({
            id: id,
            idSub: idsub
        })
    };
    const handleUpgrade = () => {
        var arr = []
        librarys.map((lib) => {
            if (lib.productPriceId != values[0].item_number)
                arr.push(lib)
        });
        var list = [];
        console.log("here is ");
        console.log(arr);
        arr.map((ar) => {
            if ((values[0].topic == "community") && (ar.metadata.type != "community")) {
                list.push(ar)
            } else if (values[0].topic == "mqtt") {
                if (ar.metadata.type == "mqtt+kafka") {
                    list.push(ar)
                }
            } else if (values[0].topic == "kafka") {
                if (ar.metadata.type == "mqtt+kafka") {
                    list.push(ar)
                }
            }
        });
        console.log(list)

        setOpenload(true)

    };

    const handleidPlan = (id) => () => {

        setidPlan(id)
    }

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleClose1 = () => {
        setAnchorEl1(null);
    };

    const [openLoad, setOpenload] = React.useState(false);

    const handleClickOpenLoad = () => {

        setOpenload(true);
    };

    const handleCloseload = () => {
        setOpenload(false);
        setMessage("")
    };

    const [loading, setLoading] = React.useState(false);
    const [loading1, setLoading1] = React.useState(false);
    const [success1, setSuccess1] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const timer = React.useRef();
    const timer1 = React.useRef();

    const buttonClassname = clsx({
        [classes.buttonSuccess]: success,
    });

    React.useEffect(() => {
        return () => {
            clearTimeout(timer.current);
        };
    }, []);
    React.useEffect(() => {
        return () => {
            clearTimeout(timer1.current);
        };
    }, []);

    const [openDelete, setOpenDelete] = useState(false);
    const handleCloseDelete = () => {
        setOpenDelete(false);
    }
    const handleopneDelete = (id, idSub) => {

        setidPlan({
            id: id,
            idSub: idSub
        });
        setOpenDelete(true);
    }

    const handleDelete = () => {

        axios.delete(url + "/paymentPlan/" + idplan.id + "/" + idplan.idSub, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getToken()
            }
        }).then(response => {
            setOpenDelete(false);
            handleClose2()
            fetchData();
            fetchDataAll();
            fetchDataLibarary();
        }).catch(error => {
            if (error) alert("Plan is not deleted  ");
        });
    }

    const [progress, setprogress] = useState(false);
    const [values, setValues] = useState([]);
    const [valuesAll, setValuesAll] = useState([]);
    const [librarys, setLibrary] = useState([]);
    const [search, setSearch] = useState([]);

    const fetchDataTopic = async () => {
        try {
            let res = await fetch(`${url}/allTopicConnector`,
                {
                    method: 'GET', mode: 'cors',
                    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getToken() }
                });
            let myjson = await res.json();
            console.log(myjson)
            await setTopics(myjson);
        } catch (err) {
            console.log('fetch data error ' + err)
        }
    }
    const [topics, setTopics] = useState([]);
    const [message, setMessage] = useState("");
    const [Created, setCreated] = useState(false);

    const fetchDataAll = async () => {
        try {
            let res = await fetch(url + "/Allpaymentsplan",
                {
                    method: 'GET', mode: 'cors',
                    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getToken() }
                });
            let myjson = await res.json();
            await setValuesAll(myjson);
        } catch (err) {
            console.log('fetch data error ' + err)
        }
    }
    const fetchData = async () => {
        try {
            let res = await fetch(url + "/paymentsplan",
                {
                    method: 'GET', mode: 'cors',
                    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getToken() }
                });
            let myjson = await res.json();
            await setValues(myjson);
            await setSearch(myjson);
            try {
                let res = await fetch(url + "/plans/",
                    {
                        method: 'GET', mode: 'cors',
                        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getToken() }
                    });
                let myjson1 = await res.json();

                var arr = []
                myjson1.map((lib) => {
                    if (lib.productPriceId == myjson[0].item_number)
                        arr.push(lib)
                });
                setList(arr);
                let obj = { ...myjson[0], ...arr[0] };
                var arr1 = [];
                console.log(obj);
                arr1.push(obj);
                setList(arr1);
                console.log(arr1);
                console.log("i am myjson");
            } catch (err) {
                console.log('fetch data error ' + err)
            }

        } catch (err) {
            console.log('fetch data error ' + err)
        }
    }
    const fetchDataLibarary = async () => {
        try {
            let res = await fetch(url + "/plans/",
                {
                    method: 'GET', mode: 'cors',
                    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getToken() }
                });
            let myjson = await res.json();
            await setLibrary(myjson);
            console.log(myjson)
        } catch (err) {
            console.log('fetch data error ' + err)
        }
    }

    useEffect(() => {
        fetchData()
    }, []);
    useEffect(() => {
        fetchDataAll()
    }, []);

    useEffect(() => {
        fetchDataLibarary()
    }, []);
    useEffect(() => {
        fetchDataTopic()
    }, []);
    useEffect(() => {
        fetchDataCard()
    }, []);
    const [cards, setCards] = React.useState([]);

    const fetchDataCard = async () => {
        try {
            let res = await fetch(url + "/cards",
                {
                    method: 'GET', mode: 'cors',
                    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getToken() }
                });
            let myjson = await res.json();
            await setCards(myjson);

            console.log("here")
        } catch (err) {
            console.log('fetch data error ' + err)
        }
    }
    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setPersonName(
            // On autofill we get a the stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    console.log(personName);
    const handleFree = (title, type) => {

        console.log(title)
        if (cards.length > 0) {
            var data = {
                priceId: title,
                type_payment: type
            }

            console.log(data);
            axios.post(url + "/subscribe", data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + getToken()
                }
            }).then(response => {

                if (response.data.message == undefined) {
                    //setOpenNot(true);
                    window.location.reload();

                } else {
                    setMessage(response.data.message);
                    setOpenNot(true);
                }
            })
        } else {
            const promise1 = new Promise((resolve, reject) => {
                resolve(handleAppearCard());


            });

            promise1.then((value) => {
                console.log("list for showing list ")
                console.log(value);
                if (cards.length > 0) {
                    var data = {

                        priceId: title,
                        type_payment: type

                    }
                    console.log(data);
                    axios.post(url + "/subscribe", data, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + getToken()
                        }
                    }).then(response => {
                        if (response.data.message == undefined) {
                            //setOpenNot(true);
                            window.location.reload();

                        } else {
                            setMessage(response.data.message);
                            setOpenNot(true);
                        }
                    })
                }        // expected output: "foo"
            });
        }
    }
    const [lists, setList] = React.useState([])

    const handleAppearCard = () => {
        setOpenNew(true);
    }

    function formatDate(string) {
        var options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(string).toLocaleDateString([], options);
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChangeEvent} aria-label="basic tabs example">
                    <Tab label="Subscription" {...a11yProps(0)} />
                    <Tab label="Subscription history " {...a11yProps(1)} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <Grid item xs={12}>
                    <Button
                        aria-controls="customized-menu"
                        aria-haspopup="true"
                        variant="contained"
                        color="primary"
                        onClick={handleClickOpenLoad}
                        style={{ float: "right" }}
                    >
                        Purchase Plan
                    </Button>
                </Grid>
                <Grid item xs={12} >
                    <Grid item xs={3}>
                        {lists.map(product => (
                            <Card
                                {...rest}
                                className={clsx(classes.root, className)}
                            >
                                <CardActionArea>
                                    <CardHeader
                                        avatar={
                                            <Avatar aria-label="recipe" className={classes.avatar}>
                                                A
                                            </Avatar>
                                        }
                                        action={
                                            <IconButton aria-label="settings" onClick={() => {
                                                handleopneDelete(product.id, product.idSub)
                                            }}>
                                                <DeleteIcon/>
                                            </IconButton>
                                        }
                                        title={product.topic}
                                        subheader={product.create_at}
                                    />
                                    <CardContent>
                                        <div className={classes.imageContainer}>
                                        </div>
                                        <CardMedia
                                            component="img"
                                            height="194"
                                            image={product.image}
                                            alt="Paella dish"
                                        />

                                        <Typography
                                            align="center"
                                            gutterBottom
                                            variant="h4"
                                        >
                                            {
                                                product.amount != "0" ?
                                                    product.amount + "  " + product.currency_code :
                                                    "Free"
                                            }
                                        </Typography>
                                        <Typography
                                            align="center"
                                            variant="body1"
                                        >

                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                                <Divider />
                                <CardActions>
                                    <Grid
                                        container
                                        justify="space-between"
                                    >
                                        <Grid

                                            item
                                        >
                                            <Button variant="outlined" size="small" onClick={handleUpgrade}>Upgrade</Button>

                                        </Grid>
                                        <Grid
                                            className={classes.statsItem}
                                            item
                                        >
                                            <div className={classes.statusContainer}>
                                                <StatusBullet
                                                    className={classes.status}
                                                    color={'success'}
                                                    size="sm"
                                                />
                                                {" "} {product.payment_status}
                                            </div>
                                            <Typography
                                                display="inline"
                                                variant="body2"
                                            >
                                            </Typography>


                                        </Grid>
                                    </Grid>
                                </CardActions>
                                <Dialog open={openDelete} onClose={handleCloseDelete}>
                                    <DialogTitle disableTypography={false}>Confirmation</DialogTitle>
                                    <DialogContent dividers>
                                        Are you sure you want to delete this plan ?
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onclick={handleDelete} color='primary' size='small'> yes </Button>
                                        <Button onClick={handleCloseDelete} color='primary' size='small'> No </Button>
                                    </DialogActions>

                                </Dialog>


                            </Card>
                        ))}
                    </Grid>
                </Grid>

            </TabPanel>
            <TabPanel value={value} index={1}>
                <Grid item xs={12}>

                    <Card>
                        <CardHeader
                            title="List of Plans"

                        />
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>

                        <Menu
                            id={`long-menu`}
                            anchorEl={anchorEl2}
                            keepMounted
                            open={open}
                            onClose={handleClose2}
                            PaperProps={{
                                style: {
                                    maxHeight: 48 * 4.5,
                                    width: '20ch',
                                },
                            }}
                        >

                            <MenuItem key={`1`} selected={"1" === 'Pyxis'} onClick={handleUpgrade}>
                                {"Upgrade Plan"}
                            </MenuItem>
                            <MenuItem key={`2`} selected={"2" === 'Pyxis'} onClick={handleopneDelete}>
                                {"Delete Plan"}
                            </MenuItem>


                        </Menu>
                        <List className={classes.root}>
                            <Grid item xs={12}>
                                <Grid container spacing={1}>

                                    <Grid item xs={3}>
                                        <ListItem key={"1246546789789"} role={undefined}>

                                            <Typography
                                                variant="h7"
                                                component="h3"
                                            >

                                                {"Email"}
                                            </Typography></ListItem></Grid>
                                    <Grid item xs={2}>
                                        <ListItem key={"1246546789789"} role={undefined}>

                                            <Typography
                                                variant="h7"
                                                component="h3"
                                            >

                                                {"Plan"}
                                            </Typography></ListItem></Grid>
                                    <Grid item xs={2}>
                                        <ListItem key={"1246546789789"} role={undefined}>

                                            <Typography
                                                variant="h7"
                                                component="h3"
                                            >

                                                {"Price"}
                                            </Typography></ListItem></Grid>
                                    <Grid item xs={2}>
                                        <ListItem key={"124654678459789"} role={undefined}>
                                            <Typography
                                                variant="h7"
                                                component="h3"
                                            >
                                                {"Date"}
                                            </Typography>

                                        </ListItem> </Grid>
                                    <Grid item xs={2}>
                                        <ListItem key={"12465467454789"} role={undefined}>
                                            <Typography
                                                variant="h7"
                                                component="h3"
                                            >
                                                {"ID subscription"}
                                            </Typography></ListItem></Grid>


                                </Grid></Grid>
                        </List>
                        <Grid item xs={12}>

                            <List className={classes.root}>

                                {valuesAll.map((value) => {
                                    const labelId = `checkbox-list-label-${value.id.toString()}`;

                                    return (
                                        <Grid container spacing={1}>
                                            <ListItem key={value.id.toString()} role={undefined} dense button
                                                onClick={() => {
                                                    handleToggle(value.id, value.idSub)
                                                }}>

                                                <Grid item xs={3}>
                                                    <ListItemText id={labelId} primary={<React.Fragment>
                                                        <Typography
                                                        >
                                                            {value.email}
                                                        </Typography>

                                                    </React.Fragment>} />
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <ListItemText id={labelId} primary={<React.Fragment>
                                                        <Typography
                                                            variant="h7"
                                                            component="h7"
                                                        >
                                                            {value.topic}
                                                        </Typography>

                                                    </React.Fragment>} />
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <ListItemText id={labelId} primary={<React.Fragment>
                                                        <Typography
                                                            variant="h7"
                                                            component="h7"
                                                        >
                                                            {value.amount + " " + value.currency_code}
                                                        </Typography>

                                                    </React.Fragment>} />
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <ListItemText id={labelId} primary={formatDate(value.create_at)} />
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <ListItemText id={labelId} primary={<React.Fragment>
                                                        <Typography
                                                            variant="h7"
                                                            component="h3"
                                                            style={{ "color": "green" }}
                                                        >
                                                            {value.item_number}
                                                        </Typography>

                                                    </React.Fragment>} />
                                                </Grid>


                                            </ListItem>
                                        </Grid>

                                    )


                                })}
                            </List>


                        </Grid>
                    </Card></Grid>
            </TabPanel>
            <Dialog fullScreen open={openLoad} onClose={handleCloseload} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleCloseload} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Plans
                        </Typography>

                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <Box display="flex" justifyContent="center">
                        {progress ?
                            <CircularProgress justifyContent="center" color="secondary" /> :
                            null
                        }
                    </Box>


                    <Grid container spacing={3}>

                        <Container maxWidth="md" component="main">

                            <Grid container spacing={5} alignItems="flex-end">

                                <Snackbar open={openNot} autoHideDuration={10000} onClose={handleCloseNot}>
                                    <Alert onClose={handleCloseNot} severity="warning">
                                        {message}
                                    </Alert>
                                </Snackbar>
                                {librarys.map((tier) => (
                                    // Enterprise card is full width at sm breakpoint
                                    <Grid
                                        item
                                        key={tier.title}
                                        xs={12}
                                        sm={tier.title === 'Enterprise' ? 12 : 6}
                                        md={4}
                                    >
                                        <Card>
                                            <CardHeader
                                                title={tier.productName}
                                                subheader={tier.subheader}
                                                titleTypographyProps={{ align: 'left' }}
                                                action={tier.name === 'Pro' ? <StarIcon /> : null}
                                                subheaderTypographyProps={{
                                                    align: 'left',
                                                }}
                                                sx={{
                                                    backgroundColor: (theme) =>
                                                        theme.palette.mode === 'light'
                                                            ? theme.palette.grey[200]
                                                            : theme.palette.grey[700],
                                                }}
                                            />
                                            <CardMedia
                                                component="img"
                                                height="194"
                                                image={tier.image}
                                                alt="Paella dish"
                                            />
                                            <CardContent>
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        mb: 2,
                                                    }}
                                                >
                                                    <Typography component="h3" variant="h3" color="text.primary">

                                                        {tier.productPrice != 0 ?
                                                            parseFloat(tier.productPrice / 100) + "€" :
                                                            "Free"

                                                        }
                                                    </Typography>
                                                    <Typography variant="h6" color="text.secondary">
                                                        {tier.productPrice != 0 ? "/mo" :
                                                            ""
                                                        }
                                                    </Typography>
                                                </Box>
                                                <ul>
                                                    {tier.productDescription}
                                                </ul>


                                            </CardContent>
                                            <CardActions>


                                                {tier.productPrice != 0 ? (
                                                    <Button
                                                        fullWidth
                                                        variant={tier.buttonVariant}
                                                        onClick={() => {
                                                            handleFree(tier.productPriceId, tier.metadata.type)
                                                        }}
                                                    >
                                                        {"Subscribe"}
                                                    </Button>
                                                ) : (

                                                    <Button
                                                        fullWidth
                                                        variant={"outlined"}
                                                        onClick={() => {
                                                            handleFree(tier.productPriceId, tier.metadata.type)

                                                        }}
                                                    >
                                                        {"Get it"}
                                                    </Button>
                                                )}
                                                {/*
                                                        <Button  onClick={handleUpgrade}>
                                                            {tier.buttonText}
                                                        </Button>*/}
                                            </CardActions>
                                        </Card>
                                    </Grid>
                                ))}
                            </Grid>
                        </Container>


                    </Grid>
                </DialogContent>
            </Dialog>
            <Cards history={history} url={url} openNew={openNew} setOpenNew={setOpenNew} fetchDataCard={fetchDataCard}
                setCards={setCards} cards={cards} created={Created} setCreated={setCreated}></Cards>
            <Dialog open={openDelete} onClose={handleCloseDelete}>
                <DialogTitle disableTypography={false}>Confirmation</DialogTitle>
                <DialogContent dividers>
                    Are you sure you want to delete this plan ?
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        handleDelete()
                    }} color='primary' size='small'> yes </Button>
                    <Button onClick={handleCloseDelete} color='primary' size='small'> No </Button>
                </DialogActions>

            </Dialog>


        </Box>
    );


};

export default Plan;
