import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import MUIDataTable from "mui-datatables";

import AccountTreeIcon from '@material-ui/icons/AccountTree';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from "@material-ui/core/Slide";
import Button from "@material-ui/core/Button";
import axios from "axios";
import {MuiThemeProvider, createMuiTheme, Grid, Card, CardContent, Avatar} from '@material-ui/core';

import clsx from "clsx";
import MoneyIcon from "@material-ui/icons/Money";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ReactJson from "react-json-view";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import {SearchInput} from "../../Components";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CardHeader from "@material-ui/core/CardHeader";
import DeleteIcon from "@material-ui/icons/Delete";
import CardActionArea from "@material-ui/core/CardActionArea";
import {getToken} from "../../Utils/common";
import ExpandMore from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%'
    },
    content: {
        alignItems: 'center',
        display: 'flex'
    },
    title: {
        fontWeight: 700
    },
    avatar: {
        backgroundColor: theme.palette.error.main,
        height: 56,
        width: 56
    },
    icon: {
        height: 32,
        width: 32
    },
    difference: {
        marginTop: theme.spacing(2),
        display: 'flex',
        alignItems: 'center'
    },
    differenceIcon: {
        color: theme.palette.error.dark
    },
    differenceValue: {
        color: theme.palette.error.dark,
        marginRight: theme.spacing(1)
    },
    row: {
        height: '42px',
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(1)
    },
    spacer: {
        flexGrow: 1
    },
    importButton: {
        marginRight: theme.spacing(1)
    },
    exportButton: {
        marginRight: theme.spacing(1)
    },
    searchInput: {
        marginRight: theme.spacing(1)
    }
}));
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const Schema = props => {
    const {history, url, className, ...rest} = props;
    const classes = useStyles();

    const [schema, setSchemas] = useState([]);


    const getSchema = async (name) => {
        try {
            let res = await fetch(`${url}/getSchema`,
                {
                    method: 'GET', mode: 'cors',
                    headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getToken()}
                });
            let myjson = await res.json();
            await setSchemas(myjson);
        } catch (err) {
            alert('fetch data error ' + err)
        }
    }

    useEffect(() => {
        getSchema()
    }, []);
    const [open, setOpen] = React.useState(false);
    const [dt, setdata] = React.useState({});
    const [name, setname] = React.useState("");

    const [openDelete, setOpenDelete] = useState(false);


    const handleOpenDelete = (name) => {
        setname(name);
        setOpenDelete(true);

    }


    const handleCloseDelete = () => {
        setOpenDelete(false);
    }
    const handleDeleteContinue = async () => {
        try {
            let res = await fetch(`${url}/deleteSchema/` + name, {
                method: 'GET',
                mode: 'cors',
                headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getToken()}
            })
            let myjson = await res.json();
            getSchema()
            handleCloseDelete()

        } catch (error) {
            console.error('Error error:', error)
        }
    }

    const [values, setValues] = useState([])
    const [search, setSearch] = useState([])


    const fetchData = async () => {
        try {
            let res = await fetch(url + "/getSchema",
                {
                    method: 'GET', mode: 'cors',
                    headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getToken()}
                });
            let myjson = await res.json();
            await setSchemas(myjson);
            await setSearch(myjson)


            console.log("here")
        } catch (err) {
            alert('fetch data error ' + err)
        }
    }


    const handleClickOpen = (data) => {


        setOpen(true);
        setdata(data)
    };


    const [flows, setFlows] = React.useState({
        name: "",
        search: ""
    })
    const handlesearch = event => {

        console.log(event.target.value)
        setSchemas(schema.filter(value => {


            if (value.name.includes(event.target.value) == true) return value


        }));
        if ((schema.length == 0) || (event.target.value == ""))
            fetchData();


    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleClick = () => {
        history.push("addschema");
    };
    return (
        <div className={classes.root}>
            <Grid container spacing={3}>
                <Grid item xs={3}>

                    <div className={classes.row}>


                        <SearchInput
                            className={classes.searchInput}
                            placeholder="Search Template "
                            name={"search"}
                            values={flows.search}
                            onChange={handlesearch}
                        />


                    </div>
                </Grid>
                <Grid item xs={3}>

                </Grid>
                <Grid item xs={3}>
                </Grid>
                <Grid item xs={3}>
                    <div className={classes.row}>
                        <span className={classes.spacer}/>

                        <Button
                            color="primary"
                            variant="contained"
                            onClick={handleClick}
                        >
                            Add Template
                        </Button>
                    </div>


                </Grid>

                <Grid item xs={"12"}> <Divider></Divider></Grid>

                <Grid
                    container
                    spacing={4}
                >  {schema.map(product => (
                    <Grid
                        item
                        lg={3}
                        sm={6}
                        xl={3}
                        xs={12}
                    >

                        <Card
                            {...rest}
                            className={clsx(classes.root, className)}
                        >
                            <CardActionArea>
                                <CardHeader

                                    action={
                                        <IconButton aria-label="settings" onClick={() => {
                                            handleOpenDelete(product.name)
                                        }}>
                                            <DeleteIcon/>
                                        </IconButton>
                                    }

                                    title={product.name}

                                />
                                <CardContent onClick={() => {
                                    handleClickOpen(product.description)
                                }}>
                                    <Grid
                                        container
                                        justify="space-between"
                                    >
                                        <Grid item>

                                            <Typography variant="body2" component="p">
                                                {
                                                    product.listTopics.toString()
                                                }
                                            </Typography>

                                        </Grid>
                                        <Grid item>
                                            <Avatar className={classes.avatar}>
                                                <AccountTreeIcon className={classes.icon}/>
                                            </Avatar>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </CardActionArea>
                        </Card>

                    </Grid>


                ))}
                </Grid>
                <Dialog open={openDelete} onClose={handleCloseDelete}>
                    <DialogTitle disableTypography={false}>Confirmation</DialogTitle>
                    <DialogContent dividers>
                        Are you sure you want to delete this template ?
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDeleteContinue} color='primary' size='small'> yes </Button>
                        <Button onClick={handleCloseDelete} color='primary' size='small'> No </Button>
                    </DialogActions>

                </Dialog>
                <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                    <DialogTitle id="customized-dialog-title" onClose={handleClose}>
                        Description
                    </DialogTitle>
                    <DialogContent dividers>
                        <ReactJson src={dt}/>
                    </DialogContent>

                </Dialog>
            </Grid>
        </div>
    );
};

export default Schema;
