import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { getToken, getUser, setUserSession, getRole } from '../../../../Utils/common';
import axios from "axios";
import { makeStyles } from '@material-ui/styles';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import {
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Divider,
    Grid,
    Button,
    TextField
} from '@material-ui/core';
import {
    Avatar,
    Box,

    Typography
} from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import MoneyIcon from '@material-ui/icons/Money';
import { red } from '@material-ui/core/colors';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(() => ({
    root: {}
}));

const AccountBilling = props => {
    const { className, url, ...rest } = props;
    let path = url;
    const classes = useStyles();


    const fetchData = async () => {
        try {
            let res = await fetch(url + "/paymentsplan",
                {
                    method: 'GET', mode: 'cors',
                    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getToken() }
                });
            let myjson = await res.json();
            await setValues(myjson);

            console.log("here is my json");
            console.log(myjson);
        } catch (err) {
            console.log('fetch data error ' + err)
        }
    }
    
    useEffect(() => {
        fetchData()
    }, []);

    const [open, setOpen] = React.useState(false);
    const [values, setValues] = React.useState([]);

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    return (
        <Card
            sx={{ height: '100%' }}
            {...props}
        >
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={6}>

                        <Grid
                            container
                            spacing={3}
                            sx={{ justifyContent: 'space-between' }}
                        >
                            <Grid item>
                                <Typography
                                    color="textSecondary"
                                    gutterBottom
                                    variant="h6"
                                >
                                    Channels/ Messages
                                </Typography> {
                                    values.map((value) => {
                                        return (
                                            <Typography
                                                color="textPrimary"
                                                variant="h6"
                                            >
                                                {value.channel} /{value.nbrMqttMessage}
                                            </Typography>
                                        )
                                    })
                                }
                            </Grid>

                        </Grid>
                        <Box
                            sx={{
                                pt: 2,
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        >

                            <Typography
                                sx={{
                                    color: red[900],
                                    mr: 1
                                }}
                                variant="body2"
                            >
                                Full report
                            </Typography>
                            <ArrowForwardIcon sx={{ color: red[900] }} />
                        </Box>
                    </Grid>


                </Grid>

            </CardContent>
        </Card>
    );
};

AccountBilling.propTypes = {
    className: PropTypes.string
};

export default AccountBilling;
