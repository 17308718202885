import React, { useEffect } from "react";
import MaterialTable from "material-table";
import axios from "axios";
import { getToken } from "../../Utils/common";
import TextField from "@material-ui/core/TextField";

import { Alert } from "@material-ui/lab";

const { useState } = require("react");

export default function MaterialTableDemo({ path }) {
  const [error, setError] = useState(null);

  const [nameError, setNameError] = useState({
    error: false,
    helperText: "",
    label: "",
    validateInput: false,
  });
  const [usernameError, setUsernameError] = useState({
    error: false,
    helperText: "",
    label: "",
    validateInput: false,
  });
  const [organizationError, setOrganizationError] = useState({
    error: false,
    helperText: "",
    label: "",
    validateInput: false,
  });
  const [emailError, setEmailError] = useState({
    error: false,
    helperText: "",
    label: "",
    validateInput: false,
  });
  const [passwordError, setPasswordError] = useState({
    error: false,
    helperText: "",
    label: "",
    validateInput: false,
  });
  const [state, setState] = useState({
    data: [],
  });

  const getusers = async () => {
    try {
      let res = await fetch(`${path}/api/users/`, {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getToken(),
        },
      });
      let myjson = await res.json();
      await setState({
        data: myjson.users,
      });
    } catch (err) {
      setError(err);
    }
  };

  useEffect(() => {
    getusers();
  }, []);

  return (
    <>
      {error && (
        <Alert
          severity="error"
          onClose={() => {
            setError(null);
          }}
        >
          Error occured , please check your information and try again
        </Alert>
      )}

      <MaterialTable
        title={"Users"}
        columns={[
          {
            title: "Name",
            field: "name",
            editComponent: (props) => (
              <TextField
                type="text"
                value={props.value}
                onChange={(e) => props.onChange(e.target.value)}
                error={nameError.error}
                helperText={nameError.helperText}
                required
              />
            ),
          },
          {
            title: "Username",
            field: "username",
            editComponent: (props) => (
              <TextField
                type="text"
                value={props.value}
                onChange={(e) => props.onChange(e.target.value)}
                error={usernameError.error}
                helperText={usernameError.helperText}
                required
              />
            ),
          },
          {
            title: "Email",
            field: "email",
            type: "string",
            editComponent: (props) => (
              <TextField
                type="email"
                value={props.value}
                onChange={(e) => props.onChange(e.target.value)}
                error={emailError.error}
                helperText={emailError.helperText}
                required
              />
            ),
          },
          {
            title: "Password",
            field: "value",
            render: (rowData) => (
              <p>{rowData.password.split("").map(() => "*")}</p>
            ),
            editComponent: (props) => (
              <TextField
                type="password"
                value={props.value}
                onChange={(e) => props.onChange(e.target.value)}
                error={passwordError.error}
                helperText={passwordError.helperText}
                required
              />
            ),
          },
          {
            title: "organization",
            field: "organization",
            type: "string",
            editComponent: (props) => (
              <TextField
                type="text"
                value={props.value}
                onChange={(e) => props.onChange(e.target.value)}
                error={organizationError.error}
                helperText={organizationError.helperText}
                required
              />
            ),
          },
        ]}
        data={state.data}
        editable={{
          onRowAdd: (newData) =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                if (newData.name === undefined || newData.name === "") {
                  setNameError({
                    error: true,
                    label: "required",
                    helperText: "Name must be filled",
                    validateInput: true,
                  });
                  console.log(nameError);
                  reject();
                }
                if (newData.username === undefined || newData.username === "") {
                  setUsernameError({
                    error: true,
                    label: "required",
                    helperText: "Username must be filled",
                    validateInput: true,
                  });
                  reject();
                }
                if (newData.email === undefined || newData.email === "") {
                  setEmailError({
                    error: true,
                    label: "required",
                    helperText: "Email must be filled",
                    validateInput: true,
                  });
                  reject();
                }
                if (
                  newData.organization === undefined ||
                  newData.organization === ""
                ) {
                  setOrganizationError({
                    error: true,
                    label: "required",
                    helperText: "Organization must be filled",
                    validateInput: true,
                  });
                  reject();
                }
                if (newData.value === undefined || newData.value === "") {
                  setPasswordError({
                    error: true,
                    label: "required",
                    helperText: "Password must be filled",
                    validateInput: true,
                  });
                  reject();
                }
                if (
                  newData.username &&
                  newData.name &&
                  newData.email &&
                  newData.value &&
                  newData.organization
                ) {
                  console.log("here");
                  axios
                    .post(
                      path + "/api/users/admin",
                      { ...newData, password: newData.value },
                      {
                        headers: {
                          "Content-Type": "application/json",
                          Authorization: "Bearer " + getToken(),
                        },
                      }
                    )
                    .then((response) => {
                      getusers();
                      resolve();
                    })
                    .catch((error) => {
                      setError(
                        "Error occured please check your information and try agian !"
                      );
                      setTimeout(() => {
                        setState((prevState) => {
                          const data = [...prevState.data];
                          return { ...prevState, data };
                        });
                      }, 600);
                      resolve();
                    });
                }
              }, 600);
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                console.log(newData);
                if (newData.name === undefined || newData.name === "") {
                  setNameError({
                    error: true,
                    label: "required",
                    helperText: "Name must be filled",
                    validateInput: true,
                  });
                  console.log(nameError);
                  reject();
                }
                if (newData.username === undefined || newData.username === "") {
                  setUsernameError({
                    error: true,
                    label: "required",
                    helperText: "Username must be filled",
                    validateInput: true,
                  });
                  reject();
                }
                if (newData.email === undefined || newData.email === "") {
                  setEmailError({
                    error: true,
                    label: "required",
                    helperText: "Email must be filled",
                    validateInput: true,
                  });
                  reject();
                }
                if (
                  newData.organization === undefined ||
                  newData.organization === ""
                ) {
                  setOrganizationError({
                    error: true,
                    label: "required",
                    helperText: "Organization must be filled",
                    validateInput: true,
                  });
                  reject();
                }
                if (newData.value === undefined || newData.value === "") {
                  setPasswordError({
                    error: true,
                    label: "required",
                    helperText: "Password must be filled",
                    validateInput: true,
                  });
                  reject();
                }
                if (
                  newData.username &&
                  newData.name &&
                  newData.email &&
                  newData.value &&
                  newData.organization
                ) {
                  console.log("here");
                  axios
                    .post(path + "/updateUser/" + oldData.id, newData, {
                      headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + getToken(),
                      },
                    })
                    .then((response) => {
                      getusers();
                      resolve();
                    })
                    .catch((error) => {
                      if (error.response.status === 401)
                        setError(" user already updated");
                      resolve();
                    });
                }
              }, 600);
            }),
          onRowDelete: (oldData) => 
            axios
              .delete(path + `/api/users/${oldData.id}`, {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + getToken(),
                },
              })
              .then((response) => {
                getusers();
              })
              .catch((error) => {
                if (error.response.status === 401) setError(" error deleting ");
              }),
        }}
        options={{
          exportButton: true,
        }}
      />
    </>
  );
}
