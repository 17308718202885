import React from 'react';
import {makeStyles} from '@material-ui/styles';
import {Grid} from '@material-ui/core';

import {Notifications} from './components';
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from '@material-ui/core/Link';
import HomeIcon from '@material-ui/icons/Home';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import GrainIcon from '@material-ui/icons/Grain';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4)
    }
}));

const ConnectorsUpdate = props => {
    const {url, history, product} = props;
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid
                container
                spacing={4}
            >
                <Grid
                    item
                    md={12}
                    xs={12}
                >
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link color="inherit" href="/connectors" className={classes.link}>
                            <HomeIcon className={classes.icon}/>
                            Connectors
                        </Link>


                    </Breadcrumbs>
                    <Notifications url={url} history={history} product={product}/>
                </Grid>

            </Grid>
        </div>
    );
};

export default ConnectorsUpdate;
