import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {
    Stepper,
    Step,
    StepLabel,
    Button,
    Typography,
    Box,
    Grid,
    CircularProgress,
} from '@material-ui/core';
import {
    SentimentVerySatisfied,
    SentimentVeryDissatisfied
} from '@material-ui/icons';
import StepperIcons from "./StepperIcons";
import ContactForm from "./Forms/ContactForm";
import PaymentForm from "./Forms/PaymentForm";

import StepConnector from './StepConnector'
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import axios from "axios";
import {getToken} from "../../../../Utils/common";
import DialogContent from "@material-ui/core/DialogContent";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(4)
    }
}));

// OVERALL STYLE
const style = makeStyles(theme => ({
    button: {
        marginRight: theme.spacing(1),
    },
    mainBox: {},
    stepper: {
        height: "calc(10vh - 40px)",
        minHeight: "55px"
    },
    form: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around"
    },
    buttonWrapper: {
        justifyContent: "flex-end"
    },
}));

const StepContent = ({step, card, setCard, url, handleChangeCard}) => {
    switch (step) {
        case 0:
            return <PaymentForm card={card} setCard={setCard} url={url} handleChangeCard={handleChangeCard}/>;
        default:
            return <></>;
    }
}

const Cards = ({history, url, openNew, setOpenNew, setCards, cards, created = null, setCreated = null}) => {
    const [activeStep, setActiveStep] = useState(0);
    const [loading, setLoading] = useState(false);
    const [cardStatus, setCardStatus] = useState(true);
    const [cardMessage, setCardMessage] = useState("");
    const classes = useStyles();

    const handleNext = () => {

        if (activeStep === 0) {

            handleAdd();
            setActiveStep(prevActiveStep => prevActiveStep + 1);


        } else {
            setActiveStep(prevActiveStep => prevActiveStep + 1);

        }
    };
    const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1);
    const handleReset = () => setActiveStep(0);

    const [values, setValues] = React.useState([]);
    const [progress, setprogress] = useState(false);
    const [value, setValue] = React.useState(0);
    const [expanded, setExpanded] = React.useState(true);
    const [expanded2, setExpanded2] = React.useState(false);
    const handleClickOpen = () => {
        setOpenNew(true);
    };
    const handleChangeCard = event => {
        setCard({
            ...card,
            [event.target.name]: event.target.value
        });
    };
    const [card, setCard] = React.useState({
        name: "",
        search: "",
        number: 0,
        ccv: 0,
        date: "",
        default: 0,
        email: "",
        currency: "",
        address_country: ""

    })
    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState("");

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const handleAdd = () => {
        setprogress(true)
        axios.post(url + "/card", card, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getToken()
            }
        }).then(response => {

            console.log(response)
            if (response.data.message != undefined) {
                setMessage(response.data.message);
                fetchDataCard();
                setprogress(false)
                setCreated(true);

            } else if (response.data.Error != undefined) {
                setMessage(response.data.Error)
                fetchDataCard();
                setprogress(false);
            } else {

                handleCloseNew()
                fetchDataCard()
                setprogress(false)


            }


        }).catch(error => {
            console.log(error)
            setMessage(error.Error)
        });

    }
    const handleClick = (e) => {
        setExpanded2(false)
        setExpanded(true)

    }
    const handleCloseNew = () => {
        setOpenNew(false);
    };

    const handleClick1 = (e) => {
        setExpanded(false)
        setExpanded2(true);


    }
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleDelete = async (id) => {


        axios.delete(url + "/card/" + id, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getToken()
            }
        }).then(response => {
            if (response) {

                fetchDataCard();

            }
        }).catch(error => {
            if (error.response.status === 401) console.log(" error ");
        });

    }

    const handleEdit = async (card) => {

        var data = {
            name: "redha card  ",
            search: "",
            number: 0,
            ccv: 155,
            date: "11/25",
            default: 0,
            email: "",
            address_country: ""
        };
        // setCard(data);

        axios.put(url + "/card/" + card.cardId, data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getToken()
            }
        }).then(response => {
            if (response) {

                fetchDataCard();

            }
        }).catch(error => {
            if (error.response.status === 401) console.log(" error ");
        });

    }

    useEffect(() => {
        fetchDataCard()
    }, []);

    const fetchDataCard = async () => {
        try {
            let res = await fetch(url + "/cards",
                {
                    method: 'GET', mode: 'cors',
                    headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getToken()}
                });
            let myjson = await res.json();
            await setCards(myjson);

            console.log("here")
        } catch (err) {
            alert('fetch data error ' + err)
        }
    }
    return (
        <Dialog open={openNew} onClose={handleCloseNew} aria-labelledby="form-dialog-title" maxWidth={"lg"}>
            <DialogTitle id="form-dialog-title"> New Card</DialogTitle>

            <Divider></Divider>
            <Box display="flex" sx={{color: "red"}}>
                {message + "\n"}
            </Box>
            <DialogContent>


                <Grid container spacing={3}>
                    <Grid item md={12} xs={12}>
                        <Stepper alternativeLabel className={classes.stepper} connector={<StepConnector/>}
                                 activeStep={activeStep}>
                            {/* Change the number of loops here based on StepContent */}
                            {[1].map(e =>
                                <Step key={e}>
                                    <StepLabel StepIconComponent={StepperIcons}/>
                                </Step>
                            )}
                        </Stepper>
                        <Box className={classes.mainBox}>
                            {activeStep === 2 ?
                                <Grid

                                    item xs={12}
                                    direction="column"
                                    justify="space-around"
                                    alignItems="center"
                                    style={{height: "400px"}}
                                >
                                    {cardStatus
                                        ?
                                        <Box display="flex" style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}>
                                            {progress ?
                                                <CircularProgress color="secondary"/> :
                                                null}
                                        </Box>
                                        :
                                        ""
                                    }
                                    <Typography variant="h4" style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}>
                                        {cardMessage}
                                    </Typography>
                                    <Button onClick={cardStatus ? handleReset : handleBack} className={classes.button}>
                                        {cardStatus ? "Reset" : "Back"}
                                    </Button>
                                </Grid>
                                :
                                <form autoComplete="off" className={classes.form} onSubmit={e => {
                                    e.preventDefault();
                                    handleNext()
                                }}>
                                    <Grid container spacing={3}>
                                        <StepContent step={activeStep} card={card} setCard={setCard} url={url}
                                                     handleChangeCard={handleChangeCard}/>
                                        <Grid container item justify="flex-end">
                                            <Button disabled={activeStep === 0} className={classes.button}
                                                    onClick={handleBack}>
                                                Back
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                className={classes.button}
                                                type="submit"
                                                disabled={loading}
                                            >
                                                {
                                                    loading
                                                        ?
                                                        <CircularProgress size={24}/>
                                                        :
                                                        activeStep === 2 ? 'Pay' : 'Next'
                                                }
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            }
                        </Box>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

Cards.propTypes = {
    className: PropTypes.string
};

export default Cards;
