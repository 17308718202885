import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/styles';
import { Badge } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import GetAppIcon from '@material-ui/icons/GetApp';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import BackupIcon from '@material-ui/icons/Backup';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import SettingsInputHdmiIcon from '@material-ui/icons/SettingsInputHdmi';
import ListItem from '@material-ui/core/ListItem';
import DataUsageIcon from '@material-ui/icons/DataUsage';
import ListItemText from '@material-ui/core/ListItemText';
import Link from '@material-ui/core/Link';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import Avatar from '@material-ui/core/Avatar';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import { SidebarNav } from "./components/Sidebar/components";
import { getToken, getUser, removeUserSession } from "../../Utils/common";
import DashboardIcon from "@material-ui/icons/Dashboard";
import PeopleIcon from "@material-ui/icons/People";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import SettingsIcon from "@material-ui/icons/Settings";
import NotificationsIcon from "@material-ui/icons/NotificationsOutlined";
import InputIcon from "@material-ui/icons/Input";
import { fade } from "@material-ui/core/styles";
import { Link as RouterLink } from 'react-router-dom';
import Menu from '@material-ui/core/Menu';
import fetch from "cross-fetch";
import axios from "axios";
import FolderIcon from '@material-ui/icons/Folder';
import Box from "@material-ui/core/Box";
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },

    notification: {
        width: '100%',
        maxWidth: '36ch',
        backgroundColor: theme.palette.background.paper,
    },
    inline: {
        display: 'inline',
    },

    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }, signOutButton: {
        marginLeft: theme.spacing(150)
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',

    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    }, flexGrow: {
        flexGrow: 1
    },

    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },

    searchIcon: {
        width: theme.spacing(7),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

}));

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://www.beemetrix.com">
                BeeMetrix
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}


const Main = props => {
    const { children, url, history, stay } = props;

    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const handleProfileMenuOpen = event => setAnchorEl(event.currentTarget);
    const handleDrawerOpen = () => { setOpen(true) };
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleDrawerClose = () => { setOpen(false); };

    const pages = []

    const usr = getUser();

    pages.push({
        title: 'Dashboard',
        href: '/dashboard',
        icon: <DashboardIcon />
    }, {
        title: 'Process',
        href: '/steps',
        icon: <DataUsageIcon />
    }, {
        title: 'Things',
        href: '/things',
        icon: <AssignmentTurnedInIcon />
    }, {
        title: 'Plans',
        href: '/plan',
        icon: <MonetizationOnIcon />
    }
        , {
            title: 'Topics',
            href: '/topic',
            icon: <FolderIcon />
        },
        {
            title: 'Connectors',
            href: '/connectors',
            icon: <SettingsInputHdmiIcon />
        }, {
        title: 'APIs',
        href: '/apis',
        icon: <BackupIcon />
    },
        {
            title: 'Flows',
            href: '/flows',
            icon: <AccountTreeIcon />
        },
        {
            title: 'ElasticSearch  manager',
            href: '/dbmanager',
            icon: <FindInPageIcon />
        }, {
        title: 'Downloads',
        href: '/downloads',
        icon: <GetAppIcon />
    },
        {
            title: 'Account',
            href: '/account',
            icon: <AccountBoxIcon />
        },
        {
            title: 'Settings',
            href: '/settings',
            icon: <SettingsIcon />
        }
    );

    if (usr != null && usr.admin) {
        pages.push(
            {
                title: 'Users',
                href: '/users',
                icon: <PeopleIcon />
            }
        )
    }

    const handlelogout = () => {
        removeUserSession();
        history.push('/signin');
    }

    const [notification, setNotification] = useState([]);

    const [nombre, setNombre] = useState(0);

    const fetchData = async () => {
        try {
            let res = await fetch(url + "/notifications/",
                {
                    method: 'GET', mode: 'cors',
                    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + getToken() }
                });
            let myjson = await res.json();
            var seenList = [];
            myjson.map(data => {
                if (data.seen == 0) seenList.push(data)
            })
            setNombre(seenList.length);
            setNotification(myjson);
            console.log(notification);
        } catch (err) {
            console.log('fetch data error ' + err)
        }
    }


    useEffect(() => { fetchData() }, []);

    const user = { name: usr.name, avatar: url + '/' + usr.location, bio: usr.email };

    const [anchorEl1, setAnchorEl1] = React.useState(null);

    const open1 = Boolean(anchorEl1);

    const handleClick = (event) => {
        setAnchorEl1(event.currentTarget);
        var arr = []
        notification.map(noti => {
            if (noti.seen == 0) arr.push(noti.id)
        })

        axios.post(url + '/UpdateN/', { list: arr }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getToken()
            }
        }).then(response => {
            setNombre(0)
        }).catch(error => {
            if (error.response.status === 401) console.log(" error ");
        });
    };

    const handleClose = () => { setAnchorEl1(null) };

    console.log(process.env.REACT_APP_GRAFANA_URL);

    const handlegrafana = () => window.open(`${process.env.REACT_APP_GRAFANA_URL}`);


    return (
        <div className={classes.root}>
            <CssBaseline />

            <AppBar position="fixed" className={clsx(classes.appBar, { [classes.appBarShift]: open })}>
                <Toolbar>

                    <Avatar onClick={handleDrawerOpen} alt="Remy Sharp" src="logo.png" />

                    <Typography variant="h6" noWrap> ThingWings </Typography>

                    <div className={classes.flexGrow} />

                    <IconButton
                        size="large"
                        aria-label="show  new notifications"
                        color="inherit"
                        onClick={handleProfileMenuOpen}
                    >
                        <Badge badgeContent={nombre} onClick={handleClick} color="error">
                            <NotificationsIcon onClick={handleClick} />
                        </Badge>
                    </IconButton>

                    <Menu
                        anchorEl={anchorEl1}
                        id="account-menu"
                        open={open1}
                        onClose={handleClose}
                        onClick={handleClose}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                overflow: 'visible',
                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                mt: 1.5,
                                '& .MuiAvatar-root': {
                                    width: 32,
                                    height: 32,
                                    ml: -0.5,
                                    mr: 1,
                                },
                                '&:before': {
                                    content: '""',
                                    display: 'block',
                                    position: 'absolute',
                                    top: 0,
                                    right: 14,
                                    width: 10,
                                    height: 10,
                                    bgcolor: 'background.paper',
                                    transform: 'translateY(-50%) rotate(45deg)',
                                    zIndex: 0,
                                },
                            },
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                            {notification.length > 0 ?
                                notification.map((notif) => {
                                    return (
                                        <div>
                                            <ListItem alignItems="flex-start">
                                                <ListItemAvatar>
                                                    <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={notif.typeN}
                                                    secondary={
                                                        <React.Fragment>
                                                            <Typography
                                                                sx={{ display: 'inline' }}
                                                                component="span"
                                                                variant="body2"
                                                                color="text.primary"
                                                            >

                                                            </Typography>
                                                            {notif.message}
                                                        </React.Fragment>
                                                    }
                                                />
                                            </ListItem>
                                            <Divider variant="inset" component="li" />
                                        </div>
                                    )
                                })
                                :
                                <ListItem alignItems="flex-start">
                                    <ListItemAvatar>
                                        <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary="Notification"
                                        secondary={
                                            <>
                                                <Typography
                                                    sx={{ display: 'inline' }}
                                                    component="span"
                                                    variant="body2"
                                                    color="text.primary"
                                                >
                                                </Typography>
                                                {"there is no notification  ..."}
                                            </>
                                        }
                                    />
                                </ListItem>
                            }
                        </List>
                    </Menu>

                    {/* <div target="_blank"
                        href={"https://www.thingwings.com/grafana/d/2PugMtsGz/mosquitto-broker?orgId=1&refresh=10s"}>
                        <Link href="https://thingwings.com/grafana"> */}

                    {/* </Link>
                    </div>
                    */}

                    <IconButton color="inherit" onClick={handlegrafana}>
                        <Avatar
                            alt="Grafana"
                            className={classes.avatar}
                            src={"grafana.jpg"}
                        />
                    </IconButton>


                    <IconButton color="inherit">
                        <Avatar
                            alt="Person"
                            className={classes.avatar}
                            component={RouterLink}
                            src={user.avatar}
                            to="/account"
                        />
                    </IconButton>

                    <IconButton color="inherit" onClick={handlelogout} >
                        <InputIcon />
                    </IconButton>

                </Toolbar>
            </AppBar>
            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="left"
                open={open}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <div className={classes.drawerHeader}>

                    <IconButton>
                        {theme.direction === 'ltr' ? <ChevronLeftIcon onClick={handleDrawerClose} /> :
                            <ChevronRightIcon />}

                    </IconButton>
                </div>
                <Divider />

                <Divider className={classes.divider} />
                <SidebarNav
                    className={classes.nav}
                    pages={pages}
                    history={history}
                    stay={stay}
                    url={url}
                />

            </Drawer>
            <main
                className={clsx(classes.content, {
                    [classes.contentShift]: open,
                })}
            >
                <div className={classes.drawerHeader} />
                {children}
                <Box pt={4}>
                    <Copyright />
                </Box>
            </main>

        </div>
    );
}


Main.propTypes = {
    children: PropTypes.node
};

export default Main;
