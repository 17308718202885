import React from 'react'
import PropTypes from 'prop-types'
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

const Message = ({msg}) => {
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const [open, setOpen] = React.useState(true);

    return (
        <div className="alert alert-info alert-dismissible fade show" role="alert">

            <Snackbar open={open} autoHideDuration={10000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={msg.type}>
                    {msg.text}

                </Alert>
            </Snackbar>
        </div>
    )
}

Message.propTypes = {
    msg: PropTypes.string.isRequired
}

export default Message
